export const MARKET_STATUS_OPTION = [
  { value: "OFF_MARKET", label: "Off Market" },
  { value: "FOR_SALE", label: "On Market" },
  { value: "PENDING", label: "Pending" },
];

export const MARKET_STATUS_OPTION_MAP:any = {
  ANY: null,
  OFF_MARKET: { off_market: false },
  FOR_SALE: { on_market: true },
  PENDING: { mls_pending: true },
  CANCELED: { mls_cancelled: true },
};

export const PROPERTY_TYPE_OPTION = [
  { value: "SINGLE_FAMILY", label: "Single Family " },
  { value: "MULTI_FAMILY", label: "Multi Family" },
  { value: "CONDOMINIUM", label: "Condominium" },
  { value: "MOBILE_HOME", label: "Mobile Home" },
  { value: "LAND", label: "Land" },
  { value: "OTHER", label: "Other" },
];

export const PROPERTY_TYPE_OPTION_MAP: any = {
  ANY: null,
  SINGLE_FAMILY: { property_type: "SFR" },
  MULTI_FAMILY: { property_type: "MFR" },
  COMMERCIAL: {
    property_use_code: [
      130, 133, 134, 135, 136, 138, 149, 159, 161, 164, 165, 171, 175, 184, 266,
      393,
    ],
  },
  CONDOMINIUM: { property_type: "CONDO" },
  MOBILE_HOME: { property_type: "MOBILE" },
  LAND: { property_type: "LAND" },
  OTHER:{property_type:"OTHER"}
};

export const BEDS_BATHS_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const SQUARE_FEET_OPTIONS = [
  { value: 500, label: "500" },
  { value: 750, label: "750" },
  { value: 1000, label: "1,000" },
  { value: 1250, label: "1,250" },
  { value: 1500, label: "1,500" },
  { value: 1750, label: " 1,750" },
  { value: 2000, label: "2,000" },
  { value: 2250, label: "2,250" },
  { value: 2500, label: "2,500" },
  { value: 2750, label: "2,750" },
  { value: 3000, label: "3,000" },
  { value: 3500, label: "3,500" },
  { value: 4000, label: "4,000" },
  { value: 5000, label: "5,000" },
  { value: 7500, label: "7,500" },
];
const ACRE_IN_SQFT = 43560;
export const LOT_SIZE_OPTIONS = [
  { value: 1000, label: "1,000 sqft" },
  { value: 2000, label: "2,000 sqft" },
  { value: 3000, label: "3,000 sqft" },
  { value: 4000, label: "4,000 sqft" },
  { value: 5000, label: "5,000 sqft" },
  { value: Math.floor(43560 / 4), label: "1/4 acre" },
  { value: ACRE_IN_SQFT, label: "1 acre" },
  { value: 1.5 * ACRE_IN_SQFT, label: "1.5 acres" },
  { value: 2 * ACRE_IN_SQFT, label: "2 acres" },
  { value: 5 * ACRE_IN_SQFT, label: "5 acres" },
  { value: 10 * ACRE_IN_SQFT, label: "10 acres" },
  { value: 20 * ACRE_IN_SQFT, label: "20 acres" },
  { value: 50 * ACRE_IN_SQFT, label: "50 acres" },
  { value: 100 * ACRE_IN_SQFT, label: "100 acres" },
];

export const HOME_FEATURES_OPTIONS = [
  { label: "Attic ", value: "ATTIC" },
  { label: "Basement ", value: "BASEMENT" },
  { label: "Breezeway ", value: "BREEZEWAY" },
  { label: "Carport ", value: "CARPORT" },
  { label: "Deck ", value: "DECK" },
  { label: "Balcony ", value: "BALCONY" },
  { label: "Fire Sprinklers ", value: "FIRE_SPRINKLERS" },
  { label: "Garage ", value: "GARAGE" },
  { label: "Patio ", value: "PATIO" },
  { label: "Pool ", value: "POOL" },
  { label: "RV Parking ", value: "RV_PARKING" },
];

export const HOME_FEATURES_OPTIONS_MAP:any = {
  ATTIC: {
    attic: true,
  },
  BASEMENT: {
    basement: true,
  },
  BREEZEWAY: {
    breezeway: true,
  },
  CARPORT: {
    carport: true,
  },
  DECK: {
    deck: true,
  },
  BALCONY: {
    feature_balcony: true,
  },
  FIRE_SPRINKLERS: {
    fire_sprinklers: true,
  },
  GARAGE: {
    garage: true,
  },
  PATIO: {
    patio: true,
  },
  POOL: {
    pool: true,
  },
  RV_PARKING: {
    rv_parking: true,
  },
};
export const CLASSIFICATIONS_OPTIONS = [
  {
    label: "Residential",
    value: "RESIDENTIAL",
  },
  {
    label: "Commercial ",
    value: "COMMERCIAL",
  },
  {
    label: "Office",
    value: "OFFICE",
  },
  {
    label: "Recreational",
    value: "RECREATIONAL",
  },
  {
    label: "Industrial",
    value: "INDUSTRIAL",
  },
  {
    label: "Agricultural",
    value: "AGRICULTURAL",
  },
  {
    label: "Vacant Land",
    value: "VACANT_LAND",
  },
  {
    label: "Exempt",
    value: "EXEMPT",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const CLASSIFICATIONS_OPTIONS_MAP :any= {
  RESIDENTIAL: {
    property_use_code: [
      181, 357, 358, 359, 360, 361, 362, 363, 364, 366, 367, 368, 369, 370, 371,
      372, 373, 375, 376, 377, 378, 379, 380, 381, 382, 383, 384, 385, 386, 387,
      388, 389, 390, 416, 421, 447, 452, 461,
    ],
  },
  COMMERCIAL: {
    property_use_code: [
      124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138,
      139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153,
      154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168,
      169, 172, 173, 175, 176, 177, 178, 179, 180, 182, 183, 185, 186, 187, 188,
      189, 190, 191, 192, 193, 194, 412, 449, 458, 459, 464,
    ],
  },
  OFFICE: {
    property_use_code: [135, 136, 139, 169, 170, 171, 176, 177, 184, 301],
  },
  RECREATIONAL: {
    property_use_code: [
      258, 259, 260, 261, 262, 263, 264, 266, 267, 270, 273, 285, 287, 288, 290,
      292, 293, 296, 316, 317, 322, 323, 327, 331, 332, 334, 336, 337, 346, 349,
      350, 355,
    ],
  },
  INDUSTRIAL: {
    property_use_code: [
      195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209,
      210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224,
      225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239,
      240, 243, 450,
    ],
  },
  AGRICULTURAL: {
    property_use_code: [
      101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115,
      116, 117, 118, 119, 120, 121, 122, 123, 392, 413, 446,
    ],
  },
  VACANT_LAND: {
    property_use_code: [
      389, 391, 392, 393, 394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404,
      406, 409, 453, 462,
    ],
  },
  EXEMPT: {
    property_use_code: [
      268, 269, 271, 272, 275, 277, 278, 282, 283, 284, 286, 288, 293, 294, 295,
      298, 302, 303, 304, 305, 306, 307, 308, 309, 310, 314, 315, 318, 319, 320,
      321, 324, 325, 326, 328, 329, 330, 333, 335, 336, 338, 339, 342, 344, 345,
      356, 354, 402,
    ],
  },
  OTHER: {
    property_use_code: [
      0, 241, 242, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255,
      256, 257, 409, 410, 411, 415, 417, 418, 419, 420, 422, 423, 424, 425, 426,
      427, 428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441,
      442, 443, 444, 445, 447, 448, 451, 454, 455, 456, 457, 460, 463,
    ],
  },
};

export const AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS = [
  { label: "Notice of Default / Lis Pendens", value: "NOTICE_OF_DEFAULT_LIS_PENDENS" },
  {label : "Notice of Trustee's Sale",value:"NOTICE_OF_TRUSTEES_SALE"}
];

export const AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP :any= {
  NOTICE_OF_DEFAULT_LIS_PENDENS: { notice_type: ["NOD", "NOL"] },
  NOTICE_OF_TRUSTEES_SALE:{notice_type: ["NTS"]}
};

export const AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS = [
  { label: "Any", value: null },
  { label: "Up to a month ago ", value: { search_range: "1_MONTH" } },
  { label: "Up to 2 months ago ", value: { search_range: "2_MONTH" } },
  { label: "Up to 3 months ago ", value: { search_range: "3_MONTH" } },
  { label: "Up to 6 months ago ", value: { search_range: "6_MONTH" } },
  { label: "Up to a year ago ", value: { search_range: "1_YEAR" } },
];

export const OWNER_TYPE_OPTIONS = [
  { label: "Individual", value: "INDIVIDUAL" },
  { label: "Corporate", value: "CORPORATE" },
];

export const OWNER_TYPE_OPTIONS_MAP:any = {
  CORPORATE: {
    corporate: true,
  },
  INDIVIDUAL:{
    individual: true,
  },
};

export const ABSENTEE_OWNER_OPTIONS : any =[
  { label: "Absentee (In State)", value: "INSTATE" },
  { label: "Absentee (Out of State)", value: "OUTOFSTATE" },
]

export const ABSENTEE_OWNER_OPTIONS_MAP:any = {
  INSTATE: {
    inState: true,
  },
  OUTOFSTATE:{
    outState: true,
  },
};

export const OCCUPANCY_OPTIONS : any =[
  { label: "Owner Occupied", value: "OWNEROCCUPIED" },
  { label: "Vacant", value: "VACANT" },
]

export const OCCUPANCY_OPTIONS_MAP:any = {
  OWNEROCCUPIED: {
    ownerOccupied: true,
  },
  VACANT:{
    vacant : true,
  },
};

export const LOAN_TYPE_OPTIONS = [
  { label: "Adjustable Rate", value: "ARM" },
  { label: "Balloon", value: "BAL" },
  { label: "Construction", value: "CON" },
  { label: "Closed End", value: "CEM" },
  { label: "Commercial", value: "COM" },
  { label: "Conventional", value: "COV" },
  { label: "FHA", value: "FHA" },
  { label: "Land Contract", value: "LCO" },
  { label: "Line of Credit", value: "LOC" },
  { label: "Loan Modification", value: "MOD" },
  { label: "Negative Amortization", value: "NEG" },
  { label: "Reverse", value: "REV" },
  { label: "Silent Second", value: "SDP" },
  { label: "Seller Take-Back", value: "SEL" },
  { label: "SBA", value: "SBA" },
  { label: "Stand Alone First", value: "SAF" },
  { label: "Stand Alone Re-fi", value: "SAR" },
  { label: "Stand Alone Second", value: "SAS" },
  { label: "State VA", value: "SVA" },
  { label: "Federal VA", value: "VA" },
  { label: "USDA", value: "USDA" },
];

export const ADVANCED_FILTER_KEYS = [
  "propertyCharacteristicsFilter",
  "auctionsPreforeclousreFilter",
  "ownershipFilter",
  "taxLienFilter",
  "valuationEquityFilter",
  "mortgageInfoFilter",
  "cashBuyersFilter",
  "demographicsFilter",
]; 

export const DEFAULT_FORM_VALUE = {
  propertyCharacteristicsFilter: {},
  auctionsPreforeclousreFilter: {},
  ownershipFilter: {},
  taxLienFilter: {},
  valuationEquityFilter: {},
  mortgageInfoFilter: {},
  cashBuyersFilter: {},
  demographicsFilter: {},
  marketStatus: [],
  bedsBath:{
    maxBathrooms:"",
    maxBedrooms:"",
    minBathrooms:"",
    minBedrooms:""
   },
  // minBedrooms: "0",
  // maxBedrooms: "0",
  // minBathrooms: "0",
  // maxBathrooms: "0",
  propertyType: [],
  searchTerm: "",
};
export const DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE = {
  propertyCharacteristicsFilter: 0,
  auctionsPreforeclousreFilter: 0,
  ownershipFilter: 0,
  taxLienFilter: 0,
  valuationEquityFilter: 0,
  mortgageInfoFilter: 0,
  cashBuyersFilter: 0,
  demographicsFilter: 0,
};
export const DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE = {
  propertyCharacteristicsFilter: {},
  auctionsPreforeclousreFilter: {},
  ownershipFilter: {},
  taxLienFilter: {},
  valuationEquityFilter: {},
  mortgageInfoFilter: {},
  cashBuyersFilter: {},
  demographicsFilter: {},
  bedsAndBathFilter: {},
  marketStatus: [],
  propertyTypeFilter: {},
  searchTerm: "",
};

export const SearchPropertyType: { [key: string]: string } = {
  A: "Address",
  C: "City",
  G: "Neighborhood",
  N: "County",
  S: 'Street',
  Z: "Zip",
  T: 'State',
};
