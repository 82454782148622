const METER_SEGMENT = 33.3;

export const getMeterPercentage = (percentage: number) => {
  if (percentage > 95) {
    return Math.round(
      METER_SEGMENT * 2 + (METER_SEGMENT / 5) * (percentage - 95)
    );
  } else if (percentage >= 5 && percentage <= 95) {
    return Math.round(METER_SEGMENT + (METER_SEGMENT / 96) * (percentage - 4));
  } else {
    return Math.round((METER_SEGMENT / 5) * percentage);
  }
};
