import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { FormProvider, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileHeart from "../../assets/icons/fileHeart";
import SearchReflation from "../../assets/icons/searchReflation";
import Button from "../../atoms/Button";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import { updateProfileAction } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { useHelpModalContext } from "../../context/HelpModalContext";
import Map, { MapViewMode, MultiPolygonType } from "../../molecules/map";
import MarkerPopup from "../../molecules/markerPopup/MarkerPopup";
import { pageNameFn } from "../../organisms/auth/enum";
import CreateList from "../../organisms/createList/CreateList";
import {
  setPropertyCardsLoading,
  toggleCreateListModal,
} from "../../organisms/createList/reducer";
import { fetchSkipPropertyCounts } from "../../organisms/createList/sagaActions";
import {
  getOpenCreateListModal,
  getPropertyCardsLoading,
} from "../../organisms/createList/selectors";
import PropertySearchFilter from "../../organisms/propertySearchFilter/PropertySearchFilter";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
} from "../../organisms/propertySearchFilter/constants";
import {
  resetPropertySearchState,
  setAddressPredictions,
  setClearPropertySearch,
  setSearchTerm,
  setSearchedProperties,
} from "../../organisms/propertySearchFilter/reducer";
import { sendEmailAction } from "../../organisms/propertySearchFilter/sagaActions";
import {
  getAddressPredictions,
  getPropertiesList,
  getPropertiesListLoading,
  getSearchPropertiesPotentialCount,
  getSearchPropertiesResultCount,
} from "../../organisms/propertySearchFilter/selectors";
import { createAdvancedFilters } from "../../organisms/propertySearchFilter/utils";
import WelcomeModal from "../../organisms/welcomeModal/WelcomeModal";
import { fbc, fbp, fetchIp, formartNumberAsLocalString, formatCurrency, hashNumber, hashPhoneNumber, hashString } from "../../utils/utils";
import styles from "./PropertySearch.module.scss";
import { MailTypeEnum } from "./enum";
import { getMeterPercentage } from "./utils";
import { advancedFilterFormSchema } from "./validation";
import { setCreateLeads } from "../../organisms/addLeads/reducer";
import { createLead } from "../leads/leadsService";
import FallbackPropertyImage from "../../assets/images/no-property-image-found.png";
import { ModalCloseReason } from "../../organisms/welcomeModal/interface";
import { FbConversionApi } from "../../organisms/auth/authServices";

export interface MapShapesPayloadRaw {
  polylines: google.maps.Polyline[];
}

export interface MapShapesPayload {
  multi_polygon?: MultiPolygonType;
}

const PropertySearch = () => {
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(advancedFilterFormSchema),
    defaultValues: DEFAULT_FORM_VALUE,
  });
  const { state } = useLocation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { modalContent, setModalContent } = useHelpModalContext();
  const [mapShapesPayload, setMapShapesPayload] =
    useState<MapShapesPayload | null>(null);
  const [mapRightActive, setMapRightActive] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [disableSearch, setDisableSearch] = useState<boolean>(false);
  const openCreateList = useSelector((state) => getOpenCreateListModal(state));
  const [propertySearchFilter, setPropertySearchFilter] = useState<any>({});
  const [pageNumber, setPageNumber] = useState(0);
  const [meterPercentage, setMeterPercentage] = useState(0);
  const [hoveredProperty, setHoveredProperty] = useState<any>(null);
  const [appliedAdvancedFiltersFormState, setAppliedAdvancedFiltersFormState] =
    useState<any>(DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE);
  const addressPredictions = useSelector((state) =>
    getAddressPredictions(state)
  );
  const propertyCardsLoading = useSelector((state) =>
    getPropertyCardsLoading(state)
  );
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const getAdvancedFilterObject = (data: any) => {
    let advancedFilter = {};
    const filterArray = createAdvancedFilters(data);
    filterArray.forEach((filter) => {
      advancedFilter = { ...advancedFilter, ...filter };
    });
    return advancedFilter;
  };

  const dispatchToggleCreateListModalAction = (state: boolean) => {
    dispatch(toggleCreateListModal(state));
    const advancedFilter = getAdvancedFilterObject(
      appliedAdvancedFiltersFormState
    );
    if (
      state &&
      (appliedAdvancedFiltersFormState.searchTerm || mapShapesPayload)
    ) {
      let address = addressPredictions.find((el: any) => {
        return (
          Object.values(el).join(",") ==
          appliedAdvancedFiltersFormState.searchTerm
        );
      });
      const filter = {
        ...appliedAdvancedFiltersFormState.bedsAndBathFilter,
        ...(appliedAdvancedFiltersFormState.marketStatus ?? {}),
        ...(appliedAdvancedFiltersFormState.propertyTypeFilter ?? {}),
        ...advancedFilter,
        ...address,
        ...mapShapesPayload,
        size: 3,
        resultIndex: pageNumber * 3,
      };
      setPropertySearchFilter(filter);
      dispatch(fetchSkipPropertyCounts(filter));
    }
  };

  const page: any = pageNameFn(location?.pathname.split("/")[1]);

  const handleClose = (event : React.SyntheticEvent<Element, Event>,reason : ModalCloseReason) => {
    if(reason === "backdropClick" || reason === "escapeKeyDown") return;

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      dispatch(
        updateProfileAction({
          [page?.pagename]: true,
          organisationName: profileInfo?.organisation?.organisationName,
        })
      );
    }
    setOpenWelcome(false);
  };

  const getData = async () => {
    if (state?.prevPath?.includes("/onboarding")) {
      const emailData = {
        type: MailTypeEnum.WELCOME_EMAIL_ON_SIGNUP,
        data: {
          name: `${profileInfo?.nameFirst} ${profileInfo.nameLast}`,
          cardLastDigit: "1234",
        },
      };

      // dispatch(sendEmailAction(emailData));
    }
  };

  // const handleBtn = () => {
  //   if (btnText === "Continue") {
  //     setModalContent({
  //       ...modalContent,
  //       title: page?.title,
  //       subtitle: page?.subtitle,
  //       video: page?.video,
  //     });
  //     setBtnText("Got it");
  //   }
  //   if (btnText === "Got it") {
  //     handleClose();
  //   }
  // };

  const propertiesListLoading = useSelector((state) =>
    getPropertiesListLoading(state)
  );
  const propertiesList = useSelector((state) => getPropertiesList(state));

  const searchPropertiesResultCount = useSelector((state) =>
    getSearchPropertiesResultCount(state)
  );
  const searchPropertiesPotentialCount = useSelector((state) =>
    getSearchPropertiesPotentialCount(state)
  );

  const handleNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const getListSizeText = () => {
    const result = searchPropertiesPotentialCount
      ? Math.round(
          (searchPropertiesResultCount / searchPropertiesPotentialCount) * 100
        )
      : 0;
    if (result > 95) {
      return "Your list is too broad.";
    } else if (result <= 95 && result >= 5) {
      return "Your list size is excellent.";
    } else if (result < 5) {
      return "Your list is narrow.";
    }
  };

  const clearSearch = () => {
    dispatch(setClearPropertySearch(true));
  };

  const handlePropertyClick = (property: any) => {
    setSelectedProperty(property);
    handleMarkerClickFn(property);
  };

  const handleMapApplyButtonClick = useCallback(
    (payload: MapShapesPayloadRaw) => {
      if (payload.polylines.length) {
        const newMapShapesPayload: MapShapesPayload = {};
        const multi_polygon: MultiPolygonType = [];
        payload.polylines.forEach((polyline) => {
          const points = polyline.getPath().getArray();
          const result = points.map((point) => ({
            lat: point.lat().toString(),
            lon: point.lng().toString(),
          }));
          multi_polygon.push(result);
        })!;
        newMapShapesPayload.multi_polygon = multi_polygon;
        setMapShapesPayload(newMapShapesPayload);
      } else {
        setMapShapesPayload(null);
      }
      dispatch(setSearchTerm(" "));
    },
    [dispatch]
  );

  const handleMapRemoveBoundariesButtonClick = useCallback(() => {
    dispatch(setSearchedProperties({ data: {results_count: -1} }));
    setMapShapesPayload(null);
    setDisableSearch(false);
    dispatch(setSearchTerm(""));
  }, [dispatch]);

  const handleMapDrawButtonClick = useCallback(() => {
    methods.setValue("searchTerm", "");
    // setDisableSearch(true);
  }, [methods]);

  const handleMapCancelButtonClick = useCallback(() => {
    setDisableSearch(false);
  }, []);

  const toggleMarker = useCallback(
    (id: number | string) => {
      const property = propertiesList.find(
        (property: any) => property?.id === id
      );
      setSelectedProperty(property ?? null);
    },
    [propertiesList]
  );

  const handleMarkerClickFn = async (property: any) => {
    dispatch(setCreateLeads({}));
    try {
      const payload = {
        nameFirst: property?.owner1FirstName
          ? property?.owner1FirstName
          : property?.owner2FirstName
          ? property?.owner2FirstName
          : "-",
        nameLast: property?.owner1LastName
          ? property?.owner1LastName
          : property?.owner2LastName
          ? property?.owner2LastName
          : "-",
        addressStreet: property?.address?.street
          ? property?.address?.street
          : "-",
        addressCity: property?.address?.city ? property?.address?.city : "-",
        addressState: property?.address?.state ? property?.address?.state : "-",
        addressZip: property?.address?.zip ? property?.address?.zip : "-",
        phones: "-",
        isAddFromSearch: true,
      };
      dispatch(setPropertyCardsLoading(true));
      const newLeadCreated = await createLead(payload);
      if (newLeadCreated?.data?.property_id) {
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.href = `/properties/${newLeadCreated?.data?.property_id}`;
        link.target = "_blank";
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(setPropertyCardsLoading(false));
    }
  };

  const markers = useMemo(() => {
    return propertiesList?.map((property: any) => {
      const features: {
        [key: string]: any;
      } = {
        bed: property?.bedrooms ? property?.bedrooms : "-",
        bath: property?.bathrooms ? property?.bathrooms : "-",
        sqft: property?.livingSquareFeet
          ? formartNumberAsLocalString(property?.livingSquareFeet)
          : "-",
        "lot sqft": property?.lotSquareFeet
          ? formartNumberAsLocalString(property?.lotSquareFeet)
          : "-",
        "year built": property?.yearBuilt ? property?.yearBuilt : "-",
      };

      const propertyfeaturesAsString = Object.keys(features)
        .map((key) => (features[key] ? `${features[key]} ${key}` : null))
        .filter(Boolean)
        .join(" | ");

      return {
        id: property?.id ?? 0,
        coordinate: {
          lat: property?.latitude ?? 0,
          lng: property?.longitude ?? 0,
          altitude:20
        } as google.maps.LatLngAltitudeLiteral,
        component: (
          <MarkerPopup
            onClose={() => {
              setSelectedProperty(null);
            }}
            headerText={`${
              property?.address?.street ? property?.address?.street + ", " : ""
            }${property?.address?.city ? property?.address?.city + ", " : ""}${
              property?.address?.state ?? ""
            } ${property?.address?.zip ?? ""}`}
            infoText={propertyfeaturesAsString}
            handleMarkerClick={() => handleMarkerClickFn(property)}
          ></MarkerPopup>
        ),
        hovered: property.id === hoveredProperty,
        streetno:property?.address?.street
      };
    });
  }, [propertiesList]);

  const handleMouseHover = (property: any) => {
    setHoveredProperty(property?.id);
  };

  const handleMouseLeave = () => {
    setHoveredProperty(null);
  };

  useEffect(() => {
    dispatch(setAddressPredictions([]));
    return () => {
      dispatch(resetPropertySearchState({}));
      dispatch(setSearchTerm(""));
    };
  }, []);

  useEffect(() => {
    const percentage =
      searchPropertiesPotentialCount > 0
        ? (searchPropertiesResultCount / searchPropertiesPotentialCount) * 100 >
          100
          ? 100
          : (searchPropertiesResultCount / searchPropertiesPotentialCount) * 100
        : 0;

    setMeterPercentage(getMeterPercentage(percentage));
  }, [searchPropertiesResultCount, searchPropertiesPotentialCount]);


  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Property_Search_View",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(profileInfo?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  // useEffect(() => {
  //   if (state?.prevPath?.includes("/onboarding")) {
  //     setOpenWelcome(true);
  //     setModalValues({
  //       ...modalValues,
  //       title: "Welcome to OttoLeads!",
  //       subtitle: "Here is a special message from our founders.",
  //       video: "https://dkmwbxteeq2p9.cloudfront.net/videos/video-launch.mp4",
  //       btnText: "Continue",
  //     });
  //   }
  // }, [state?.prevPath]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      setOpenWelcome(true);
      // setBtnText("Continue");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !propertiesList?.length ||
      propertiesList.length === searchPropertiesResultCount
    )
      return;

    const parent = document.getElementById("scrollableDiv1");
    const child = parent?.getElementsByClassName(
      "infinite-scroll-component "
    )[0];

    const parentHeight = parent?.clientHeight;
    const childHeight = child?.clientHeight;

    if (!parentHeight || !childHeight || childHeight >= parentHeight) {
      return;
    }

    handleNextPage();
  }, [propertiesList, searchPropertiesResultCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <>
      <div className={` ${styles.propertySearch}`}>
        <div className="wrapper bottom-zero">
          <FormProvider {...methods}>
            <PropertySearchFilter
              setMapRightActive={setMapRightActive}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              appliedAdvancedFiltersFormState={appliedAdvancedFiltersFormState}
              setAppliedAdvancedFiltersFormState={
                setAppliedAdvancedFiltersFormState
              }
              mapShapesPayload={mapShapesPayload}
              disableSearch={disableSearch}
            ></PropertySearchFilter>
          </FormProvider>
        </div>
        <div className={`dflex ${styles.mapSection} `}>
          <div
            className={`${styles.mapSection__map} ${
              mapRightActive ? styles.rightActive : ""
            }`}
          >
            <Map
              height="calc(100vh - 136px)"
              selectedMarkers={
                selectedProperty?.id ? [selectedProperty?.id] : []
              }
              toggleMarker={toggleMarker}
              markers={markers}
              coordinate={{
                lat: selectedProperty
                  ? selectedProperty?.latitude
                  : propertiesList?.[0]?.latitude ?? null,
                lng: selectedProperty
                  ? selectedProperty?.longitude
                  : propertiesList?.[0]?.longitude ?? null,
              }}
              initialViewMode={MapViewMode.ROADMAP}
              allowToDrawShapes={true}
              onApplyButtonClick={handleMapApplyButtonClick}
              onRemoveBoundariesButtonClick={
                handleMapRemoveBoundariesButtonClick
              }
              onDrawButtonClick={handleMapDrawButtonClick}
              onCancelButtonClick={handleMapCancelButtonClick}
              hovered={hoveredProperty}
            />
          </div>
          {/* {state?.prevPath?.includes("/onboarding") && (
            <WelcomeModal
              title={modalValues.title}
              subtitle={modalValues.subtitle}
              button={
                <Button
                  action={handleBtn}
                  className="primary xl full"
                  label={modalValues.btnText}
                />
              }
              video="https://dkmwbxteeq2p9.cloudfront.net/videos/video-launch.mp4"
              open={openWelcome}
              handleClose={handleClose}
            />
          )} */}
          {openWelcome && (
            <WelcomeModal
              title={modalContent.title}
              subtitle={modalContent.subtitle}
              button={
                <Button
                  action={handleClose}
                  className="primary xl full"
                  label={"Got it"}
                />
              }
              video={modalContent.video}
              open={openWelcome}
              handleClose={handleClose}
              termsOfUseFlag={true}
            />
          )}
          {mapRightActive && (
            <div className={`${styles.mapSection__right}`}>
              {propertiesListLoading && pageNumber === 0 ? (
                <div className={` ${styles.loader}`}>
                  <Loader></Loader>
                </div>
              ) : (
                <>
                  <div className={` ${styles.box}`}>
                    <h2>List Size</h2>
                    <div className={`flex ${styles.box__info}`}>
                      <div className={` ${styles.meter}`}>
                        <div className={` ${styles.meter__inner}`}>
                          <ReactSpeedometer
                            maxValue={100}
                            value={meterPercentage}
                            currentValueText=""
                            needleColor="#344054"
                            segments={3}
                            ringWidth={10}
                            maxSegmentLabels={0}
                            width={200}
                            height={200}
                            segmentColors={["#F04438", "#17B26A", "#FEDF89"]}
                          />
                        </div>
                        <div className="flex alignCenter justifySpaceBetween">
                          <p>Specific</p>
                          <p>Broad</p>
                        </div>
                      </div>
                      <div className={` ${styles.meterInfo}`}>
                        <p>
                          Your list size is defined by your search and filters.
                        </p>
                        <h6> {getListSizeText()}</h6>
                      </div>
                    </div>
                    <div
                      className={`dflex alignCenter justifySpaceBetween ${styles.box__footer}`}
                    >
                      <p>
                        Results:{" "}
                        <strong>
                          {formartNumberAsLocalString(
                            searchPropertiesResultCount
                          )}{" "}
                        </strong>
                      </p>
                      <Button
                        label="Create List"
                        className="primary animated"
                        prefix={<FileHeart />}
                        disabled={propertiesList.length === 0}
                        action={() => dispatchToggleCreateListModalAction(true)}
                      />
                    </div>
                  </div>

                  {propertiesList.length > 0 ? (
                    <>
                      <div className={`${styles.propertyListMain}`}>
                        <div
                          className={`${styles.propertyList}`}
                          id="scrollableDiv1"
                        >
                          {propertyCardsLoading && (
                            <div className={`${styles.propertyListLoader}`}>
                              <div className="dot-pulse"></div>
                            </div>
                          )}
                          <InfiniteScroll
                            dataLength={propertiesList.length}
                            next={() => handleNextPage && handleNextPage()}
                            hasMore={
                              propertiesList.length <
                              searchPropertiesResultCount
                            }
                            scrollableTarget="scrollableDiv1"
                            loader={
                              <div className="table-loader">
                                <Loader></Loader>
                              </div>
                            }
                          >
                            {propertiesList?.map(
                              (property: any, index: number) => {
                                return (
                                  <div
                                    key={`${property?.id}_${index}`}
                                    id={`${property?.id}_${index}`}
                                    className={`${styles.box} ${
                                      property?.id === selectedProperty?.id
                                        ? styles.boxActive
                                        : ""
                                    } `}
                                    onClick={() =>
                                      handlePropertyClick(property)
                                    }
                                    onMouseEnter={() =>
                                      handleMouseHover(property)
                                    }
                                    onMouseLeave={() => handleMouseLeave()}
                                  >
                                    <div
                                      role="link"
                                      className={`flex ${styles.propertyCard}`}
                                    >
                                      <figure>
                                        <img
                                          src={
                                            property.propertyImageUrl ??
                                            FallbackPropertyImage
                                          }
                                          alt="property"
                                        />
                                      </figure>
                                      <figcaption>
                                        <h5>{`${
                                          property?.address?.street
                                            ? property?.address?.street + ", "
                                            : ""
                                        }${
                                          property?.address?.city
                                            ? property?.address?.city + ", "
                                            : ""
                                        }${property?.address?.state ?? ""} ${
                                          property?.address?.zip ?? ""
                                        }`}</h5>
                                        <p>
                                          Est. Value:{" "}
                                          <strong>{`${
                                            property?.estimatedValue
                                              ? formatCurrency(
                                                  property?.estimatedValue
                                                )
                                              : "N/A"
                                          }`}</strong>
                                        </p>
                                        <p>
                                          Last Sale:{" "}
                                          <strong>{`${
                                            property?.lastSaleDate
                                              ? dayjs(
                                                  property?.lastSaleDate
                                                ).format("L")
                                              : "-"
                                          }`}</strong>
                                        </p>
                                        <p>
                                          Sold Price:{" "}
                                          <strong>
                                            {`${formatCurrency(
                                              property?.lastSaleAmount
                                            )}`}{" "}
                                          </strong>
                                        </p>
                                        <p>
                                          <strong>
                                            {property?.bedrooms &&
                                            property?.bedrooms !== 0
                                              ? property?.bedrooms
                                              : "-"}
                                          </strong>{" "}
                                          bed |{" "}
                                          <strong>
                                            {property?.bathrooms &&
                                            property?.bathrooms !== 0
                                              ? property?.bathrooms
                                              : "-"}
                                          </strong>{" "}
                                          bath |{" "}
                                          <strong>
                                            {formartNumberAsLocalString(
                                              property?.squareFeet
                                            ) ?? "-"}
                                          </strong>{" "}
                                          sqft{" "}
                                        </p>
                                      </figcaption>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`flex alignCenter justifyCenter ${styles.empty} ${styles.box}`}
                      >
                        <EmptyPlaceholder
                          title="No results found"
                          description="We couldn’t find any results that match your search or filtering criteria."
                          icon={<SearchReflation />}
                          button1={
                            <Button
                              label="Clear search"
                              className="outline"
                              action={clearSearch}
                            />
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {openCreateList && (
        <CreateList
          open={openCreateList}
          setOpen={dispatchToggleCreateListModalAction}
          propertySearchFilter={propertySearchFilter}
        ></CreateList>
      )}
    </>
  );
};
export default PropertySearch;
