import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  leadList: [] as any[],
  total: 0,
  leadListIsLoading: false,
};

const leadSlice = createSlice({
  name: "leadsTable",
  initialState,
  reducers: {
    setLeadList: (state, { payload: { data } }) => {
      return {
        ...state,
        leadList: data?.result.map((item : any) => item={...item, isLoading:false} ) ?? [],
        total: data?.count ?? 0,
      };
    },
    appendLeadList: (state, { payload: { data } }) => {
      return {
        ...state,
        leadList: [...state?.leadList, ...(data?.result ? data?.result : [])].map((item : any) => item={...item, isLoading:false}),
        total: data.count,
      };
    },
    handleLoaderForRow: (state, { payload: { data } }) => {
      const { id, key,value } = data;
      const leadListCopy = [...state.leadList];
      const index = state.leadList.findIndex((e) => {
        return id === e.id
      })
      if(index !== -1){
        let temp=leadListCopy[index];
        leadListCopy[index] = JSON.parse(JSON.stringify({...temp,[key]:value}))
      }
      return {
        ...state,
        leadList: leadListCopy
      };
    },
    setLeadListIsLoading: (state, { payload }) => {
      return { ...state, leadListIsLoading: payload };
    },
    resetLeadState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setLeadList,
  appendLeadList,
  setLeadListIsLoading,
  resetLeadState,
  handleLoaderForRow,
} = leadSlice.actions;

export default leadSlice.reducer;
