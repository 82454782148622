import { deleteAPI, getAPI, postAPI, putAPI } from "../../apis/apis";

export const getPropertySingleSkipStatus = async (propertyId: string | number) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-details/${propertyId}/skip-trace-status`,
  });
};

export const propertySingleSkip = async (propertyId: string | number) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-details/${propertyId}/skip-trace`,
  });
};

export const getPropertyComment = (id:any,payload:any) => {
    return getAPI({
      url:
        `${process.env.REACT_APP_BASE_URL}/property-comment/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const postPropertyComment = async (id:any,payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-comment`,
      headers: {},
      data: payload,
    });
  };

  export const putPropertyComment = async (commentId:any,payload: any) => {
    return putAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-comment/${commentId}`,
      headers: {},
      data: payload,
    });
  };

  export const deletePropertyComment = async (commentId:any) => {
    return deleteAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-comment/${commentId}`,
      headers: {},
    });
  };

  export const propertyCount = async (payload:any) => {
    return postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/advanced-search-filter`,
      headers: {},
      data: payload,
    });
  };

  export const ownerNameUpdate = async (id:any,payload:any) => {
    return postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-owner/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const resetOwnerName = async (id:any) => {
    return postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-owner/reset/${id}`,
      headers: {},
      
    });
  };
  export const deleteContact = async (id:any) => {
    return deleteAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-phone/${id}`,
      headers: {},
    });
  };
 
  export const addPropertyPhone = async (payload:any) => {
    return postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-phone`,
      headers: {},
      data: payload,
    });
  };

  export const addPropertyEmail = async (payload:any) => {
    return postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-email`,
      headers: {},
      data: payload,
    });
  };

  export const deleteEmail = async (id:any) => {
    return deleteAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-email/${id}`,
      headers: {},
    });
  };