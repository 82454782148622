import Close from "../../assets/icons/cross";
import HomeIcon from "../../assets/icons/homeIcon";
import styles from "./MarkerPopup.module.scss";
const MarkerPopup = ({
  onClose,
  headerText,
  infoText,
  handleMarkerClick,
}: any) => {
  return (
    <>
      <div className={`dflex alignCenter ${styles.mapInfo}`}>
        <div
          onClick={handleMarkerClick}
          className={`dflex alignCenter ${styles.mapInfo__inner}`}
        >
          <figure>
            <HomeIcon />
          </figure>
          <figcaption>
            <h2>{headerText}</h2>
            <p>{infoText}</p>
          </figcaption>
        </div>
        <div
          onClick={handleMarkerClick}
          className={`dflex alignCenter ${styles.mapArrow}`}
        ></div>
        <span
          className={`${styles.mapInfo__close}`}
          role="link"
          onClick={onClose}
          onTouchEnd={onClose}
        >
          <Close />
        </span>
      </div>
    </>
  );
};
export default MarkerPopup;
