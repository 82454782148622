import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DropDownIcon from "../../assets/icons/dropDownIcon";
import styles from "./ProfileMenu.module.scss";
import Setting from "../../assets/icons/setting";
import HelpCircle from "../../assets/icons/helpCircle";
import Logout from "../../assets/icons/logout";
import { Link, NavLink } from "react-router-dom";
import Life from "../../assets/icons/life";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "./selectors";
import { setProfileInfo } from "./reducer";
import { useMobileMenuDrawerContext } from "../../context/MobileMenuDrawerContext";

declare global {
  interface Window {
    Intercom: any;
  }
}

export default function ProfileMenu({ onLogout }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const { closeDrawer } = useMobileMenuDrawerContext();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAndRemoveToken = () => {
    handleClose();
    onLogout();
    dispatch(setProfileInfo({ payload: {} }));
  };

  const handleClickIntercomLauncher = () => {
    window?.Intercom("show");
  };

  return (
    <div className={`${styles.profileMenu}`} >
      <ul>
        <li
          onClick={() => {
            closeDrawer();
          }}
        >
          {/* <NavLink
            to="/"
            role="link"
            className={({ isActive }) => ` ${isActive ? styles.active : ""}`}
            onClick={closeDrawer}
          > */}
          <span
            onClick={() => handleClickIntercomLauncher()}
            role="link"
            className={` ${styles.profileMenu}`}
          >
            <span className={` ${styles.navIcon}`}>
              <Life />
            </span>
            Support
          </span>
          {/* </NavLink> */}
        </li>
        <li >
          <NavLink
            to="/settings"
            role="link"
            className={({ isActive }) => ` ${isActive ? styles.active : ""}`}
            onClick={closeDrawer}
           
          >
            <span className={` ${styles.navIcon}`}>
              <Setting />
            </span>
            Settings
          </NavLink>
        </li>
        <li onClick={onLogout}>
          <span
            className={`flex justifySpaceBetween ${styles.logout}`}
            onClick={closeDrawer}
          >
            <figcaption>
              <p>
                <strong>{profileInfo?.nameFirst}</strong>
                {profileInfo?.email}
              </p>
            </figcaption>
            <figure>
              <Logout />
            </figure>
          </span>
        </li>
      </ul>

      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        role="link"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={` ${styles.profileMenu__item}`}
      >
        <span className={` ${styles.userName}`}>
          {profileInfo && profileInfo?.nameFirst ? profileInfo?.nameFirst : ""}{" "}
          {profileInfo && profileInfo.nameLast ? profileInfo.nameLast : ""}
        </span>
        <DropDownIcon />
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="profileMenuList"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <span onClick={() => handleClickIntercomLauncher()}>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <span className={` ${styles.menuLink}`}>
              <HelpCircle /> Support
            </span>
          </MenuItem>
        </span>
        <MenuItem data-testid="settings" onClick={handleClose}>
          <Link to="/settings">
            <Setting />
            Settings
          </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseAndRemoveToken}>
          <span className={` ${styles.menuLink}`}>
            <Logout />
            Log out
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}
