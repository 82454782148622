const Award = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M6.55722 12.8836L5.83333 18.3337L9.657 16.0395C9.78171 15.9646 9.84406 15.9272 9.91063 15.9126C9.96951 15.8997 10.0305 15.8997 10.0894 15.9126C10.1559 15.9272 10.2183 15.9646 10.343 16.0395L14.1667 18.3337L13.4433 12.8812M13.6882 3.54106C13.8169 3.85232 14.064 4.09974 14.375 4.22891L15.4658 4.68073C15.7771 4.80967 16.0244 5.05699 16.1533 5.36828C16.2822 5.67957 16.2822 6.02933 16.1533 6.34062L15.7018 7.43062C15.5728 7.74205 15.5726 8.09216 15.7022 8.40344L16.1529 9.49312C16.2168 9.64729 16.2498 9.81256 16.2498 9.97946C16.2498 10.1464 16.2169 10.3116 16.1531 10.4658C16.0892 10.62 15.9956 10.7601 15.8775 10.8781C15.7595 10.9961 15.6194 11.0897 15.4652 11.1535L14.3752 11.605C14.064 11.7337 13.8166 11.9808 13.6874 12.2919L13.2356 13.3826C13.1067 13.6939 12.8593 13.9412 12.5481 14.0702C12.2368 14.1991 11.887 14.1991 11.5758 14.0702L10.4858 13.6187C10.1745 13.4901 9.82489 13.4903 9.5138 13.6194L8.42306 14.0706C8.11195 14.1992 7.76251 14.1991 7.45148 14.0703C7.14045 13.9415 6.89328 13.6944 6.76426 13.3835L6.31232 12.2924C6.18363 11.9811 5.93659 11.7337 5.62553 11.6045L4.53479 11.1527C4.22365 11.0238 3.97641 10.7766 3.84743 10.4655C3.71844 10.1544 3.71827 9.80482 3.84694 9.49358L4.29841 8.40359C4.42703 8.09229 4.42677 7.74265 4.29768 7.43154L3.84686 6.33998C3.78294 6.1858 3.75003 6.02054 3.75 5.85364C3.74997 5.68673 3.78283 5.52146 3.8467 5.36726C3.91057 5.21306 4.0042 5.07296 4.12223 4.95496C4.24026 4.83696 4.38039 4.74338 4.5346 4.67956L5.62456 4.22807C5.93554 4.09947 6.1828 3.85274 6.31206 3.54203L6.76386 2.45125C6.8928 2.13996 7.14011 1.89264 7.45139 1.7637C7.76267 1.63476 8.11242 1.63476 8.4237 1.7637L9.51365 2.21519C9.82494 2.34381 10.1746 2.34355 10.4857 2.21446L11.5769 1.7644C11.8881 1.63553 12.2378 1.63556 12.549 1.76447C12.8602 1.89339 13.1075 2.14063 13.2364 2.45184L13.6884 3.54295L13.6882 3.54106Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Award;

