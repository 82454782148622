import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Details.module.scss";
import Message from "../../assets/icons/message";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileDetailsSchema } from "./validation";
import userpool from "../../userpool";
import { updateUserProfile } from "./settingService";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import VerifyOtp from "../verifyOtp/VerifyOtp";
import { checkUserInDb } from "../../apis/user";
import MaskedInput from "../../atoms/MaskedInput";
import { setProfileInfo } from "../../components/profileMenu/reducer";
import Loader from "../../atoms/Loader";
import Close from "../../assets/icons/cross";

export const Details = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(profileDetailsSchema),
  });
  const dispatch = useDispatch();
  const [openVerifyOtp, setOpenVerifyOtp] = useState<boolean>(false);
  const [laoding, setLoading] = useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  const [deleteLoading, setDeleteLoading] = useState(false);
  const onSubmit = async (data: any) => {
    if (watch("email") !== profileInfo?.email) {
      setLoading(true);
      try {
        const res = await checkUserInDb(watch("email"));
        if (res?.data) {
          displayErrorMessage({ message: "This email address already taken!" });
          return;
        } else {
          await callUpdateProfile({
            name: watch("name"),
            organisationName: watch("companyName"),
            email: watch("email"),
            callbackPhone:data?.callbackPhone
          });
        }
      } catch (err) {
        console.log(err);
        return;
      } finally {
        setLoading(false);
      }
      // const cognitoUser = userpool.getCurrentUser();
      // await new Promise((res) => cognitoUser?.getSession(res));
      // const attribute: any = new CognitoUserAttribute({
      //   Name: "email",
      //   Value: watch("email"),
      // });
      // const attributeList = [attribute];
      // cognitoUser?.updateAttributes(attributeList, async (err, result) => {
      //   setLoading(false);
      //   if (err) {
      //     displayErrorMessage(err);
      //     return;
      //   }
      //   setOpenVerifyOtp(true);
      // });
    } else {
      await callUpdateProfile({
        name: watch("name"),
        organisationName: watch("companyName"),
        callbackPhone:data?.callbackPhone
      });
    }
  };

  const verifyAttribute = async (otp: string) => {
    const cognitoUser = userpool.getCurrentUser();
    setOtpVerificationLoading(true);
    await new Promise((res) => cognitoUser?.getSession(res));
    cognitoUser?.verifyAttribute("email", otp, {
      onSuccess: async () => {
        setOtpVerificationLoading(false);
        setOpenVerifyOtp(false);
        await callUpdateProfile({
          name: watch("name"),
          email: watch("email"),
          organisationName: watch("companyName"),
        });
      },
      onFailure: (err) => {
        setOtpVerificationLoading(false);
        displayErrorMessage(err);
      },
    });
  };

  const callUpdateProfile = async (data: any) => {
    try {
      setLoading(true);
      await updateUserProfile(data);
      displaySuccessMessage("User profile updated successfully");
      dispatch(fetchProfileInfo({}));
      setLoading(false);
    } catch (error) {
      displayErrorMessage(error);
      setLoading(false);
    }
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  useEffect(() => {
    setValue(
      "name",
      `${profileInfo?.nameFirst ?? ""} ${profileInfo?.nameLast ?? ""}`
    );
    setValue("companyName", profileInfo?.organisation?.organisationName);
    setValue("email", profileInfo?.email);
    setValue("callbackPhone", `${profileInfo?.callbackPhone?? ""}` );
  }, [profileInfo]);

  useEffect(()=>{
dispatch(fetchProfileInfo({}))
  },[])

  return (
    <div className={`${styles.details}`}>
      <div className={`${styles.details__header}`}>
        <h3> Personal Info</h3>
        <p>Update your personal details here.</p>
      </div>
      <div className={`${styles.details__form}`}>
        <form>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              label="Name*"
              register={register}
              name="name"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              prefix={<Message />}
              label="Email address*"
              register={register}
              name="email"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              label="Company name*"
              register={register}
              name="companyName"
              errors={errors}
              hint="This is the name we use to represent your business when calling prospects."
            ></Input>
          </div>

          <div className={`${styles.input}`}>
            <MaskedInput
              placeholder="Enter the phone number"
              label="Callback phone number"
              name="callbackPhone"
              register={register}
              errors={errors}
              maskChar={""}
              mask={"(999) 999-9999"}
              hint="This number connects prospects directly to your business when they return your call."
            ></MaskedInput>
          </div>
          <div className={`dflex justifyEnd ${styles.button}`}>
            {/* <Button label="Cancel" className="outline" action={handleSubmit(onSubmit)}></Button> */}
            <Button
              type="submit"
              label="Save"
              className="primary"
              action={handleSubmit(onSubmit)}
              clicked={laoding}
              disabled={laoding}
            ></Button>
          </div>
        </form>
      </div>
      {openVerifyOtp && (
        <VerifyOtp
          open={openVerifyOtp}
          setOpen={setOpenVerifyOtp}
          onSubmit={verifyAttribute}
          userDetails={{ email: watch("email") }}
          otpVerificationLoading={otpVerificationLoading}
        ></VerifyOtp>
      )}
    </div>
  );
};
