import { initialState } from "./reducer";

const getStateData = (state: any) => state["leadsTable"] || initialState;

export const getLeadsList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadList;
};

export const getLeadListTotal=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.total
}

export const getLeadListIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadListIsLoading;
};