import { useEffect, useState, useLayoutEffect } from "react";
import Close from "../assets/icons/cross";
import Search from "../assets/icons/search";
import SearchSm from "../assets/icons/searchSm";
import EmptyPlaceholder from "./EmptyPlacholder";
import Loader from "./Loader";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const SearchDropDown = ({
  options,
  handleInputChange,
  handlePredictionClick,
  searchText,
  searchIconRight,
  closeIcon,
  placeholder,
  focused,
  handleCloseClick,
  handleFocusOut,
  handleKeyPress,
  isSearchLoading,
  onSearchClick,
  handleOnFocus,
  searchRef,
  emptySearchMetaData,
  isSearched,
  disabled = false,
  handleSearchIconClick,
}: any) => {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (options.length === 0) return;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex <= 0 ? options.length - 1 : prevIndex - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === options.length - 1 ? 0 : prevIndex + 1
        );
        break;
      case "Enter":
        e.preventDefault();
        // if (highlightedIndex !== 0) {
        handlePredictionClick(
          options[highlightedIndex]?.value,
          options[highlightedIndex]?.label
        );
        setHighlightedIndex(0);
        // }
        break;
      default:
        break;
    }
  };

  useLayoutEffect(() => {
    const highlightedItem = document.querySelector(".highlighted");
    if (highlightedItem) {
      highlightedItem.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedIndex]);

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputBlur = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setHighlightedIndex(0);
    }
  }, [isOpen]);

  const renderPredictions = () => {
    return (
      <ul className="item-list">
        {options.length ? (
          options.map((prediction: any, index: number) => (
            <li
              key={`${prediction?.value}_${index}`}
              onClick={() =>
                handlePredictionClick(prediction?.value, prediction?.label)
              }
              className={`flex alignCenter justifySpaceBetween ${
                index === highlightedIndex ? "highlighted" : ""
              }`}
            >
              <span>{prediction.label}</span>
              {Boolean(prediction.searchType) && (
                <small>{prediction.searchType}</small>
              )}
            </li>
          ))
        ) : searchText && !isSearchLoading && isSearched ? (
          <EmptyPlaceholder
            icon={emptySearchMetaData?.icon}
            title={emptySearchMetaData?.title}
            description={emptySearchMetaData?.description}
          />
        ) : null}
      </ul>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleInputBlur}>
        <div
          className={`search ${searchIconRight ? "searchIconRight" : ""} ${
            closeIcon ? "closeIcon" : ""
          } ${focused ? "focused" : ""}`}
        >
          <span
            role="link"
            className="search__icon"
            onClick={() => !isSearchLoading && onSearchClick()}
          >
            {isSearchLoading ? (
              <Loader size={20}></Loader>
            ) : searchIconRight ? (
              <span
                onClick={() =>
                  {
                   if(options.length> 0) {
                    handlePredictionClick(
                      options[highlightedIndex]?.value,
                      options[highlightedIndex]?.label
                    )
                  !isSearchLoading  && handleSearchIconClick()} }
                }
              >
                <SearchSm />
              </span>
            ) : (
              <span
                onClick={() =>{
                  
                  !isSearchLoading &&
                   handleSearchIconClick()}
                }
              >
              <Search />
              </span>
            )}
          </span>
          {closeIcon && (
            <span
              role="link"
              className="search__close"
              onClick={handleCloseClick}
            >
              <Close />
            </span>
          )}

          <input
            type="text"
            placeholder={placeholder}
            value={searchText}
            onChange={(e) => {
              setHighlightedIndex(0);
              handleInputChange(e);
            }}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            ref={searchRef}
            disabled={disabled}
          />
          {isOpen && renderPredictions()}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default SearchDropDown;
