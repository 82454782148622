import React, { useState } from "react";
import { Tabs, Tab, Box, Tooltip } from "@mui/material";
import { Details } from "../../organisms/settingdetails/Details";
import { Security } from "../../pages/security/Security";
import styles from "./SettingHeader.module.scss";
import { Billings } from "../../organisms/billing/Billings";
import { Usage } from "../../organisms/usage/Usage";
import Integrations from "../../organisms/integrations/Integrations";
import { getSubscriptions } from "../../organisms/billing/selectors";
import { useSelector } from "react-redux";
import { SUBSCRITPTION_STATUS } from "../../constants/constants";

export const SettingHeader = () => {
  const [value, setValue] = useState("one");
  const subscriptions = useSelector((state) => getSubscriptions(state));
  return (
    <div className={`${styles.setting}`}>
      <Tabs
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        className={`${styles.setting__header}`}
      >
        <Tab value="one" label="My Details" wrapped data-testid="MyDetails" />
        <Tab value="two" label="Usage" wrapped />
        <Tab value="three" label="Security" wrapped />
        <Tab value="four" label="Billing" wrapped data-testid="Billing" />
          <Tab
            value="five"
            label={
              <Tooltip
                title={
                  subscriptions?.generalSubData
                    ? subscriptions?.generalSubData?.lastPaymentStatus ===
                      "card_declined"
                      ? SUBSCRITPTION_STATUS.CardDecline
                      : ""
                    : SUBSCRITPTION_STATUS.NoGeneralAccess
                }
              >
                <span>Integrations</span>
              </Tooltip>
            }
            wrapped
            data-testid="Integrations"
            disabled={
              !subscriptions?.generalSubData  ||
              subscriptions?.generalSubData?.lastPaymentStatus ===
                "card_declined"
                  }
          />
      </Tabs>

      {value === "one" && <Details />}
      {value === "two" && <Usage />}
      {value === "three" && <Security />}
      {value === "four" && <Billings />}
      {value === "five" && <Integrations />}
    </div>
  );
};
