import { useSelector } from "react-redux";
import SearchReflation from "../../../../assets/icons/searchReflation";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import CustomTable from "../../../../molecules/table/CustomTable";
import { formatCurrency, formatDate } from "../../../../utils/utils";
import {
  mortgageCurrentColumns,
  mortgageHistoryColumns,
  saleColumns,
} from "../../columnsData";
import { getPropertyDetails } from "../../property.selector";
import styles from "./MortgageTab.module.scss";

const handleTableMortgageRowsData = (data: Record<string, any>[]) => {
  return data?.map((el: Record<string, any>) => {
    let a: any = {
      loanPosition: el?.position,
      recordingDate: formatDate(el?.recordingDate),
      loanAmount: formatCurrency(el?.amount),
      estRate: `${el?.interestRate ? el?.interestRate + "%" : "-"}`,
      documentNumber: el?.mortgageId,
      deedType: el?.deedType,
      lenderName: el?.lenderName,
      lenderType: el?.lenderType,
      granteeName: el?.granteeName,
      loanType: el?.loanType,
      activityDrawerLeadId: false,
    };
    return a;
  });
};

const handleTableSaleRowsData = (
  data: Record<string, any>[] | Record<string, any>
) => {
  if (Array.isArray(data)) {
    return data?.map((el: Record<string, any>) => {
      return {
        dateOfSale: formatDate(el?.saleDate),
        amount: formatCurrency(el?.saleAmount),
        purchaseMethod: el?.purchaseMethod,
        documentType: el?.documentType,
        transactionType: el?.transactionType,
        documentNumber: el?.mortgageId,
        sellerName: el?.sellerNames,
        buyerName: el?.buyerNames,
        activityDrawerLeadId: false,
      };
    });
  }
  return [
    {
      dateOfSale: formatDate(data?.saleDate),
      amount: formatCurrency(data?.saleAmount),
      purchaseMethod: data?.purchaseMethod,
      documentType: data?.documentType,
      transactionType: data?.transactionType,
      documentNumber: data?.mortgageId,
      sellerName: data?.sellerNames,
      buyerName: data?.buyerNames,
      activityDrawerLeadId: false,
    },
  ];
};
const MortgageTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { currentMortgages, mortgageHistory, saleHistory, lastSale } =
    propertyDetails?.jsonData;
  const currentMortgagesRows = handleTableMortgageRowsData(currentMortgages);
  const mortgageHistoryRows = handleTableMortgageRowsData(mortgageHistory);
  const lastSaleRows = handleTableSaleRowsData(lastSale);
  const saleHistoryRows = handleTableSaleRowsData(saleHistory);

  return (
    <div className={` ${styles.mortgage}`}>
      <div className="wrapper">
        <div className={`${styles.table}`}>
          <h2>
            Current Mortgages <span>{currentMortgagesRows?.length}</span>
          </h2>

          <CustomTable
            rows={currentMortgagesRows?.length ? currentMortgagesRows : [{activityDrawerLeadId: false,}]}
            columns={mortgageCurrentColumns}
            tableStriped
          />
        </div>
        <div className={`${styles.table}`}>
          <h2>
            Mortgage History<span>{mortgageHistoryRows?.length}</span>
          </h2>

          <CustomTable
            rows={mortgageHistoryRows?.length ? mortgageHistoryRows : [{}]}
            columns={mortgageHistoryColumns}
            tableStriped
          />
        </div>
        <div className={`${styles.table}`}>
          <h2>Last Sale</h2>
          {lastSaleRows?.length === 0 ? (
            <EmptyPlaceholder
              icon={<SearchReflation />}
              title={"No results found"}
              description="We couldn’t find any results that match your search or filtering criteria."
            />
          ) : (
            <CustomTable
              rows={lastSaleRows}
              columns={saleColumns}
              tableStriped
            />
          )}
        </div>
        <div className={`${styles.table}`}>
          <h2>
            Sale History<span>{saleHistoryRows?.length}</span>
          </h2>
          <CustomTable
            rows={saleHistoryRows?.length ? saleHistoryRows : [{}]}
            columns={saleColumns}
            tableStriped
          />
        </div>
      </div>
    </div>
  );
};
export default MortgageTab;
