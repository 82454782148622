import { takeLatest, put } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* searchAddress({ payload }: any) {
  try {
    yield put(slice.setserachLoading(true))
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/address/search`,
      data: {
        searchTerm: payload?.search?.trim(),
        search_types: "A",
      },
      headers: {},
    });
    yield put(slice.setSearchList(response?.data));
    yield put(slice.setserachLoading(false))
  } catch (error: any) {
    yield put(slice.setserachLoading(false))
  }
}

function* createLeadSaga() {
  yield takeLatest(sagaActions.fetchAddressList, searchAddress);
}

export default createLeadSaga;
