import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  campaignList: [] as any,
  campaignListIsLoading: false,
  campaignStats: {} as any,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignList: (state, { payload: { data } }) => {
      const formattedCampaigns = data.result.map((campaign: any) => {
        return {
          ...campaign,
          status: `${
            campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)
          }`,
        };
      });
      return {
        ...state,
        campaignList: formattedCampaigns,
        total: data.total,
      };
    },
    setCampaignListIsLoading: (state, { payload }) => {
      return { ...state, campaignListIsLoading: payload };
    },
    appendCampaignList: (state, { payload: { data } }) => {
      const formattedCampaigns = data.result.map((campaign: any) => {
        return {
          ...campaign,
          status: `${
            campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)
          }`,
        };
      });
      return {
        ...state,
        campaignList: [...state.campaignList, ...formattedCampaigns],
        total: data.total,
      };
    },
    setCampaignStats: (state, { payload }) => {
      return {
        ...state,
        campaignStats: {
          dailsRemaining: +(payload?.dailsRemaining ?? 0),
          todayCallCount: +(payload?.todayCallCount ?? 0),
          todayCampaignCount: +(payload?.todayCampaignCount ?? 0),
          todayConversion: +(payload?.todayConversion ?? 0),
          totalCallCount: +(payload?.totalCallCount ?? 0),
          totalCampaignCount: +(payload?.totalCampaignCount ?? 0),
          totalConversion: +(payload?.totalConversion ?? 0),
          productTotalCredit: +(payload?.productTotalCredit ?? 0),
          activeCampaignCount: +(payload?.activeCampaignCount ?? 0),
          totalCredits:+(payload?.totalCredits ?? 0),
          expiresAt:(payload?.expiresAt ?? 0)
        },
      };
    },
    updateCampaignStats: (state, { payload }) => {
      return {
        ...state,
        campaignStats: {
          dailsRemaining:
            +(payload?.dailsRemaining ?? 0) +
            state.campaignStats.dailsRemaining,
          todayCallCount:
            +(payload?.todayCallCount ?? 0) +
            state.campaignStats.todayCallCount,
          todayCampaignCount:
            +(payload?.todayCampaignCount ?? 0) +
            state.campaignStats.todayCampaignCount,
          todayConversion:
            +(payload?.todayConversion ?? 0) +
            state.campaignStats.todayConversion,
          totalCallCount:
            +(payload?.totalCallCount ?? 0) +
            state.campaignStats.totalCallCount,
          totalCampaignCount:
            +(payload?.totalCampaignCount ?? 0) +
            state.campaignStats.totalCampaignCount,
          totalConversion:
            +(payload?.totalConversion ?? 0) +
            state.campaignStats.totalConversion,
          activeCampaignCount:
            +(payload?.activeCampaignCount ?? 0) +
            state.campaignStats.activeCampaignCount,
        },
      };
    },
    resetCampaignState:(state, payload ) => {
      return initialState;
    }
  },
});

export const {
  setCampaignList,
  setCampaignListIsLoading,
  appendCampaignList,
  setCampaignStats,
  updateCampaignStats,
  resetCampaignState,
} = campaignSlice.actions;

export default campaignSlice.reducer;
