import Check from "../../assets/icons/check";
import Close from "../../assets/icons/cross";
import Refresh from "../../assets/icons/refresh";
import TargetIcon from "../../assets/icons/targetIcon";
import Button from "../../atoms/Button";
import styles from "./ShadowBox.module.scss";
import { ShadowBoxProps } from "./interface";
const ShadowBox = ({
  title,
  subTitle,
  rightTitle,
  rightSubTitle,
  children,
  skipButton,
  loading,
  showstatus,
  failLoading,
  rightButton,
}: React.PropsWithChildren<ShadowBoxProps>) => {
  return (
    <div className={`${styles.shadowBox}`}>
      <div
        className={`dflex alignCenter justifySpaceBetween ${styles.shadowBox__header}`}
      >
        <div className={`${styles.leftInfo}`}>
          <h3>
            {title}{" "}
            {showstatus && failLoading ? (
              <span className={`${styles.failed}`}>
                <Close /> Failed to Save
              </span>
            ) : showstatus ? (
              loading ? (
                <span>
                  <span className={`${styles.spin}`}>
                    <Refresh />
                  </span>{" "}
                  Saving...
                </span>
              ) : (
                <span>
                  <Check />
                </span>
              )
            ) : ''}
          </h3>
          <p>{subTitle}</p>
        </div>
        <div className={`${styles.rightInfo}`}>
            {rightButton ? <>
            {rightButton}
            </>: <>
            <p>{rightTitle}</p>
          <h3>{rightSubTitle}</h3>
          {skipButton && (
            <Button
              label="Skip Trace"
              prefix={<TargetIcon />}
              className="outline blue"
            />
          )}
            </>}
        
        </div>
      </div>
      <div className={`${styles.shadowBox__info}`}>{children}</div>
    </div>
  );
};

export default ShadowBox;
