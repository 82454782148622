import { useFormContext } from "react-hook-form";
import DropDown from "../../../atoms/DropDown";
import Input from "../../../atoms/Input";
import {
  CLASSIFICATIONS_OPTIONS,
  HOME_FEATURES_OPTIONS,
  LOT_SIZE_OPTIONS,
  SQUARE_FEET_OPTIONS,
} from "../constants";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import SearchReflation from "../../../assets/icons/searchReflation";

const PropertyCharacteristics = () => {
  const {
    formState: { errors },
    control,
    watch,
    register,
  }: any = useFormContext();

  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Square Feet</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <DropDown
                options={[
                  // { value: null, label: "No Min" },
                  ...(watch("propertyCharacteristicsFilter.maxSquareFeet")
                    ? SQUARE_FEET_OPTIONS.filter(
                        (option) =>
                          option.value <
                          watch("propertyCharacteristicsFilter.maxSquareFeet")
                      )
                    : SQUARE_FEET_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.minSquareFeet"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minSquareFeet"]
                }
                placeholder="No Min"
                clearOnBlur={true}
              ></DropDown>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <DropDown
                options={[
                  // { value: null, label: "No Max" },
                  ...(watch("propertyCharacteristicsFilter.minSquareFeet")
                    ? SQUARE_FEET_OPTIONS.filter(
                        (option) =>
                          option.value >
                          watch("propertyCharacteristicsFilter.minSquareFeet")
                      )
                    : SQUARE_FEET_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.maxSquareFeet"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxSquareFeet"]
                }
                placeholder="No Max"
                clearOnBlur={true}
              ></DropDown>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Lot Size</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <DropDown
                options={[
                  // { value: null, label: "No Min" },
                  ...(watch("propertyCharacteristicsFilter.maxLotSize")
                    ? LOT_SIZE_OPTIONS.filter(
                        (option) =>
                          option.value <
                          watch("propertyCharacteristicsFilter.maxLotSize")
                      )
                    : LOT_SIZE_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.minLotSize"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minLotSize"]
                }
                placeholder="No Min"
                clearOnBlur={true}
              ></DropDown>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <DropDown
                clearOnBlur={true}
                options={[
                  // { value: null, label: "No Max" },
                  ...(watch("propertyCharacteristicsFilter.minLotSize")
                    ? LOT_SIZE_OPTIONS.filter(
                        (option) =>
                          option.value >
                          watch("propertyCharacteristicsFilter.minLotSize")
                      )
                    : LOT_SIZE_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.maxLotSize"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxLotSize"]
                }
                placeholder="No Max"
              ></DropDown>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Year Built</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.yearBuiltMin"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["yearBuiltMin"]
                }
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.yearBuiltMax"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["yearBuiltMax"]
                }
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Stories</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.storiesMin"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["storiesMin"]
                }
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.storiesMax"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["storiesMax"]
                }
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Classifications</p>
          <MultiSelectDropDown
            options={CLASSIFICATIONS_OPTIONS}
            label="Any"
            name={"propertyCharacteristicsFilter.classifications"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
        <div className={`colRow__col`}>
          <p>Home Features</p>
          <MultiSelectDropDown
            options={HOME_FEATURES_OPTIONS}
            label="Any"
            name={"propertyCharacteristicsFilter.homeFeatures"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
      </div>
    </>
  );
};
export default PropertyCharacteristics;
