import { getParseFloatValue } from "../../utils/utils";
import {
  ABSENTEE_OWNER_OPTIONS_MAP,
  ADVANCED_FILTER_KEYS,
  AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP,
  CLASSIFICATIONS_OPTIONS_MAP,
  HOME_FEATURES_OPTIONS_MAP,
  OCCUPANCY_OPTIONS_MAP,
  OWNER_TYPE_OPTIONS_MAP,
} from "./constants";

export const createAdvancedFilters = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filterArray = [];
  filterArray.push(
    getPropertyCharacteristicsFilter(
      data.propertyCharacteristicsFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getAuctionsPreforeclousreFilter(
      data.auctionsPreforeclousreFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getOwnershipFilter(data.ownershipFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getTaxLienFilter(data.taxLienFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getValuationEquityFilter(
      data.valuationEquityFilter,
      setAppliedAdvancedFilters
    )
  );
  filterArray.push(
    getMortgageInfoFilter(data.mortgageInfoFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getCashBuyersFilter(data.cashBuyersFilter, setAppliedAdvancedFilters)
  );
  filterArray.push(
    getDemographicsFilter(data.demographicsFilter, setAppliedAdvancedFilters)
  );
  return filterArray;
};

const getPropertyCharacteristicsFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  let classifications = createClassifications(data);
  let homeFeatures = createHomeFeatures(data);
  const filter = {
    building_size_min: data.minSquareFeet,
    building_size_max: data.maxSquareFeet,
    lot_size_min: data.minLotSize,
    lot_size_max: data.maxLotSize,
    year_built_min: data.yearBuiltMin,
    year_built_max: data.yearBuiltMax,
    stories_min: data.storiesMin,
    stories_max: data.storiesMax,
    ...classifications,
    ...homeFeatures,
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      propertyCharacteristicsFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getAuctionsPreforeclousreFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const filter = {
    ...createAuctionsPreforeclousreStatus(data),
    ...data.auctionsPreforeclousreSearchRange,
    // ...(data.auctionsPreforeclousreSearchRange?.startDate && data.auctionsPreforeclousreSearchRange?.endDate
    //   ? {
    //     foreclosure_date_min: new Date(
    //         new Date(data.auctionsPreforeclousreSearchRange?.startDate).getTime() - offset
    //       )
    //         .toISOString()
    //         .split("T")[0],
    //         foreclosure_date_max: new Date(
    //         new Date().getTime() - offset
    //       )
    //         .toISOString()
    //         .split("T")[0],
    //     }
    //   : {}),
    reo: data.reo,
    exclude_foreclosure: data.exclude_foreclosure,
    auction: data.auction,
    ...(data.auctionDate?.startDate && data.auctionDate?.endDate
      ? {
          auction_date_min: new Date(
            new Date(data.auctionDate?.startDate).getTime() - offset
          )
            .toISOString()
            .split("T")[0],
          auction_date_max: new Date(
            new Date(data.auctionDate?.endDate).getTime() - offset
          )
            .toISOString()
            .split("T")[0],
        }
      : {}),
  };

  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      auctionsPreforeclousreFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getOwnershipFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  let ownerType = createOwnerType(data);
  let absenteeOwner = createAbsenteOwner(data);
  let occupancy = createOccupancy(data);
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const filter = {
    ...ownerType,
    ...absenteeOwner,
    ...occupancy,
    ...(data.lastSaleDateRange?.startDate && data.lastSaleDateRange?.endDate
      ? {
          last_sale_date_min: new Date(
            new Date(data.lastSaleDateRange?.startDate).getTime() - offset
          )
            .toISOString()
            .split("T")[0],
          last_sale_date_max: new Date(
            new Date(data.lastSaleDateRange?.endDate).getTime() - offset
          )
            .toISOString()
            .split("T")[0],
        }
      : {}),

    years_owned_min: data.yearsOwnedMin,
    years_owned_max:data.yearsOwnedMax,
    last_sale_price_min:getParseFloatValue(data.lastSalePriceMin),
    last_sale_price_max: getParseFloatValue(data.lastSalePriceMax),
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      ownershipFilter: getFilterAppliedCount(data),
    }));

  return filter;
};
const getTaxLienFilter = (data: any, setAppliedAdvancedFilters?: Function) => {
  const filter: any = {
    ...(data.taxLien ? { tax_lien: data.taxLien } : {}),
    tax_delinquent_year_min: data.taxDelinquentYearMin,
    tax_delinquent_year_max: data.taxDelinquentYearMax,
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      taxLienFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getValuationEquityFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    equity_percent_min: data.equityPercentMin,
    equity_percent_max: data.equityPercentMax,
    estimated_equity_min: getParseFloatValue(data.estimatedEquityMin),
    estimated_equity_max: getParseFloatValue(data.estimatedEquityMax),
    value_min: getParseFloatValue(data.estimatedValueMin),
    value_max: getParseFloatValue(data.estimatedValueMax),
    assessed_value_min: getParseFloatValue(data.assessedValueMin),
    assessed_value_max:getParseFloatValue(data.assessedValueMax),
    assessed_land_value_min:getParseFloatValue(data.assessedLandValueMin),
    assessed_land_value_max: getParseFloatValue(data.assessedLandValueMax),
    assessed_improvement_value_min: getParseFloatValue(data.assessedImprovementValueMin),
    assessed_improvement_value_max: getParseFloatValue(data.assessedImprovementValueMax),
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      valuationEquityFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getMortgageInfoFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    free_clear: data.freeClear,
    assumable: data.assumableDebt,
    mortgage_min:getParseFloatValue(data.mortgageMin),
    mortgage_max: getParseFloatValue(data.mortgageMax),
    loan_type_code_first: data.loanType,
    adjustable_rate: data.adjustable_rate,
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  filter?.loan_type_code_first?.length === 0 &&
    delete filter["loan_type_code_first"];
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      mortgageInfoFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const getCashBuyersFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    cash_buyer: data.cashBuyer,
    properties_owned_min: data.propertiesOwnedMin,
    properties_owned_max: data.propertiesOwnedMax,
    portfolio_value_min: getParseFloatValue(data.portfolioValueMin),
    portfolio_value_max:getParseFloatValue(data.portfolioValueMax),
    portfolio_equity_min: getParseFloatValue(data.portfolioEquityMin),
    portfolio_equity_max: getParseFloatValue(data.portfolioEquityMax),
    portfolio_mortgage_balance_min: getParseFloatValue(data.portfolioMortgageBalanceMin),
    portfolio_mortgage_balance_max: getParseFloatValue(data.portfolioMortgageBalanceMax),
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      cashBuyersFilter: getFilterAppliedCount(data),
    }));
  return filter;
};
const getDemographicsFilter = (
  data: any,
  setAppliedAdvancedFilters?: Function
) => {
  const filter: any = {
    death: data.death,
    inherited: data.inherited,
    investor_buyer: data.investorBuyer,
    private_lender: data.privateLender,
    median_income_min: getParseFloatValue(data.medianIncomeMin),
    median_income_max: getParseFloatValue(data.medianIncomeMax),
  };
  Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
  setAppliedAdvancedFilters &&
    setAppliedAdvancedFilters((prev: any) => ({
      ...prev,
      demographicsFilter: getFilterAppliedCount(data),
    }));
  return filter;
};

const createClassifications = (data: any) => {
  let classifications: any = { property_use_code: [] };
  data?.classifications?.forEach((el: any) => {
    if (CLASSIFICATIONS_OPTIONS_MAP[el]) {
      classifications = {
        property_use_code: [
          ...classifications.property_use_code,
          ...CLASSIFICATIONS_OPTIONS_MAP[el].property_use_code,
        ],
      };
    }
  });
  if (classifications?.property_use_code?.length === 0) {
    delete classifications["property_use_code"];
  }
  return classifications;
};
const createHomeFeatures = (data: any) => {
  let homeFeatures: any = {};
  data?.homeFeatures?.forEach((el: any) => {
    if (HOME_FEATURES_OPTIONS_MAP[el]) {
      homeFeatures = {
        ...homeFeatures,
        ...HOME_FEATURES_OPTIONS_MAP[el],
      };
    }
  });
  return homeFeatures;
};
const createOwnerType = (data: any) => {
  let filter: any = {};
  // let individualSelected=false;
  // let corporateSelected=false;
  data?.ownerType?.forEach((el: any) => {
    // if (OWNER_TYPE_OPTIONS_MAP[el]) {
    filter = { ...filter, ...OWNER_TYPE_OPTIONS_MAP[el] };
    //   if (el === "INDIVIDUAL") {
    //     individualSelected = true;
    //   } else if (el === "CORPORATE") {
    //     corporateSelected = true;
    //   }
  });

  // if (individualSelected && corporateSelected) {
  //   delete filter.corporate_owned;
  // }
  return filter;
};

const createOccupancy = (data: any) => {
  let filter: any = {};
  // let inStateSelected=false;
  // let outStateSelected=false;
  // let ownerOccupiedSelected = false;
  // data?.absenteeOwner?.forEach((el: any) => {
  //   if (ABSENTEE_OWNER_OPTIONS_MAP[el]) {
  //     filter = { ...filter, ...ABSENTEE_OWNER_OPTIONS_MAP[el] };
  //     if (el === "INSTATE") {
  //       inStateSelected = true;
  //     } else if (el === "OUTOFSTATE") {
  //       outStateSelected = true;
  //     }
  //   }
  // });

  data?.occupancy?.forEach((el: any) => {
    // if(OCCUPANCY_OPTIONS_MAP[el]){
    filter = { ...filter, ...OCCUPANCY_OPTIONS_MAP[el] };
    //     if(el==="OWNEROCCUPIED"){
    //       ownerOccupiedSelected=true;
    //     }
    //   }
    //   if((inStateSelected || outStateSelected)  && ownerOccupiedSelected ){
    //     delete filter.absentee_owner;
    //     delete filter.in_state_owner;
    //   }
    // })
  });

  return filter;
};

const createAbsenteOwner = (data: any) => {
  let filter: any = {};
  // let inStateSelected=false;
  // let outStateSelected=false;
  data?.absenteeOwner?.forEach(
    (el: any) => {
      // if (ABSENTEE_OWNER_OPTIONS_MAP[el]) {
      filter = { ...filter, ...ABSENTEE_OWNER_OPTIONS_MAP[el] };
      // if (el === "INSTATE") {
      //   inStateSelected = true;
      // } else if (el === "OUTOFSTATE") {
      //   outStateSelected = true;
      // }
    }
    // if (inStateSelected && outStateSelected) {
    //   delete filter.absentee_owner;
    //   delete filter.in_state_owner;
    // }
  );
  return filter;
};
const createAuctionsPreforeclousreStatus = (data: any) => {
  let filter: any = { notice_type: [] };
  data?.auctionsPreforeclousreStatus?.forEach((el: any) => {
    if (AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el]) {
      if (AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el].notice_type) {
        filter.notice_type = [
          ...filter.notice_type,
          ...AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el].notice_type,
        ];
      } else {
        filter = {
          ...filter,
          ...AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS_MAP[el],
        };
      }
    }
  });
  if (filter?.notice_type?.length === 0) {
    delete filter["notice_type"];
  }
  return filter;
};

const getFilterAppliedCount = (filter: any): number => {
  return Object.keys(filter).filter((key) => {
    if (Array.isArray(filter?.[key])) {
      return filter?.[key].length > 0;
    } else {
      return filter?.[key];
    }
  }).length;
};

export const updateFilterFlags = (
  data: any,
  setAppliedAdvancedFiltersFlags: Function
) => {
  let filterCounts: any = {};
  ADVANCED_FILTER_KEYS.forEach((key) => {
    const count = Object.keys(data[key]).filter((filter) => {
      if (Array.isArray(data[key][filter])) {
        return data[key][filter].length > 0;
      } else {
        if (filter === "lastSaleDateRange") {
          return data[key]?.[filter]?.endDate || data[key]?.[filter]?.startDate
            ? true
            : false;
        }
        return data[key][filter];
      }
    }).length;
    filterCounts[key] = count;
  });
  setAppliedAdvancedFiltersFlags(filterCounts);
};
export const removeZeroValues = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === 0) {
      delete data[key];
    }
  });
  return data;
};
