export const LeadStatus = {
  NEW_LEAD: "New Lead",
  FOLLOW_UP: "Follow Up",
  CONTRACT_SENT: "Contract Sent",
  MAKE_OFFER: "Make Offer",
  IN_CONTRACT: "In Contract",
  CLOSED_DEAL: "Closed Deal",
  DEAD_DEAL: "Dead Deal",
} as const;

type LeadStatusKeys = keyof typeof LeadStatus;

export const LeadStatusMap = {
  "New Lead": "NEW_LEAD",
  "Follow Up": "FOLLOW_UP",
  "Contract Sent": "CONTRACT_SENT",
  "Make Offer": "MAKE_OFFER",
  "In Contract": "IN_CONTRACT",
  "Closed Deal": "CLOSED_DEAL",
  "Dead Deal": "DEAD_DEAL",
} as const;

export type { LeadStatusKeys };
