import { Column } from "../../models/table.interface";
import { SortLeadsKey } from "../../molecules/table/enum";

export const columns: readonly Column[] = [
    {
      id: "lead",
      label: "Lead",
      minWidth: 260,
      type: "titleDescriptionText",
      sortKey: SortLeadsKey.NAME,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 170,
      type: "phone",
      sortKey: SortLeadsKey.PHONE,
    },
    {
      id: "phone_attempt_summary",
      label: "Summary",
      minWidth: 450,
      type: "clampedText",
      sortKey: SortLeadsKey.SUMMARY,
    },
    // { id: "bed", label: "Bed", minWidth: 70, type: "" },
    // { id: "bath", label: "Bath", minWidth: 70, type: "" },
    // { id: "sqft", label: "SqFt", minWidth: 70, type: "" },
    {
      id: "commentButton",
      label: "",
      minWidth: 10,
      type: "icon",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
      type: "statusPill",
      sortKey: SortLeadsKey.STATUS,
    },
    {
      id: "followUpDate",
      label: "Follow Up Date",
      minWidth: 170,
      type: "dateCalenderCell",
      sortKey: SortLeadsKey.FOLLOWUP,
    },
    {
      id: "updatedAtDate",
      label: "Last Update",
      minWidth: 170,
      type: "",
      sortKey: SortLeadsKey.UPDATEAT,
    },
  ];