const ClockIcon = ({color='#98A2B3'}:any) => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
  <circle cx="40.5" cy="40" r="40" fill="black" fill-opacity="0.06"/>
  <circle cx="40.5" cy="40" r="39.75" stroke="url(#paint0_linear_17191_39771)" stroke-opacity="0.2" strokeWidth="0.5"/>
  <path d="M21.6426 43.1475C21.6426 45.6233 22.1303 48.0748 23.078 50.3621C24.0256 52.6494 25.4146 54.7277 27.1656 56.4783C28.9167 58.2289 30.9954 59.6176 33.2833 60.565C35.5711 61.5125 38.0232 62.0001 40.4995 62.0001C42.9758 62.0001 45.4279 61.5125 47.7158 60.565C50.0036 59.6176 52.0824 58.2289 53.8334 56.4783C55.5844 54.7277 56.9734 52.6494 57.9211 50.3621C58.8687 48.0748 59.3565 45.6233 59.3565 43.1475C59.3565 40.6718 58.8687 38.2202 57.9211 35.9329C56.9734 33.6456 55.5844 31.5673 53.8334 29.8167C52.0824 28.0661 50.0036 26.6774 47.7158 25.73C45.4279 24.7826 42.9758 24.2949 40.4995 24.2949C38.0232 24.2949 35.5711 24.7826 33.2833 25.73C30.9954 26.6774 28.9167 28.0661 27.1656 29.8167C25.4146 31.5673 24.0256 33.6456 23.078 35.9329C22.1303 38.2202 21.6426 40.6718 21.6426 43.1475Z" stroke="black" stroke-width="2"/>
  <path d="M53.0723 20.0947L62.4996 29.5199" stroke="black" strokeWidth="2"/>
  <path d="M18.5 29.5199L27.9274 20.0947" stroke="black" strokeWidth="2"/>
  <path d="M40.5 32.6631V43.1355H47.8324" stroke="black" strokeWidth="2"/>
  <path d="M40.5 24.2835V18" stroke="black" strokeWidth="2"/>
  <defs>
    <linearGradient id="paint0_linear_17191_39771" x1="-61.569" y1="179.31" x2="94.3883" y2="175.842" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.2"/>
    </linearGradient>
  </defs>
</svg>
        </>
    );
};

export default ClockIcon;

