const PlayIcon = () => {
    return (
        <>
         <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
        <path d="M6.18945 5.82142C6.18945 4.6884 6.18945 4.12189 6.42569 3.8096C6.6315 3.53755 6.94606 3.3692 7.28658 3.34887C7.67746 3.32553 8.14883 3.63977 9.09156 4.26826L21.3597 12.447C22.1387 12.9663 22.5282 13.226 22.6639 13.5533C22.7825 13.8394 22.7825 14.161 22.6639 14.4471C22.5282 14.7744 22.1387 15.034 21.3597 15.5534L9.09156 23.7321C8.14883 24.3606 7.67746 24.6749 7.28658 24.6515C6.94606 24.6312 6.6315 24.4628 6.42569 24.1908C6.18945 23.8785 6.18945 23.312 6.18945 22.179V5.82142Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </>
    );
};

export default PlayIcon;




