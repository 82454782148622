import PriceCard from "../../components/priceCard/PriceCard";
import PriceCardInfo from "../../components/priceCard/PriceCardInfo";
import Input from "../../atoms/Input";
import styles from "./Billings.module.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
  AddressElement,
} from "@stripe/react-stripe-js";
import PriceCardFooter from "../../components/priceCard/PriceCardFooter";
import Button from "../../atoms/Button";
import { useFormContext } from "react-hook-form";
import { CardElementType } from "./enum";
import { useEffect, useState } from "react";
import { fetchSetupIntent, updatePaymentMethod } from "./billingService";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailAction } from "../propertySearchFilter/sagaActions";
import { MailTypeEnum } from "../../pages/propertySearch/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import InfoCircle from "../../assets/icons/infoCircle";
import { AddCardProps } from "./interface";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { fetchSubscriptions } from "./sagaActions";
import { fbc, fbp, fetchIp, hashString, hashNumber, hashPhoneNumber, gtagEvent } from "../../utils/utils";
import { FbConversionApi } from "../auth/authServices";
import ReactGA from "react-ga4";
import { GtagEventParams } from "../../models/gtag.interface";

const AddCard = ({
  selectedPricing,
  displayErrorMessage,
  confirmCardLoading,
  setConfirmCardLoading,
  onSubmit,
  buttonConfig,
  replacePaymentMethod
}: AddCardProps) => {
  const {
    formState: { errors, isValid },
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
  } = useFormContext();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [cardErrors, setCardError] = useState<any>({
    [CardElementType.CardNumber]: undefined,
    [CardElementType.CardExpiry]: undefined,
    [CardElementType.CardCVV]: undefined,
  });
  const [cardElementsTouched, setCardElementsTouched] = useState<any>({
    [CardElementType.CardNumber]: false,
    [CardElementType.CardExpiry]: false,
    [CardElementType.CardCVV]: false,
  });
  const [addressElementsTouched, setAddressElementsTouched] = useState(false);
  const [startTrialClicked, setStartTrialClicked] = useState(false);
  const [cardConfirmationFailed, setCardConfirmationFailed] = useState(false);
  const [cardConfirmationError, setCardConfirmationError] = useState("");
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const onCardValueChanges = (e: any, type: CardElementType | string) => {
    if (cardConfirmationFailed) {
      setCardConfirmationFailed(false);
      setCardConfirmationError("");
    }
    let cardErrors: any = {
      [e.elementType]: e?.error?.message,
    };
    if (!cardElementsTouched[type] && !e?.empty) {
      setCardElementsTouched((prev: any) => ({ ...prev, [type]: true }));
    } else if (e?.empty) {
      setCardElementsTouched((prev: any) => ({ ...prev, [type]: false }));
    }
    setCardError({ ...errors, ...cardErrors });
  };

  const onStartFreeTrialClick = async (data: any) => { 
    if (cardErrors["zipCode"]) {
      delete cardErrors["zipCode"];
    }
    const cardErr = Object.keys(cardErrors).filter(
      (key: string) => cardErrors[key]
    );
    const touched = Object.keys(cardElementsTouched).filter(
      (key: string) => cardElementsTouched[key]
    );
    if (isValid && cardErr.length === 0 && touched.length === 3) {
      setStartTrialClicked(true);
      setConfirmCardLoading(true);
      try {
        const setupIntent = await fetchSetupIntent();
       await confirmCardSetup(setupIntent?.data); 
      } catch (error: any) {
        displayErrorMessage(error);
        setConfirmCardLoading(false);
      }
    }
  };

  const confirmCardSetup = async (setupIntent: any) => {
    let result: any;
    const addressElement = elements?.getElement(AddressElement);
    if (addressElement) {
      const { complete, value } = await addressElement.getValue();
    try {
    if(complete){
        result = await stripe?.confirmCardSetup(setupIntent.clientSecret, {
          payment_method: {
            card: elements?.getElement(CardNumberElement) as any,
            billing_details: {
              name: value.name,
              address: {
                line1: value.address.line1,
                line2: value?.address?.line2 || "",
                city: value.address.city,
                state: value.address.state,
                postal_code: value.address.postal_code,
                country: value.address.country,
              },
            },
            // address: { postal_code: getValues().zipCode },
            // name: getValues().nameOnCard,
          },
        });
       const payloadData = {
          data: [
            {
              event_name: "Add_payment_info",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              event_id: hashNumber(result?.setupIntent?.created),
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                em: hashString(profileInfo?.email),
                ph: hashPhoneNumber(profileInfo?.phone),
                external_id: hashNumber(profileInfo?.organisationId),
              },
            },
          ],
        };
      
        if(replacePaymentMethod){
          await updatePaymentMethod({ paymentMethod: result?.setupIntent?.payment_method });
         dispatch(
          addToast({
            id: new Date().getTime(),
            message: "Default payment method updated successfully!",
            type: ToastType.success,
          })
        );
        dispatch(fetchSubscriptions({}));
        }
        if (result["error"] && result["error"]?.code) {
          setCardConfirmationFailed(true);
          setConfirmCardLoading(false);
          setCardConfirmationError(
            result["error"]?.message ?? "Your bank declined our card check."
          );
          dispatch(
            sendEmailAction({
              type: MailTypeEnum.CARD_DECLINES,
              data: {
                name: `${profileInfo?.nameFirst ?? ""} ${
                  profileInfo?.nameLast ?? ""
                }`,
                message: result["error"]?.message,
              },
            })
          );
        }
        await FbConversionApi(payloadData);
        const params: GtagEventParams = {
          event_category: 'User',
          event_label: 'add_payment_info',
          user_email: profileInfo?.email, 
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: window.location.href,
           Id:profileInfo?.organisationId
        };
    
        gtagEvent('event', 'add_payment_info', params);
        // ReactGA.event({
        //   category: 'User',
        //   action: 'add_payment_info',
        // });
    }
    else{
      setConfirmCardLoading(false);
    }
    } catch (error) {
      setCardConfirmationFailed(true);
      setConfirmCardLoading(false);
    }
    onSubmit(result);
  }
  };

  useEffect(() => {
    if (
      watch("nameOnCard")?.length > 0 &&
      watch("nameOnCard")?.trim()?.length === 0
    ) {
      setValue("nameOnCard", "");
    }
  }, [watch("nameOnCard")]);


  useEffect(() => {
    return () => {
      if (!addressElementsTouched && elements) {
        const addressElement = elements.getElement(AddressElement);
        if (addressElement) {
          addressElement.clear(); 
        }
      }
    };
  }, [addressElementsTouched, elements]);


  return (
    <>
      <PriceCard>
        <PriceCardInfo>
          {cardConfirmationFailed && (
            <div className={`dflex alignCenter ${styles.registerInfo}`}>
              <span className={`animated-svg color-red`}>
                <InfoCircle />
              </span>
              <aside>
                <strong>{cardConfirmationError}</strong>
                <p>Please try a different card or contact support.</p>
              </aside>
            </div>
          )}

          <div className={` ${styles.cardRow}`}>
            <div className={` ${styles.cardRow__col}`}>
              <Input
                type="text"
                label="Name on card*"
                register={register}
                name="nameOnCard"
                placeholder="Olivia Rhye"
                errors={errors}
              ></Input>
            </div>
            <div
              className={` ${styles.cardRow__col3}`}
              data-testid="cardExpiry"
            >
              <div
                className={` ${styles.cardInput} ${
                  !cardElementsTouched.cardExpiry && startTrialClicked
                    ? styles.isError
                    : ""
                }`}
              >
                <label>Exp*</label>
                <CardExpiryElement
                  onChange={(e) => {
                    onCardValueChanges(e, CardElementType.CardExpiry);
                  }}
                ></CardExpiryElement>
                {!cardElementsTouched.cardExpiry && startTrialClicked && (
                  <span className="error">Enter expiry date</span>
                )}
                {cardErrors && (
                  <span className="error">
                    {cardErrors[CardElementType.CardExpiry]}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.cardRow}`}>
            <div className={` ${styles.cardRow__col}`}>
              <div
                className={` ${styles.cardInput} ${
                  !cardElementsTouched.cardNumber && startTrialClicked
                    ? styles.isError
                    : ""
                }`}
                data-testid="cardNumber"
              >
                <label>Card number*</label>
                <CardNumberElement
                  options={{ showIcon: true }}
                  onChange={(e) => {
                    onCardValueChanges(e, CardElementType.CardNumber);
                  }}
                ></CardNumberElement>
                {!cardElementsTouched.cardNumber && startTrialClicked && (
                  <span className="error">Enter card number</span>
                )}
                {cardErrors && (
                  <span className="error">
                    {cardErrors[CardElementType.CardNumber]}
                  </span>
                )}
              </div>
            </div>
            <div className={` ${styles.cardRow__col3}`}>
              <div
                className={` ${styles.cardInput} ${
                  !cardElementsTouched.cardCvc && startTrialClicked
                    ? styles.isError
                    : ""
                }`}
              >
                <label>CVV*</label>
                <CardCvcElement
                  onChange={(e) => {
                    onCardValueChanges(e, CardElementType.CardCVV);
                  }}
                  data-testid="cardCvv"
                ></CardCvcElement>
                {!cardElementsTouched.cardCvc && startTrialClicked && (
                  <span className="error">Enter CVV</span>
                )}
                {cardErrors && (
                  <span className="error">
                    {cardErrors[CardElementType.CardCVV]}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.cardRow}`}>
            <div 
             className={`${styles.cardRow__full}`}>
              <AddressElement
                options={{ mode: "billing" }}
                onChange={(e:any) => {
                 
                  setAddressElementsTouched(true);
                }}
              />

              {/* { startTrialClicked && (
                <span className="error">Address is required</span>
              )} */}

              {/* <Input
                type="alphaNumeric"
                label="Zip Code"
                control={control}
                register={register}
                name="zipCode"
                placeholder="Enter Zip Code"
                errors={errors}
              /> */}
            </div>
          </div>

          {selectedPricing && (
            <>
              <div className={`dflex justifySpaceBetween ${styles.trial}`}>
                <h5> Free Trial</h5>
                <div className={` ${styles.right}`}>
                  <h5> 3 days free</h5>
                  <p>
                    {" "}
                    ${selectedPricing?.unitAmount} / {selectedPricing?.interval}{" "}
                    after trial ends
                  </p>
                </div>
              </div>


              <div className={`dflex justifySpaceBetween ${styles.total}`}>
                <p>Total due today:</p>
                <div className={` ${styles.right}`}>
                  <p>
                    {" "}
                    <strong>$0.00</strong>{" "}
                  </p>
                </div>
              </div>
            </>
          )}
        </PriceCardInfo>

        <PriceCardFooter>
          <Button
            action={() => {
              handleSubmit(onStartFreeTrialClick)();
              setStartTrialClicked(true);
            }}
            label={buttonConfig?.label ?? "Add Card"}
            postfix={buttonConfig?.postfix ?? undefined}
            clicked={confirmCardLoading}
            disabled={confirmCardLoading}
            className="primary animated full xl"
          ></Button>
        </PriceCardFooter>
      </PriceCard>
    </>
  );
};
export default AddCard;
