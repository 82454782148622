
const Reset = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M1.91797 8.33333C1.91797 8.33333 3.58879 6.05685 4.94616 4.69854C6.30354 3.34022 8.1793 2.5 10.2513 2.5C14.3934 2.5 17.7513 5.85786 17.7513 10C17.7513 14.1421 14.3934 17.5 10.2513 17.5C6.83206 17.5 3.94723 15.2119 3.04444 12.0833M1.91797 8.33333V3.33333M1.91797 8.33333H6.91797"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default Reset;
