import { initialState } from "./reducer";

const getStateData = (state: any) => state["propertySearch"] || initialState;

export const getAddressPredictions = (state: any) => {
  const reducer = getStateData(state);
  return reducer.addressPredictions;
};
export const getPropertiesListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.propertiesListLoading;
};

export const getPropertiesList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.propertiesList;
};

export const getSearchPropertiesResultCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.resultsCount;
};
export const getSearchPropertiesPotentialCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.potentialCount;
};

export const getClearPropertySearch = (state: any) => {
  const reducer = getStateData(state);
  return reducer.clearPropertySearch;
};

export const getAddressPredictionLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.addressPredictionLoading;
};

export const getAddressSearchProperty = (state: any) => {
  const reducer = getStateData(state);
  return reducer.searchProperty;
};