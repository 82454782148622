import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./assets/global.scss";
import Notification from "./organisms/notifications/Notification";
import Routes from "./Routes";
import Providers from "./Providers";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { HelmetProvider } from "react-helmet-async";
import { getCookie, isValidTextApp } from "./utils/utils";
import ReactGA from "react-ga4";

const measurementId = process.env.REACT_APP_TRACKING_ID;
if (measurementId) {
  ReactGA.initialize(measurementId);
} 

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  function authenticateUser() {
    let authenticated = false;

    while (!authenticated) {
      let username = prompt("UserName:");
      while (!username || username?.trim() === "" || !isValidTextApp(username)) {
        username = prompt("UserName:");
      }
      let password = prompt("Password:");
      while (!password || password?.trim() === "" || !isValidTextApp(password)) {
        password = prompt("Password:");
      }

      if (username === process.env.REACT_APP_VALID_USERNAME && password === process.env.REACT_APP_VALID_PASSWORD) {
        authenticated = true;
        document.cookie = "authenticatedUser=true";
      } else {
        alert("Invalid! Please try again.");
      }
    }
    return true;
  }

  useEffect(() => {
    if(process.env.REACT_APP_NODE_ENV === "PROD"){
      setAuthenticated(true);
    }
    else{
      if (getCookie("authenticatedUser") === "true") {
        setAuthenticated(true);
      } else {
        setAuthenticated(authenticateUser());
      }
    }
    
  }, [getCookie("authenticatedUser")]);

  useEffect(() => {
    AOS.init({
      disable: "mobile",
    });
  }, []);

  return (
    <>
      {authenticated && (
        <div className="App">
          <HelmetProvider>
            <Providers>
              <Notification />
              <Routes />
            </Providers>
          </HelmetProvider>
        </div>
      )} 
    </>
  );
}

export default App;
