import { Column } from "../../../../models/table.interface";

export const currentLinkedPropertiesColumns: readonly Column[] = [
    { id: "address", label: "Address", minWidth: 70, type: "string" },
    { id: "beds", label: "Beds", minWidth: 70, type: "number" },
    { id: "bath", label: "Baths", minWidth: 70, type: "number" },
    { id: "sqFt", label: "SqFt", minWidth: 70, type: "number" },
    { id: "ownerNames", label: "Owner Name(s)", minWidth: 70, type: "string" },
    { id: "yearBuilt", label: "Year Built", minWidth: 70, type: "number" },
    {
      id: "ownershipLength",
      label: "Ownership Length",
      minWidth: 70,
      type: "number",
    },
    { id: "lastSaleDate", label: "Last Sale Date", minWidth: 70, type: "string" },
    {
      id: "lastSalePrice",
      label: "Last Sale Price",
      minWidth: 70,
      type: "string",
    },
    {
      id: "estimatedMortgageBalance",
      label: "Est. Mortgage Bal.",
      minWidth: 70,
      type: "string",
    },
    { id: "estValue", label: "Est. Value", minWidth: 70, type: "string" },
  ];