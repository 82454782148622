import { Controller, useFormContext } from "react-hook-form";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import {
  AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS,
  AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS,
} from "../constants";
import DropDown from "../../../atoms/DropDown";
import SearchReflation from "../../../assets/icons/searchReflation";
import CustomDateRangePicker from "../../../atoms/CustomDateRangePicker";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import DateRangePickerCalendar from "../../../atoms/DateRangePickerCalendar";

const AuctionsPreForeclosure = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  }: any = useFormContext();
  const [foreclosureValue, setForeclosureValue] = useState( watch('auctionsPreforeclousreFilter.exclude_foreclosure') || false)

  const auctionValue = watch('auctionsPreforeclousreFilter.auction');
  const recordingDate= watch('auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange')
  
  useEffect(() => {
    if (auctionValue) return;
    setValue('auctionsPreforeclousreFilter.auctionDate', undefined);
  }, [auctionValue, setValue])

  useEffect(()=>{
    if (recordingDate) {
      setValue('auctionsPreforeclousreFilter.exclude_foreclosure',false);
      setForeclosureValue(false)
      } 
  },[recordingDate,setValue])

  useEffect(()=>{
    setForeclosureValue(watch('auctionsPreforeclousreFilter.exclude_foreclosure') || false)
  }, [ watch('auctionsPreforeclousreFilter.exclude_foreclosure')])

  useEffect(()=>{
    if (foreclosureValue) {
    setValue('auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange', null);
    }
  },[foreclosureValue,setValue])

  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Foreclosure Status</p>
          <MultiSelectDropDown
            options={AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS}
            label="Any"
            name={"auctionsPreforeclousreFilter.auctionsPreforeclousreStatus"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
        <div className={`colRow__col select-bold`}>
          <p>Recording Date</p>

          <DropDown
            options={AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS}
            label=""
            control={control}
            name="auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
            errors={
              errors?.["auctionsPreforeclousreFilter"]?.[
                "auctionsPreforeclousreSearchRange"
              ]
            }
            placeholder="Any"
            clearOnBlur={true}
          ></DropDown>

          {/* <DateRangePickerCalendar
            placeHolder="Select dates"
            control={control}
            name="auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
            errors={
              errors?.["auctionsPreforeclousreFilter"]?.[
                "auctionsPreforeclousreSearchRange"
              ]
            }
          /> */}
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="auctionsPreforeclousreFilter.auction"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Active Auction</h5>
                  <p>
                    Used to include properties which are scheduled to go to
                    auction.
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className={`colRow__col`}>
          <Tooltip
            title={!auctionValue ? "Please check auction checkbox first" : ""}
          >
            <div>
              <p>Auction Date</p>
              {/* <label> */}
                <DateRangePickerCalendar
               placeHolder="Select dates"
               control={control}
               disabled={!auctionValue}
               name="auctionsPreforeclousreFilter.auctionDate"
               errors={errors?.["auctionsPreforeclousreFilter"]?.["auctionDate"]}
            />
                {/* <CustomDateRangePicker
                  placeHolder="Select dates"
                  control={control}
                  disabled={!auctionValue}
                  name="auctionsPreforeclousreFilter.auctionDate"
                  errors={
                    errors?.["auctionsPreforeclousreFilter"]?.["auctionDate"]
                  }
                ></CustomDateRangePicker> */}
              {/* </label> */}
            </div>
          </Tooltip>
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
          <Tooltip
            title={recordingDate ? "Recording date will get reset" : ""}
          >
            <FormControlLabel
              control={
                <Controller
                  name="auctionsPreforeclousreFilter.exclude_foreclosure"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={foreclosureValue}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Exclude Foreclosures</h5>
                  <p>
                    Don’t include properties with the foreclosure attribute.
                  </p>
                </div>
              }
            />
            </Tooltip>
          </div>
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="auctionsPreforeclousreFilter.reo"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Bank Owned (REO)</h5>
                  <p>Used to find properties which are owned by the bank.</p>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AuctionsPreForeclosure;
