import { useSelector } from "react-redux";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formartNumberAsLocalString, formatCurrency, formatMonthYear, formatPhoneNumber } from "../../../../utils/utils";
import { getPropertyDetails } from "../../property.selector";
import styles from "./ForeclosuresAndLines.module.scss";

const SectionItem = ({ title, value }: { title: string; value: string }) => {
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }

  if (typeof value === "number") {
    value = formartNumberAsLocalString(value);
  }

  value = Boolean(value) ? value : "-";
  return (
    <div className={`${styles.row__col}`}>
      <InfoCard title={title} description={value} />
    </div>
  );
};

const ForeclosuresAndLines = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { taxLien, foreclosureInfo = [] } =
    propertyDetails?.jsonData  || {};

  const sections = {
    Foreclosures: {
      subtitle: "Foreclosures recorded against the property.",

      data: [
        {
          title: "Active",
          value: foreclosureInfo[0]?.active ? foreclosureInfo[0]?.active : "",
        },
        {
          title: "Document Type",
          value: (foreclosureInfo[0]?.documentType === "LisPendens" ? "Lis Pendens" : foreclosureInfo[0]?.documentType) || "",
        },
        ...(foreclosureInfo[0]?.defaultAmount ? [{
          title: "Default Amount",
          value: formatCurrency(foreclosureInfo[0]?.defaultAmount)
        }] : []),
        ...(foreclosureInfo[0]?.openingBid ? [{
          title: "Opening Bid",
          value: formatCurrency(foreclosureInfo[0]?.openingBid)
        }] : []),
        {
          title: "Recording Date",
          value: formatMonthYear(foreclosureInfo[0]?.recordingDate, false)
        },
        {
          title: "Original Loan Amount",
          value: formatCurrency(foreclosureInfo[0]?.originalLoanAmount)
        },
        {
          title: "Est. Bank Value",
          value: formatCurrency(foreclosureInfo[0]?.estimatedBankValue)
        },
        ...(foreclosureInfo[0]?.auctionDate ? [{
          title: "Auction Date",
          value: formatMonthYear(foreclosureInfo[0]?.auctionDate, false)
        }] : []),
        ...(foreclosureInfo[0]?.auctionTime ? [{
          title: "Auction Time",
          value: foreclosureInfo[0]?.auctionTime ? foreclosureInfo[0]?.auctionTime : ""
        }] : []),
        ...(foreclosureInfo[0]?.auctionDate ? [{
          title: "Auction Street Address",
          value: foreclosureInfo[0]?.auctionStreetAddress ? foreclosureInfo[0]?.auctionStreetAddress :""
        }] : []),
        ...(foreclosureInfo[0]?.judgmentAmount && foreclosureInfo[0]?.judgmentAmount !== "0" ? [{
          title: "Judgment Amount",
          value: formatCurrency(foreclosureInfo[0]?.judgmentAmount)
        }] : []),
        ...(foreclosureInfo[0]?.judgmentDate ? [{
          title: "Judgment Date",
          value: formatMonthYear(foreclosureInfo[0]?.judgmentDate, false)
        }] : []),
        ...(foreclosureInfo[0]?.lenderPhone ? [{
          title: "Lender Phone",
          value: formatPhoneNumber(foreclosureInfo[0]?.lenderPhone)
        }] : []),
        ...(foreclosureInfo[0]?.trusteeAddress ? [{
          title: "Trustee Address",
          value: foreclosureInfo[0]?.trusteeAddress
        }] : []),
        ...(foreclosureInfo[0]?.trusteeFullName ? [{
          title: "Trustee Full Name",
          value: foreclosureInfo[0]?.trusteeFullName
        }] : []),
        ...(foreclosureInfo[0]?.trusteePhone ? [{
          title: "Trustee Phone",
          value: formatPhoneNumber(foreclosureInfo[0]?.trusteePhone)
        }] : []),
        ...(foreclosureInfo[0]?.trusteeSaleNumber ? [{
          title: "Trustee Sale Number",
          value: foreclosureInfo[0]?.trusteeSaleNumber
        }] : []),
      ],
    },
    Liens: {
      subtitle: "Liens recorded against the property.",
      data: [
        {
          title: "Tax Lien(s)",
          value: taxLien,
        },
      ],
    },
  };

  return (
    <div className={` ${styles.foreclosures}`}>
      <div className="wrapper">
        {Object.entries(sections).map(([key, { subtitle, data }]) => (
          <ShadowBox title={key} key={key} subTitle={subtitle}>
            <div className={`dflex ${styles.row}`}>
              {data.map(({ title, value }) => (               
                <SectionItem title={title} value={value} key={title} />
              ))}
            </div>
          </ShadowBox>
        ))}
      </div>
    </div>
  );
};
export default ForeclosuresAndLines;
