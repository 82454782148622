import { useState } from "react";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Eye from "../assets/icons/eye";
import EyeOff from "../assets/icons/eyeOff";
import { InputProps } from "../models/input.interface";
import { Tooltip } from "@mui/material";
import AlertCircle from "../assets/icons/alertCircle";

const Input = ({
  type,
  label,
  register,
  name,
  placeholder,
  errors,
  hint,
  prefix,
  control,
  min,
  errorToolTip,
  invalidAddressError,
  onKeyDown,
  postfix,
  postfixValue,
  invalidResultCount,
  readOnly,
  defaultValue,
  dataTestId
}: InputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => {
    const prev = showPassword;
    setShowPassword(!prev);
  };

  const inputType = (type: string) => {
    if (type === "password") {
      return showPassword ? "text" : type;
    } else {
      return type;
    }
  };
  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 9, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const formatValue = (value:any) => {
    return value.replace(/,/g, ''); 
  };

  const getInputByType = () => {
    switch (type) {
      case "currency": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <MaskedInput
                  mask={currencyMask}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  type="text"
                  placeholder={placeholder}
                  {...field}
                  onChange={(e) => field.onChange(formatValue(e.target.value))}
                />
              )}
              name={name}
              control={control}
            />
          )
        );
      }
      case "numeric": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <input
                  type="text"
                  placeholder={placeholder}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  onChange={(e) => {
                    const re = /^[-][0-9]*$/;
                    const re2 = /^[0-9]+$/;
                    if (
                      e.target.value === "" ||
                      re2.test(e.target.value) ||
                      (re.test(e.target.value) &&
                        e.target.value.lastIndexOf("-") <= 0)
                    ) {
                      field.onChange(e.target.value);
                    } else if (e.target.value.length === 1) {
                      field.onChange("");
                    }
                  }}
                  value={field.value}
                />
              )}
              name={name}
              control={control}
            />
          )
        );
      }
      case "alphaNumeric": {
        return (
          control && (
            <Controller
              render={({ field }) => (
                <input
                data-testid="zipCode"
                  type="text"
                  placeholder={placeholder}
                  className={`${
                    errors && (errors?.[name] || errors?.message)
                      ? "inputError"
                      : ``
                  }`}
                  onChange={(e) => {
                    const re = /^[A-Z0-9a-z]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e.target.value);
                    } else if (e.target.value.length === 1) {
                      field.onChange("");
                    }
                  }}
                  value={field.value}
                />
              )}
              name={name}
              control={control}
            />
          )
        );
      }
      default: {
        return (
          <input
            className={`${
              errors && (errors?.[name] || errors?.message) ? "inputError" : ``
            }`}
            type={inputType(type)}
            {...register(name)}
            placeholder={placeholder}
            min={min}
            onKeyDown={(e)=>onKeyDown&&onKeyDown(e)}
            readOnly={readOnly}
            defaultValue={defaultValue}
            data-testid={dataTestId}
          />
        );
      }
    }
  };
  return (
    <div className={`inputGroup`}>
      {label && <label className="label">{label}</label>}
      <span
        className={`inputGroup__input ${
          type === "password" ? `inputPassword` : ``
        } ${prefix ? `prefixMain` : ``} ${postfix ? `postfixMain` : ``}`}
      >
        {getInputByType()}

        {type === "password" && (
          <span role="link" className={`eyeIcon`} onClick={togglePassword}>
            {showPassword ? <EyeOff /> : <Eye />}
          </span>
        )}
        {prefix && (
          <span role="link" className={`prefix`}>
            {prefix}
          </span>
        )}
         {postfix && (
          <span  className={`postfix`}>
            / {postfixValue}
          </span>
        )}
        {errorToolTip &&
          errors &&
          (errors?.[name] || (errors && errors?.message)) && (
            <>
              <span className={`main-tooltip`}>
                <Tooltip
                  title={
                    errors && (errors?.[name] || (errors && errors?.message))
                  }
                  arrow
                  placement="top"
                  classes={{ popper: "error-tooltip" }}
                >
                  <span role="link">
                    <AlertCircle />
                  </span>
                </Tooltip>
              </span>
            </>
          )}
      </span>
      {!errorToolTip && (
        <>
          {errors && errors?.[name] && (
            <span className="error">{errors?.[name]?.message}</span>
          )}
          {errors && errors?.message && (
            <span className="error">{errors?.message}</span>
          )}
        </>
      )}
      {invalidAddressError && (
        <>
          <span className="error">
            Please select address from the suggestions.
          </span>
        </>
      )}
      {
        invalidResultCount && (
          <>
          <span className="error">
          Skip trace count cannot be more than total count.
          </span>
        </>
        )
      }
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default Input;
