import { initialState } from "./reducer";

const getStateData = (state: any) => state["myListsTable"] || initialState;

export const getMylistData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.myLists;
};

export const getListIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listIsLoading;
};

export const getMyListTotal = (state: any) => {
  const reducer = getStateData(state);
  return reducer.total;
};
