import * as yup from "yup";

export const advancedFilterFormSchema = yup.object({
  propertyCharacteristicsFilter: yup.object({
    yearBuiltMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Year built cannot be less than 1"),

    yearBuiltMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Year built cannot be less than 1")
      .test(
        "min-max",
        "Year built max cannot be less than year built min",
        function (value) {
          return minMaxEqualValidation(this.parent.yearBuiltMin, value);
        }
      ),
    storiesMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Stories cannot be less than 1"),
    storiesMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Stories cannot be less than 1")
      .test(
        "min-max",
        "Stories max cannot be less than stories min",
        function (value) {
          return minMaxEqualValidation(this.parent.storiesMin, value);
        }
      ),
  }),

  auctionsPreforeclousreFilter: yup.object({}),

  ownershipFilter: yup.object({
    yearsOwnedMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Years Owned cannot be less than 1"),
    yearsOwnedMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Years Owned cannot be less than 1")
      .test(
        "min-max",
        "Years Owned max cannot be less than Years Owned min",
        function (value) {
          return minMaxEqualValidation(this.parent.yearsOwnedMin, value);
        }
      ),
    lastSalePriceMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Last Sale Price cannot be less than 0"),
      // .transform((value: any) => (value ? value : 0)),
    lastSalePriceMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Last Sale Price cannot be less than 0")
      // .transform((value) => (value ? value : 0))
      .test(
        "min-max",
        "Last Sale price max cannot be less than last sale price min",
        function (value) {
          return minMaxEqualValidation(this.parent.lastSalePriceMin, value);
        }
      ),
  }),

  taxLienFilter: yup.object({
    taxDelinquentYearMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Tax Delinquent Year cannot be less than 1"),
    taxDelinquentYearMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Tax Delinquent Year cannot be less than 1")
      .test(
        "min-max",
        "Tax delinquent year max cannot be less than tax delinquent year  min",
        function (value) {
          return minMaxEqualValidation(this.parent.taxDelinquentYearMin, value);
        }
      ),
  }),

  valuationEquityFilter: yup.object({
    equityPercentMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Equity Percent cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    equityPercentMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Equity Percent cannot be less than 0")
      .test(
        "min-max",
        "Equity percent max cannot be less than equity percent min",
        function (value) {
          return minMaxEqualValidation(this.parent.equityPercentMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    estimatedEquityMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Estimated Equity cannot be less than 0"),
      // .transform((value) => (value ? parseInt(value) : 0)),
    estimatedEquityMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Estimated Equity cannot be less than 0")
      .test(
        "min-max",
        "Estimated equity max cannot be less than estimated equity min",
        function (value) {
          return minMaxEqualValidation(this.parent.estimatedEquityMin, value);
        }
      ),
      // .transform((value) => (value ? parseInt(value) : 0)),
    estimatedValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Estimated Value cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    estimatedValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Estimated Value cannot be less than 0")
      .test(
        "min-max",
        "Estimated value max cannot be less than estimated value min",
        function (value) {
          return minMaxEqualValidation(this.parent.estimatedValueMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    assessedValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Value cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    assessedValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed value max cannot be less than assessed value min",
        function (value) {
          return minMaxEqualValidation(this.parent.assessedValueMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    assessedLandValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Land Value cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    assessedLandValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Land Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed land value max cannot be less than assessed land value min",
        function (value) {
          return minMaxEqualValidation(this.parent.assessedLandValueMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    assessedImprovementValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Improvement Value cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    assessedImprovementValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Assessed Improvement Value cannot be less than 0")
      .test(
        "min-max",
        "Assessed improvement value max cannot be less than assessed improvement value min",
        function (value) {
          return minMaxEqualValidation(
            this.parent.assessedImprovementValueMin,
            value
          );
        }
      ),
      // .transform((value) => (value ? value : 0)),
  }),

  mortgageInfoFilter: yup.object({
    mortgageMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Mortgage cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    mortgageMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Mortgage cannot be less than 0")
      .test(
        "min-max",
        "Mortgage max cannot be less than mortgage min",
        function (value) {
          return minMaxEqualValidation(this.parent.mortgageMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
  }),

  cashBuyersFilter: yup.object({
    propertiesOwnedMin: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Properties Owned cannot be less than 1"),
    propertiesOwnedMax: yup
      .string()
      .matches(/^$|^[1-9]\d*$/, "Properties Owned cannot be less than 1")
      .test(
        "min-max",
        "Properties owned max cannot be less than properties Owned min",
        function (value) {
          return minMaxEqualValidation(this.parent.propertiesOwnedMin, value);
        }
      ),
    portfolioValueMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Value cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),

    portfolioValueMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Value cannot be less than 0")
      .test(
        "min-max",
        "Portfolio value max cannot be less than portfolio value min",
        function (value) {
          return minMaxEqualValidation(this.parent.portfolioValueMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    portfolioEquityMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Equity cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    portfolioEquityMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Equity cannot be less than 0")
      .test(
        "min-max",
        "Portfolio equity max cannot be less than portfolio equity min",
        function (value) {
          return minMaxEqualValidation(this.parent.portfolioEquityMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
    portfolioMortgageBalanceMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Mortgage Balance cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    portfolioMortgageBalanceMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Portfolio Mortgage Balance cannot be less than 0")
      .test(
        "min-max",
        "Portfolio mortgage balance max cannot be less than portfolio mortgage balance min",
        function (value) {
          return minMaxEqualValidation(
            this.parent.portfolioMortgageBalanceMin,
            value
          );
        }
      ),
      // .transform((value) => (value ? value : 0)),
  }),
  demographicsFilter: yup.object({
    medianIncomeMin: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Median Income cannot be less than 0"),
      // .transform((value) => (value ? value : 0)),
    medianIncomeMax: yup
      .string()
      .transform((originalValue) => {
        return typeof originalValue === 'string' ? originalValue.replace(/,/g, '') : originalValue;
      })
      .nullable()
      .min(0, "Median Income cannot be less than 0")
      .test(
        "min-max",
        "Median income max cannot be less than portfolio median income min",
        function (value) {
          return minMaxEqualValidation(this.parent.medianIncomeMin, value);
        }
      ),
      // .transform((value) => (value ? value : 0)),
  }),
  bedsBath: yup.object({
    minBedrooms: yup
      .string()
      .nullable()
      .nullable()
      .trim()
      .matches(/^$|^[0-9]*$/, "Bedrooms cannot be less than 0")
      .test("max", "Bedrooms min must be less than or equal to 99", function (value) {
        if (value && parseInt(value, 10) > 99) {
          return false; 
        }
        return true;
      }),
    maxBedrooms: yup
      .string()
      .nullable()
      .nullable()
      .trim()
      .matches(/^$|^[1-9]\d*$/, "Bedrooms cannot be less than 1")
      .test(
        "min-max",
        "Bedrooms max cannot be less than Bedrooms min",
        function (value) {
          return minMaxEqualValidation(this.parent.minBedrooms, value);
        }
      )
      .test("max", "Bedrooms max must be less than or equal to 99", function (value) {
        if (value && parseInt(value, 10) > 99) {
          return false; 
        }
        return true;
      }),
    minBathrooms: yup
      .string()
      .nullable()
      .trim()
      .matches(/^$|^[0-9]*$/, "Bathrooms cannot be less than 0")
      .test("max", "Bathrooms min must be less than or equal to 99", function (value) {
        if (value && parseInt(value, 10) > 99) {
          return false; 
        }
        return true;
      }),
    maxBathrooms: yup
      .string()
      .nullable()
      .trim()
      .matches(/^$|^[1-9]\d*$/, "Bathrooms cannot be less than 1")
      .test(
        "min-max",
        "Bathrooms max cannot be less than Bathrooms min",
        function (value) {
          return minMaxEqualValidation(this.parent.minBathrooms, value);
        }
      )
      .test("max", "Bathrooms max must be less than or equal to 99", function (value) {
        if (value && parseInt(value, 10) > 99) {
          return false; 
        }
        return true;
      }),

  }),
});

const minMaxEqualValidation = (
  min: string | number | undefined | null,
  max: string | number | undefined | null
) => {
  if (min && max) {
    return +max >= +min;
  } else {
    return true;
  }
};
