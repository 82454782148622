import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchList({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/detail-table?filter=${
          payload?.filter
        }&search=${payload?.search.trim()}&index=${payload?.index}&size=${
          payload?.size
        }&sortDirection=${payload?.sortDirection}&sortKey=${payload?.sortKey}`,
      headers: {},
    });
    yield all([
      put(
        payload?.index > 1
          ? slice.appendCampaignList({
              data: response?.data,
              total: response?.data?.total,
              totalPage: response?.data?.totalPage,
            })
          : slice.setCampaignList({
              data: response?.data,
              total: response?.data?.total,
              totalPage: response?.data?.totalPage,
            })
      ),
      put(slice.setCampaignListIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setCampaignListIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchCampaignStats({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/campaign/stats?filter=${
          payload?.filter
        }&search=${payload?.search.trim()}&index=${payload?.index}&size=${
          payload?.size
        }`,
      headers: {},
    });
    yield all([put(slice.setCampaignStats(response.data))]);
  } catch (error: any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* rootSaga() {
  yield takeLatest(sagaActions.fetchCampaignList, fetchList);
  yield takeLatest(sagaActions.fetchCampaignStats, fetchCampaignStats);
}

export default rootSaga;
