import { useSelector } from "react-redux";
import SearchReflation from "../../../../assets/icons/searchReflation";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import {
  formatCurrency,
  formatMonthYear,
  formatPhoneNumber,
  formatPropertyType,
} from "../../../../utils/utils";
import { getPropertyDetails } from "../../property.selector";
import styles from "./MlsTab.module.scss";

const MlsTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { mlsHistory } = propertyDetails?.jsonData;

  return (
    <div className={` ${styles.mls}`}>
      <div className="wrapper">
        {mlsHistory?.length === 0 && (
          <EmptyPlaceholder
            icon={<SearchReflation />}
            title={"No results found"}
            description="We couldn’t find any results that match your search or filtering criteria."
          />
        )}

        {mlsHistory?.map((el: Record<string, any>, index: number) => (
          <ShadowBox
            title={formatMonthYear(el?.statusDate)}
            subTitle={
              index !== 0
                ? "Historical MLS data for this property."
                : "Most recent MLS data for this property."
            }
            key={index}
          >
            <div className={`dflex ${styles.row}`}>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Status Date"
                  description={formatMonthYear(el?.statusDate, false)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Price"
                  description={formatCurrency(el?.price)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Status"
                  description={
                    el?.status !== "offmarket" ? "Active" : "Inactive"
                  }
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Days on Market"
                  description={el?.daysOnMarket}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Bed/Bath"
                  description={`${+el?.beds} / ${+el?.baths}`}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="MLS/Listing" description={el?.propertyId} />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Property Type"
                  description={formatPropertyType(el?.type)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="Agent Name" description={el?.agentName} />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Agent Phone"
                  description={formatPhoneNumber(el?.agentPhone)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="Office Name" description={el?.agentOffice} />
              </div>
            </div>
          </ShadowBox>
        ))}
      </div>
    </div>
  );
};
export default MlsTab;
