import { postAPI, putAPI } from "../../apis/apis";

export const postWholesaleCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/wholesale`,
      headers: {},
      data: payload,
    });
  };

  export const postAmortizationCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/amortization`,
      headers: {},
      data: payload,
    });
  };

  export const postFinanceCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance`,
      headers: {},
      data: payload,
    });
  };

  export const updateFinanceCalculator = async (payload:any,id:any) => {
    return putAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance/${id}`,
      headers: {},
      data: payload,
    });
  };