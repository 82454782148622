import {
  AdvancedMarker,
  ControlPosition,
  Map as GoogleMap,
  MapControl,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import locationMarker from "../../assets/images/location.svg";

import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MapBlue from "../../assets/icons/mapBlue";
import { MapShapesPayloadRaw } from "../../pages/propertySearch/PropertySearch";
import DrawControls from "./DrawControls";
import ViewModeControls from "./ViewModeControls";
import { Coordinate, MapViewMode, Marker } from "./map.types";
import {
  MEDIA_TYPES,
  MediaContext,
  MediaContextProps,
} from "../../context/MediaContext";

const initialBounds: google.maps.LatLngBoundsLiteral = {
  /* Austin Coordinates */
  // south: 30.10642820351337,
  // west: -97.97161385779384,
  // north: 30.41364725122462,
  // east: -97.48752877478603,

  /* USA Coordinates */
  south: 28.45530627304474,
  west: -122.58198018811572,
  north: 46.374329666062515,
  east: -66.55170675061572,
};

interface MapProps {
  height?: string;
  width?: string;
  coordinate: Coordinate;
  markers?: Marker[];
  selectedMarkers?: (number | string)[];
  toggleMarker?: Function;
  initialViewMode?: MapViewMode;
  scrollwheel?: boolean;
  allowToDrawShapes?: boolean;
  boundsRef?: { current: google.maps.LatLngBounds | null };
  onApplyButtonClick?: (payload: MapShapesPayloadRaw) => void;
  onRemoveBoundariesButtonClick?: () => void;
  onDrawButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  hovered?: any;
}

export function Map({
  height = "100vh",
  width = "100%",
  coordinate,
  markers,
  selectedMarkers = [],
  toggleMarker = () => {},
  initialViewMode = MapViewMode.ROADMAP,
  scrollwheel,
  allowToDrawShapes = false,
  onApplyButtonClick,
  onRemoveBoundariesButtonClick,
  onDrawButtonClick,
  onCancelButtonClick,
  hovered,
}: PropsWithChildren<MapProps>) {
  const [hideViewMode, setHideViewMode] = useState<boolean>(false);
  const [mapLoading, setMapLoading] = useState<boolean>(true);
  const streeViewMarkersRef = useRef<google.maps.Marker[]>([]);
  const markersRef = useRef<typeof markers>();
  markersRef.current = markers;
  const [viewMode, setViewMode] = useState<MapViewMode>(initialViewMode);
  const coreLibrary = useMapsLibrary("core");
  const { media } = useContext(MediaContext) as MediaContextProps;
  const map = useMap();

  const getInitialBounds = useCallback(() => {
    if (!coreLibrary) {
      return undefined;
    }

    const { LatLngBounds } = coreLibrary!;

    let bounds: google.maps.LatLngBounds | null = null;

    if (coordinate.lat === null && coordinate.lng === null) {
      bounds = new coreLibrary!.LatLngBounds(initialBounds);
    } else {
      let cat = { lat: coordinate.lat + 0.0001, lng: coordinate.lng };
      bounds = new LatLngBounds(cat);
    }

    if (!markers?.length) {
      return bounds;
    }

    for (const marker of markers) {
      bounds.extend(marker.coordinate);
    }

    return bounds;
  }, [coordinate.lat, coordinate.lng, coreLibrary, markers]);

  useEffect(() => {
    if (!map) return;
    if (!markers?.length && map.getBounds()?.toJSON()) return;
    const newBounds = getInitialBounds();
    map.fitBounds(newBounds as google.maps.LatLngBounds);
  }, [markers, map, getInitialBounds]);

  useEffect(() => {
    if (!map || !navigator.geolocation || (coordinate.lat && coordinate.lng)) {
      setMapLoading(false);
      return;
    }

    setMapLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setTimeout(() => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
          map.setZoom(10);
          setMapLoading(false);
        }, 1000);
      },
      () => {
        setMapLoading(false);
      },
      {
        enableHighAccuracy: false,
        maximumAge: Infinity,
        timeout: 10000,
      }
    );
  }, [map]);

  useEffect(() => {
    if (!map || !coreLibrary) return;

    const streetViewPanorama = map.getStreetView();

    streetViewPanorama.addListener('visible_changed', function () {
      // @ts-ignore
      const isVisible = this.visible

      streeViewMarkersRef.current.forEach(marker => {
        marker.setMap(null);
      })
      streeViewMarkersRef.current = [];

      if (isVisible) {
        markersRef.current?.forEach(({ coordinate, streetno = '' }) => {
          const marker = new google.maps.Marker({
            position: coordinate,
            map: streetViewPanorama,
            label: { text: streetno, color: "white" },
            draggable: false,
            icon: {
              url: locationMarker,
              labelOrigin: new google.maps.Point(30, 65),
              scaledSize: new google.maps.Size(60, 185),
            },
          });
          streeViewMarkersRef.current.push(marker);
        })
      }
    })

    return () => {
      coreLibrary.event.clearInstanceListeners(streetViewPanorama);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <div
      style={{ height, width }}
      id="google-map-ottoleads"
      className="map-main"
    >
      {mapLoading && (
        <div className="map-loader">
          <div className="dot-pulse"></div>
        </div>
      )}
      <GoogleMap
        mapId={"93bfca76dfdd5f79"}
        mapTypeId={viewMode}
        mapTypeControl={false}
        gestureHandling={
          media !== MEDIA_TYPES.DESKTOP ? "cooperative" : "greedy"
        }
        scrollwheel={scrollwheel}
        minZoom={5}
        zoomControlOptions={{
          position: ControlPosition.INLINE_END_BLOCK_START,
        }}
        streetViewControlOptions={{
          position: ControlPosition.INLINE_END_BLOCK_START,
        }}
      >
        {allowToDrawShapes && (
          <MapControl position={ControlPosition.BLOCK_START_INLINE_END}>
            <DrawControls
              onApplyButtonClick={(payload) => {
                setHideViewMode(false);
                onApplyButtonClick?.(payload);
              }}
              onDrawButtonClick={() => {
                setHideViewMode(true);
                onDrawButtonClick?.();
              }}
              onRemoveBoundariesButtonClick={() => {
                setHideViewMode(false);
                onRemoveBoundariesButtonClick?.();
              }}
              onCancelButtonClick={onCancelButtonClick}
            />
          </MapControl>
        )}

        {!hideViewMode && (
          <MapControl position={ControlPosition.BLOCK_START_INLINE_END}>
            <ViewModeControls setViewMode={setViewMode} />
          </MapControl>
        )}

        {markers?.map(({ id, component, coordinate }, index: number) => {
          return (
            <AdvancedMarker
              key={`${id}_${index}`}
              position={coordinate}
              onClick={() => toggleMarker(id)}
            >
              {selectedMarkers.includes(id) ? (
                component
              ) : id === hovered ? (
                <MapBlue />
              ) : null}
            </AdvancedMarker>
          );
        })}
      </GoogleMap>
    </div>
  );
}