import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../atoms/Input";

const Demographics = () => {
  const {
    formState: { errors },
    control,
    register,
  }: any = useFormContext();
  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="demographicsFilter.death"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Death</h5>
                  <p>
                    Properties where owner on the deed is recently deceased.
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="demographicsFilter.inherited"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Inheritance</h5>
                  <p>Properties which are inherited.</p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="demographicsFilter.investorBuyer"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Investor Buyer</h5>
                  <p>
                    Properties where the buyer is an absentee owner or investor.
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="demographicsFilter.privateLender"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Private Lender</h5>
                  <p>Properties which are financed by a private lender.</p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Household Income ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="demographicsFilter.medianIncomeMin"
                placeholder="No Min"
                errors={errors?.["demographicsFilter"]?.["medianIncomeMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="demographicsFilter.medianIncomeMax"
                placeholder="No Max"
                errors={errors?.["demographicsFilter"]?.["medianIncomeMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Demographics;
