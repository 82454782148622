import { useSelector } from "react-redux";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";
import { Navigate } from "react-router-dom";
import { getSubscriptions, getSubscriptionsIsLoading } from "../billing/selectors";
import { getRefreshPage } from "./selectors";

const GeneralAccessWrapper = ({ children }: { children: React.ReactNode }) => {
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const subscriptionsLoading = useSelector((state) => getSubscriptionsIsLoading(state));
   const refreshPage=useSelector((state) =>
    getRefreshPage(state)
  );
  if (!sunscriptions.hasOwnProperty('generalSubData')||(subscriptionsLoading&&refreshPage)) {
    return <GlobalLoader></GlobalLoader>;
  }
  if (!(!sunscriptions?.generalSubData || sunscriptions?.generalSubData?.lastPaymentStatus ===
    "card_declined")) { 
    return <>{children}</>;
  }
   else {
     return <Navigate to="/settings" />;
  }
};
export default GeneralAccessWrapper;
