
import React from "react";
import Button from "../../atoms/Button";
import { formartNumberAsLocalString } from "../../utils/utils";
import styles from "./PurchaseConfirmation.module.scss";
import Shield from "../../assets/icons/shield";
import Check from "../../assets/icons/check";
import Zap from "../../assets/icons/zap";
import Refresh from "../../assets/icons/refresh";
import CheckCircle from "../../assets/icons/checkCircle";
import Trophy from "../../assets/icons/trophy";

const PurchaseConfirmation = ({
  onSubmit,
  handleClose,
  selectedPricing,
  currentCredits,
  paymentSuccess,
  confirmSubscriptionLoading,
}: any) => {
  return (
    <>
     
      {paymentSuccess ? (
        <>
         <div className={`${styles.confirm}`}>
         <div className={`${styles.confirm__success}`}>
            <figure>
                <Trophy />
            </figure>
            <div className={`${styles.confirm__header}`}>
            <h2>Congratulations!</h2>
            <p>
              Your transaction has been <u>approved</u> and your plan has been <u>updated</u>,
              as per your request!
            </p>
          </div>
          <div className={`${styles.confirm__footer}`}>
            <Button className="button animated primary full" action={handleClose} label="Awesome!"></Button>
            </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles.confirm}`}>
          <div className={`${styles.headerIcon}`}>
          <span className={`${styles.flagBorder}`}>  <Check /></span>
            </div>
          <div className={`${styles.confirm__header}`}>
          <h2>Let's confirm your purchase</h2>
          <p>The following are important details to review.</p>
          </div>
          <div className={`${styles.confirm__info}`}>
            <ul>
                <li>
                    <figure>
                        <Zap />
                    </figure>
                    <figcaption>
                    <p>
              $<strong>{formartNumberAsLocalString(+(selectedPricing?.unitAmount ?? 0), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</strong>{" "}
              total due now
            </p>
            <p> Renews <u>automatically</u> every month.</p>
                    </figcaption>
                </li>
                <li>
                    <figure>
                        <Refresh />
                    </figure>
                    <figcaption>
                    <p><strong>Credits are refreshed</strong> every month</p>
            <p>
              Your credits reset to {" "}
              {formartNumberAsLocalString(currentCredits)} every month.
            </p>
                    </figcaption>
                </li>
                <li>
                    <figure>
                        <CheckCircle />
                    </figure>
                    <figcaption>
                    <p><strong>You won't lose your current credits</strong></p>
            <p>
              {" "}
              {formartNumberAsLocalString(currentCredits)} will be added to your{" "} <u>existing</u> credits.
            </p>
                    </figcaption>
                </li>
            </ul>
         </div>
            <Button
              action={onSubmit}
              label={`Pay $${formartNumberAsLocalString(
                +(selectedPricing?.unitAmount ?? 0),
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )} now & confirm subscription`}
              clicked={confirmSubscriptionLoading}
              disabled={confirmSubscriptionLoading}
              className="button primary animated full"
              postfix={<Shield />}
            >
     
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default PurchaseConfirmation;
