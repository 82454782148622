import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pill from "./Pill";
import { getPillStatusIcon } from "./PillStatusIcon";
import { getPillStatus } from "../constants/status.constants";
import { useState, useEffect } from "react";
import DropDownIcon from "../assets/icons/dropDownIcon";
import Tick from "../assets/icons/tick";

const PillDropdown = ({ options, label, onChange }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const initialLabel = label;
  const [selectedValue, setSelectedValue] = useState<string>(initialLabel);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: any) => {
    if (onChange) {
      onChange(value);
    }
    setSelectedValue(value);
    handleClose();
  };
  useEffect(() => {
    setSelectedValue(label);
  }, [label]);
  return (
    <div className="leads-pill">
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        role="link"
      >
        <Pill
          label={selectedValue}
          className={getPillStatus(selectedValue)}
          infix={getPillStatusIcon(selectedValue)}
          postIcon={<DropDownIcon />}
        />
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option: any, index: number) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(option.value)}
          >
            <div className="flex pillsDropdown alignCenter justifySpaceBetween">
              <span  data-testid="pillOptionTestId"> {option.value}</span>
              {selectedValue === option?.value && (
                <span>
                  <Tick />
                </span>
              )}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PillDropdown;
