import Dialog from "@mui/material/Dialog";
import Close from "../../assets/icons/cross";
import PriceCardFooter from "../../components/priceCard/PriceCardFooter";
import PriceCardInfo from "../../components/priceCard/PriceCardInfo";
import Button from "../../atoms/Button";
import PriceCard from "../../components/priceCard/PriceCard";
import PriceCardHeader from "../../components/priceCard/PriceCardHeader";
import CustomSwitch from "../../atoms/CustomSwitch";
import styles from "./SubscribeModal.module.scss";
import Pill from "../../atoms/Pill";
import { useForm } from "react-hook-form";
import Shield from "../../assets/icons/shield";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGeneralAccessProduct,
  fetchInvoices,
} from "../billing/sagaActions";
import {
  getGeneralAccessProduct,
  getGeneralAccessProductsIsLoading,
  getSubscriptions,
} from "../billing/selectors";
import { getProductByInterval } from "../billing/utils";
import { ProductPriceIntervalType, ProductType } from "../billing/enum";
import { fbc, fbp, fetchIp, formartNumberAsLocalString, gtagEvent, hashNumber, hashString } from "../../utils/utils";
import { createSubscription, updatePlan } from "../billing/billingService";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import Loader from "../../atoms/Loader";
import { SubscribeModalProps } from "./interface";
import { FbConversionApi } from "../auth/authServices";
import ReactGA from "react-ga4";
import { GtagEventParams } from "../../models/gtag.interface";

const SubscribeModal = ({
  handleClose,
  open,
  changeSubscription,
  product,
  displayErrorMessage,
  displaySuccessMessage,
  setShow
}: SubscribeModalProps) => {
  const { control, setValue, watch } = useForm({
    mode: "onSubmit",
    defaultValues: { annualPricing: false },
  });
  const dispatch = useDispatch();
  const [selectedPricing, setSelectedPricing] = useState<any>(null);
  const [confirmSubscriptionLoading, setConfirmSubscriptionLoading] =
    useState(false);
  const [discount, setDiscount] = useState(0);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const generalAccessProducts = useSelector((state) =>
    getGeneralAccessProduct(state)
  );
  const generalAccessProductsIsLoading = useSelector((state) =>
    getGeneralAccessProductsIsLoading(state)
  );
  const subscriptions = useSelector((state) => getSubscriptions(state));

  const getDiscount = () => {
    const selected: any = getProductByInterval(
      true,
      generalAccessProducts?.[0]
    );
    setDiscount(selected?.discountPercent);
  };
  
  const confirmSubscription = async () => {
    const payloadData = {
      data: [
        {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: window.location.href,
          action_source: "website",
          user_data: {
            client_ip_address: fetchIp,
            client_user_agent: navigator.userAgent,
            fbc: fbc,
            fbp: fbp,
            em: hashString(profileInfo?.email),
            ph: hashNumber(profileInfo?.phone),
            external_id: hashNumber(profileInfo?.organisationId),
            fn:hashString(profileInfo?.nameFirst),
            ln:profileInfo?.nameLast ? hashString(profileInfo?.nameLast) : null,
          },
          custom_data: {
            value: selectedPricing?.unitAmount,
            currency: "USD",
         },

        },
      ],
    };
    const params: GtagEventParams = {
      event_category: "User",
      event_label: "Purchase",
      user_email: profileInfo?.email,
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      Id: profileInfo?.organisationId,
      first_name: profileInfo?.nameFirst,
      last_name: profileInfo?.nameLast ? profileInfo?.nameLast : null,
      value: selectedPricing?.unitAmount,
      currency: "USD",
    };
    try {
      setConfirmSubscriptionLoading(true);
      if (changeSubscription) {
        await updatePlan({
          stripeSubscriptionId: product?.stripeSubscriptionId,
          stripePriceId: selectedPricing?.stripePriceId,
          subsProductType: ProductType.GENERAL_ACCESS,
        });
        await FbConversionApi(payloadData);
        gtagEvent('event', 'Purchase', params);
        // ReactGA.event({
        //   category: 'User',
        //   action: 'Purchase',
        //   value: selectedPricing?.unitAmount,
        // });
      } else {
        let payload = {
          paymentMethod: profileInfo?.organisation?.paymentMethodId,
          price: selectedPricing.stripePriceId,
          subsProductType: ProductType.GENERAL_ACCESS,
          trialPeriodDays: selectedPricing.trialPeriodDays,
        };
        await createSubscription(payload);
        await FbConversionApi(payloadData);
        gtagEvent('event', 'Purchase', params);
      }
      setTimeout(() => {
        if (changeSubscription) {
          displaySuccessMessage("Subscription upgraded successfully!");
        } else {
          displaySuccessMessage("Subscription confirmed successfully!");
        }
        if(window.location.pathname.includes("/properties")){
          if(!confirmSubscriptionLoading){
            setShow(true)
          }
        }
        dispatch(fetchProfileInfo({}));
        dispatch(fetchInvoices({}));
        handleClose();
        setConfirmSubscriptionLoading(false);
      }, 3000);
    } catch (error) {
      displayErrorMessage(error);
      setConfirmSubscriptionLoading(false);
    }
  };
  useEffect(() => {
    dispatch(fetchGeneralAccessProduct({}));
  }, []);

  useEffect(() => {
    if(subscriptions?.generalSubData?.isTrial && window.location.pathname.includes("/properties")){
      setValue("annualPricing", false);
    }
    else if (product?.interval === ProductPriceIntervalType.MONTH) {
      setValue("annualPricing", true);
    }
    else {
      setValue("annualPricing", false);
    }
  }, [product]);

  useEffect(() => {
    if (generalAccessProducts.length > 0) {
      const selected = getProductByInterval(
        watch("annualPricing") ?? false,
        generalAccessProducts?.[0]
      );
      setSelectedPricing(selected);
      getDiscount();
    }
  }, [generalAccessProducts, watch("annualPricing")]);

  return (
    <Dialog onClose={handleClose} open={open} className={`stepsDialog`}>
      {generalAccessProductsIsLoading ? (
        <>
          <div className={`loader-main-large`}>
            <Loader></Loader>
          </div>
        </>
      ) : (
        <div className={`${styles.subscribeModal}`}>
          <span
            role="link"
            onClick={() => !confirmSubscriptionLoading && handleClose()}
            className="closeDialog"
          >
            <Close />
          </span>
          <PriceCard>
            <PriceCardHeader>
              <div
                className={`dflex justifySpaceBetween alignCenter cardHeader`}
              >
                <div>
                  <h2>
                    General Access{" "}
                    <Pill
                      label={`Billed ${
                        watch("annualPricing") ? "Annualy" : "Monthly"
                      }`}
                      className="completed"
                    />
                  </h2>

                  <div className={` cardSwitch flex alignCenter`}>
                    {(!changeSubscription || subscriptions?.generalSubData?.isTrial) && (
                      <CustomSwitch
                        control={control}
                        name="annualPricing"
                        defaultChecked={false}
                      />
                    )}
                    <div>
                      <h5>
                        Annual pricing <span>(save {discount ?? 0}%)</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className={`cardHeader__right`}>
                  <div className="flex">
                    <div className={` cardPrice `}>
                      <small>$</small>
                      {watch("annualPricing")
                        ? Math.floor(selectedPricing?.unitAmount / 12)
                        : selectedPricing?.unitAmount ?? 0}
                    </div>
                  </div>
                  <p>first month</p>
                </div>
              </div>
            </PriceCardHeader>
            <PriceCardInfo>
              <div
                className={`dflex justifySpaceBetween alignCenter cardInfo ${styles.subscribeModal__list}`}
              >
                <div className="check-list">
                  <ul>
                    <li>
                      Advanced property search with over 50 expertly-crafted
                      filters
                    </li>
                    <li>
                      Purchase lists, skip trace, and upload your own data
                    </li>
                    <li>
                      Access to our wholesale and amortization calculators
                    </li>
                    <li>
                      Ability to upgrade to our exclusive AI calling packages
                    </li>
                    <li>
                      Easy to use CRM features to manage your leads and follow
                      ups
                    </li>
                  </ul>
                </div>

                <p className={`${styles.textSpace}`}>
                  AI calling packages not included.
                </p>
              </div>
              {ProductPriceIntervalType.YEAR === selectedPricing?.interval && (
                <>
                  <div className={`dflex justifySpaceBetween totalRow`}>
                    <p>Regular</p>
                    <div className={` right`}>
                      <p>
                        {" "}
                        $
                        {formartNumberAsLocalString(
                          +selectedPricing?.actualPrice ?? 0,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  <div className={`dflex justifySpaceBetween totalRow`}>
                    <p>Savings</p>
                    <div className={` right`}>
                      <p>
                        -$
                        {formartNumberAsLocalString(
                          +(selectedPricing?.actualPrice ?? 0) -
                            +(selectedPricing?.unitAmount ?? 0),
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  {/* <div className={`dflex justifySpaceBetween totalRow`}>
                    <p>Tax</p>
                    <div className={` right`}>
                      <p>
                        $
                        {selectedPricing.taxAmount}
                      </p>
                    </div>
                  </div> */}
                </>
              )}
             {ProductPriceIntervalType.YEAR != selectedPricing?.interval && <div className={`flex justifySpaceBetween totalRow borderTop`}>
                <p>Actual Price</p>
                <div className={` right`}>
                  <p>${selectedPricing?.actualPrice}</p>
                </div>
              </div>}
              {selectedPricing?.taxAmount !=0 && <div className={`dflex justifySpaceBetween totalRow`}>
                <p>Tax</p>
                <div className={` right`}>
                  <p>${selectedPricing?.taxAmount}</p>
                </div>
              </div>}
              <div className={`dflex justifySpaceBetween totalRow borderTop`}>
                <p>Total due today:</p>
                <div className={` right`}>
                  <p>
                    <strong>
                      $
                      {formartNumberAsLocalString(
                        +(selectedPricing?.unitAmount ?? 0),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </strong>
                  </p>
                  <p className={` gray`}>
                    $
                    {formartNumberAsLocalString(
                      +(selectedPricing?.unitAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    / {selectedPricing?.interval} after
                  </p>
                </div>
              </div>
            </PriceCardInfo>

            <PriceCardFooter>
              <Button
                label={"Confirm subscription and pay now"}
                postfix={<Shield />}
                className="primary animated full xl"
                action={confirmSubscription}
                clicked={confirmSubscriptionLoading}
                disabled={confirmSubscriptionLoading}
              ></Button>
            </PriceCardFooter>
          </PriceCard>
        </div>
      )}
    </Dialog>
  );
};
export default SubscribeModal;