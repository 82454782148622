import { useSelector } from "react-redux";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formatCurrency } from "../../../../utils/utils";
import { getPropertyDetails } from "../../property.selector";
import styles from "./TaxTab.module.scss";

const TaxTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { taxInfo,propertyInfo,lotInfo } = propertyDetails?.jsonData;

  return (
    <div className={` ${styles.tax}`}>
      <div className="wrapper">
        <ShadowBox
          title="Tax Information"
          subTitle="Most recent taxes for this property."
        >
          <div className={`dflex ${styles.row}`}>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="APN"
                description={lotInfo?.apnUnformatted ? lotInfo?.apnUnformatted : "-" }
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard title="Tax Year" description={taxInfo?.year} />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Tax Amount "
                description={formatCurrency(taxInfo?.taxAmount)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessment Year"
                description={taxInfo?.assessmentYear}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Total Assessed Value"
                description={formatCurrency(taxInfo?.assessedValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard title="County" description={propertyInfo?.address?.county ? propertyInfo?.address?.county  : "-" } />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Land Value"
                description={formatCurrency(taxInfo?.marketLandValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Value"
                description={formatCurrency(taxInfo?.marketValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Improvement Value"
                description={formatCurrency(taxInfo?.marketImprovementValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessed Land Value"
                description={formatCurrency(taxInfo?.assessedLandValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessed Improvement Value"
                description={formatCurrency(taxInfo?.assessedImprovementValue)}
              />
            </div>
          </div>
        </ShadowBox>
      </div>
    </div>
  );
};
export default TaxTab;
