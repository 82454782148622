import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI, postAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import store from "../../store/store";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* searchAddress({ payload }: any) {
  try {
    yield put(slice.setAddressPredictionLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/address/search`,
      headers: {},
      data: {
        searchTerm: payload?.trim(),
      },
    });
    yield put(slice.setAddressPredictionLoading(false));
    if (store.getState().propertySearch.searchTerm) {
      yield put(slice.setAddressPredictions(response));
    }
  } catch (error: any) {
    yield all([put(slice.setAddressPredictionLoading(false))]);
  }
}

function* searchProperty({ payload }: any) {
  yield put(slice.setPropertiesListLoading(true));
  try {
    const response: ResponseGenerator = yield postAPI({
      url: process.env.REACT_APP_BASE_URL + `/advanced-search-filter`,
      headers: {},
      data: payload,
    });
    for (const property of response.data.result) {
      const mapResponse: ResponseGenerator = yield getAPI({
        url: `https://maps.googleapis.com/maps/api/streetview/metadata?location=${property.latitude},${property.longitude}&size=400x400&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      });
      if (mapResponse.data.status === "OK") {
        property.propertyImageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${property.latitude},${property.longitude}&size=400x400&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      }
    }
    yield put(slice.setPropertiesListLoading(false));
    if (store.getState().propertySearch.searchTerm) {
      response.data.searchTerm = store.getState().propertySearch.searchTerm;
      yield put(
        payload.resultIndex > 0
          ? slice.appendSearchedProperties(response)
          : slice.setSearchedProperties(response)
      );
    }
  } catch (error: any) {
    yield all([
      put(slice.setPropertiesListLoading(false)),
      put(
        slice.setSearchedProperties({
          data: {
            result: [],
            results_count: -1,
            potential_count: 0,
            searchTerm: "",
          },
        })
      ),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* sendEmailNotificationData({ payload }: any) {
  try {
    yield postAPI({
      url: process.env.REACT_APP_BASE_URL + `/mail/send`,
      headers: {},
      data: payload,
    });
  } catch (error: any) {
    console.log("error=", error);
  }
}

function* propertySearchSaga() {
  yield takeLatest(sagaActions.searchAddress, searchAddress);
  yield takeLatest(sagaActions.searchProperty, searchProperty);
  yield takeLatest(sagaActions.sendEmailAction, sendEmailNotificationData);
}

export default propertySearchSaga;
