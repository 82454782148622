import dayjs from "dayjs";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { DefaultValue } from "../pages/register/interface";
import axios from "axios";
import crypto from 'crypto-js';
import {GtagEventParams} from "../models/gtag.interface";

export const timeAgoFormatter = (date: Date) => {
  const currentTime = new Date();
  const diffInMiliSeconds = currentTime.getTime() - date.getTime();
  let time = diffInMiliSeconds / (365 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " years" : "a year"} ago`;
  }
  time = diffInMiliSeconds / (30 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " months" : "a month"} ago`;
  }
  time = diffInMiliSeconds / (7 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " weeks" : "a week"} ago`;
  }
  time = diffInMiliSeconds / (60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " days" : "a day"} ago`;
  }
  time = diffInMiliSeconds / (60 * 60 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " hours" : "over an hour"} ago`;
  }
  time = diffInMiliSeconds / (60 * 1000);
  if (time < 15) {
    return "a few moments ago";
  } else {
    return "less than an hour ago";
  }
};

export const decodetoken = (token = "", wantDetails = false) => {
  const jwt = token ? token : localStorage.getItem("accessToken") ?? "";

  try {
    const decodedToken = jwtDecode(jwt);

    let expirationTime = null;
    if (decodedToken && decodedToken.exp) {
      expirationTime = decodedToken.exp * 1000;
    }
    if (wantDetails && decodedToken) {
      return decodedToken;
    }
    return expirationTime;
  } catch (error: unknown) {
    return null;
  }
};

export const jwt = localStorage.getItem("accessToken");
// export const followUpDateFormatter = (date: Date | null | any) => {

//   if (!date) {
//     return "None";
//   }
//   const currentTime = new Date();
//   const dateTime = new Date(date.toISOString().slice(0, -1));
//   const diffInMiliSeconds = currentTime.getTime() - dateTime.getTime();
//   const diffInHours = Math.floor(diffInMiliSeconds / (60 * 60 * 1000));
//   if (diffInHours >= 0 && diffInHours < 24) {
//     return "Today";
//   } else if (diffInHours >= 24 && diffInHours < 48) {
//     return "Yesterday";
//   } else if (diffInHours < 0 && diffInHours > -24) {
//     return "Tomorrow";
//   } else {
//     return dayjs(date).format(`MMM DD, YYYY`);
//   }

//   // if (Math.abs(time) < 24) {
//   //   return "Today";
//   // } else if (Math.abs(time) < 48 && Math.abs(time) > 24) {
//   //   if (Math.floor(time) < 0) {
//   //     return "Tomorrow";
//   //   } else {
//   //     return "Yesterday";
//   //   }
//   // } else {
//   //   console.log("date=====",dayjs(date).format("MMM DD ,YYYY"))
//   //   return dayjs(date).format("MMM DD ,YYYY");
//   // }
// };

export const followUpDateFormatter = (dateString: Date | null | any) => {
  if (!dateString) {
    return "None";
  }
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  if (
    inputDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
    inputDate.getUTCMonth() === currentDate.getUTCMonth() &&
    inputDate.getUTCDate() === currentDate.getUTCDate()
  ) {
    return "Today";
  }
  const tomorrow = new Date(currentDate);
  tomorrow.setUTCDate(currentDate.getUTCDate() + 1);
  if (
    inputDate.getUTCFullYear() === tomorrow.getUTCFullYear() &&
    inputDate.getUTCMonth() === tomorrow.getUTCMonth() &&
    inputDate.getUTCDate() === tomorrow.getUTCDate()
  ) {
    return "Tomorrow";
  }
  const yesterday = new Date(currentDate);
  yesterday.setUTCDate(currentDate.getUTCDate() - 1);
  if (
    inputDate.getUTCFullYear() === yesterday.getUTCFullYear() &&
    inputDate.getUTCMonth() === yesterday.getUTCMonth() &&
    inputDate.getUTCDate() === yesterday.getUTCDate()
  ) {
    return "Yesterday";
  }
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "UTC",
    month: "short",
    day: "numeric",
    year: "numeric" as const,
  };
  return new Intl.DateTimeFormat("en-US", options).format(inputDate);
};

export const campaigndaysNMonthsFormatter = (date: Date) => {
  const currentTime = dayjs();
  const dateTime = dayjs(date);

  const diffInDays = currentTime.diff(dateTime, "day");
  const diffInMonths = currentTime.diff(dateTime, "month");

  if (diffInDays > 0) {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else if (diffInMonths > 0) {
    return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
  } else {
    return "just now";
  }
};

export const campaignRefreshTimeFormatter = (date: Date) => {
  const currentTime = new Date();
  const dateTime = new Date(date);
  const diffInMiliSeconds = currentTime.getTime() - dateTime.getTime();
  let time = diffInMiliSeconds / (60 * 60 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " hrs" : "1 hr"} ago`;
  }
  time = diffInMiliSeconds / (60 * 1000);
  if (time > 1) {
    return `${Math.floor(time)} minutes ago`;
  } else {
    return `just now`;
  }
};

export const graphXaxisDate = (date: Date) => {
  const currentTime = new Date();
  const dateTime = new Date(date);
  const diffInMiliSeconds = currentTime.getTime() - dateTime.getTime();
  let time = diffInMiliSeconds / (60 * 60 * 1000);
  return Math.abs(Math.floor(time));
};

export function formatDate(inputDateString: string | undefined | null) {
  if (inputDateString === undefined || inputDateString === null) {
    return "-";
  }
  const inputDate = new Date(inputDateString);

  // Extract components of the date
  const day = inputDate.getUTCDate();
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based
  const year = inputDate.getUTCFullYear();

  // Pad single-digit day or month with leading zero if necessary
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Create the formatted date string
  const formattedDateString = `${formattedDay}/${formattedMonth}/${year}`;

  return formattedDateString;
}

export function formatMonthYear(dateString: string | undefined | null, word = true) {
  if (dateString === undefined || dateString === null) {
    return "-";
  }
  const inputDate = new Date(dateString);

  // Get the month name and year
  if (word) {
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(inputDate);
    const year = inputDate.getFullYear();
    const day = inputDate.getDate();

    // Create the formatted string
    const formattedDateString = `${day},${month}, ${year}`;

    return formattedDateString;
  }
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based
  const year = inputDate.getUTCFullYear();
  const day = inputDate.getDate();

  // Pad single-digit month with leading zero if necessary
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Create the formatted string
  const formattedDateString = `${formattedMonth}/${day}/${year}`;

  return formattedDateString;
}

export function formatPropertyType(propertyType: string | undefined | null) {
  if (propertyType === undefined || propertyType === null) {
    return "-";
  }
  // Split the input string into words
  const words = propertyType.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with a space
  const formattedPropertyType = capitalizedWords.join(" ");

  return formattedPropertyType;
}

export function getDateDifferenceInWords(timestamp: Date, minimal = false): string {
  const timeDifference = new Date().getTime() - timestamp.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return minimal ? "just now" : "moments ago";
  } else if (minutes === 1) {
    return minimal ? "1m ago" : "a minute ago";
  } else if (minutes < 60) {
    return minimal ? `${minutes}m ago` : `${minutes} minutes ago`;
  } else if (hours === 1) {
    return minimal ? "1h ago" : "an hour ago";
  } else if (hours < 24) {
    return minimal ? `${hours}h ago` : `${hours} hours ago`;
  } else if (days === 1) {
    return minimal ? "1d ago" : "a day ago";
  } else {
    return minimal ? `${days}d ago` : `${days} days ago`;
  }
}

export function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return "-";
  let match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) return "-";
  return "(" + match[1] + ") " + match[2] + "-" + match[3];
}

export function formatCurrency(value: any) {
  if (!parseFloat(value)) {
    return "-";
  }
  return "$" + formartNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formartNumberAsLocalString(value: any, option: any = {}, fallback?: any) {
  if (!parseFloat(value)) {
    return fallback ? fallback : "-";
  }
  return parseFloat(value).toLocaleString(undefined, option);
}

export function formatNewCurrency(value: any) {
  if (!parseFloat(value)) {
    return "0";
  }
  return "$" + formartNewNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatUpdateCurrency(value: any) {
  if (!parseFloat(value)) {
    return "0";
  }
  return  formartNewNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formartNewNumberAsLocalString(value: any, option: any = {}, fallback?: any) {
  if (!parseFloat(value)) {
    return fallback ? fallback : "0";
  }
  return parseFloat(value).toLocaleString(undefined, option);
}


export function isCSVEmpty(rows: string[][]) {
  return (
    rows.length === 1 ||
    !rows.reduce((prev, current, index) => {
      if (index === 0) return prev;
      return prev + current.reduce((p, c) => p + c, "").trim();
    }, "")
  );
}
export function getLocalStorageData(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) ?? "");
  } catch {
    return localStorage.getItem(key) || null;
  }
}

export function getFormattedDate(date: any) {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObject.getDate()).slice(-2);
  const formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
}

export function convertToFollowUpAt(date: any) {
  var today = new Date();
  if (date === null || date.toLowerCase() === "none") {
    return null;
  } else if (date.toLowerCase() === "today") {
    return getFormattedDate(today);
  } else if (date.toLowerCase() === "tomorrow") {
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return getFormattedDate(tomorrow);
  } else if (date.toLowerCase() === "yesterday") {
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return getFormattedDate(yesterday);
  } else {
    var dateObject = new Date(date);
    return getFormattedDate(dateObject);
  }
}

export const formatNumber = (number: number): string => {
  if (number < 1e3) return number?.toFixed(1)?.replace(/\.0$/, "");

  if (number >= 1e3 && number < 1e6) {
    const roundedNumber = Math.floor((number / 1e3) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}k`;
  }

  if (number >= 1e6 && number < 1e9) {
    const roundedNumber = Math.floor((number / 1e6) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}M`;
  }

  if (number >= 1e9 && number < 1e12) {
    const roundedNumber = Math.floor((number / 1e9) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}B`;
  }

  if (number >= 1e12) {
    const roundedNumber = Math.floor((number / 1e12) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}T`;
  }

  return number?.toString();
};

export function toCamelCase(str: string) {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}

export const firstName = (str: string) => {
  const firstNameParts = str?.split(" ");
  let finalFirstName = "";
  for (let i = 0; i < firstNameParts?.length; i++) {
    finalFirstName = finalFirstName + " " + toCamelCase(firstNameParts[i]);
  }
  return finalFirstName;
};

export function getCookie(name: string) {
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
}

export function isValidTextApp(text: string) {
  const regex = /^(?!\s)(?!.*\s$)(?!.{21,}$)\s*\S+(\s+\S+)*\s*$/;
  return regex.test(text);
}

export const dateFn = (inputDate: any) => {
  if (inputDate === null) {
    return null;
  }
  if (inputDate === "Today") {
    return new Date().toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } else if (inputDate === "Tomorrow") {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } else if (inputDate === "Yesterday") {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
  const parsedDate = new Date(inputDate);
  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date";
  }

  return parsedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export function formatMonths(value: any) {
  if (!Boolean(value)) {
    return "-";
  }

  const months = parseInt(value);

  if (months === 1) {
    return `1 month`;
  } else if (months < 12) {
    return `${months} months`;
  } else {
    const tempMonth = Math.floor(months / 12);
    let result = `${tempMonth} ${tempMonth === 1 ? "year" : "years"}`;
    if (months % 12) {
      result += ` ${formatMonths(months % 12)}`;
    }
    return result;
  }
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard:", text);
    toast.info("Copied!", { autoClose: 2000 });
  } catch (err) {
    console.error("Unable to copy text to clipboard:", err);
  }
};

export const formatDateRange = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function hasNullValue(prop: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.has(prop) && searchParams.get(prop) !== "null" && searchParams.get(prop) !== "undefined";
}
export function getParamsValue(prop: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(prop);
}

export const getParamsValueForSignup = (): DefaultValue => {
  const regex = /[^\d]/g;

  const defaultValue: DefaultValue = {
    confirmPolicy: false,
    name: "",
    email: "",
    password: "",
    companyName: "",
    phone: "",
    hearAboutUs: "",
  };
  if (hasNullValue("email")) {
    defaultValue.email = (getParamsValue("email")?.trim() ?? "").replace(/ /g, "+");
  }
  if (hasNullValue("company")) {
    defaultValue.companyName = getParamsValue("company")?.trim() ?? "";
  }

  if (hasNullValue("phone")) {
    defaultValue.phone = (getParamsValue("phone")?.trim() ?? "").replace(/[^\d]/g, "");
  }
  let name = "";
  if (hasNullValue("firstname")) {
    name += getParamsValue("firstname")?.trim() ? getParamsValue("firstname")?.trim() + " " : "";
  }
  if (hasNullValue("lastname")) {
    name += getParamsValue("lastname")?.trim();
  }
  defaultValue.name = name;
  return defaultValue;
};

export function getParseFloatValue(value: any) {
  return parseFloat((value)?.replace(/,/g, "") ?? "")
}

export const fetchIp = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response?.data?.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
};

export const fbc = getCookie('_fbc');
export const fbp = getCookie('_fbp');

export const hashString = (value:string) => {
  return crypto.SHA256(value.trim().toLowerCase()).toString();
};

export const hashNumber = (number:any) => {
  return crypto.SHA256(number).toString();
};

export const hashPhoneNumber = (number:any) => {
  return crypto.SHA256(number.trim().replace(/\D/g, '')).toString();
};

export const formatDateAndTime = (dateTimeString: any) => {
  const date = new Date(dateTimeString);
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true 
  };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  return `${formattedDate}, ${formattedTime}`; 
}

// export const gtagEvent = (action: string, params: GtagEventParams) => {
//   if (typeof window !== 'undefined' && window.gtag) {
//     window.gtag('event', action, params);
//   } else {
//     console.error('gtag function is not available');
//   }
// };

// export const gtagEvent = (event: string, action: string, params: GtagEventParams) => {
//   if (typeof window === 'undefined' || !window.gtag) {
//     console.error('Google Analytics gtag function is not available.');
//     return;
//   }

//   const { custom_params, ...restParams } = params;
//   const updatedParams: GtagEventParams = {
//     ...restParams,
//     custom_params: {
//       ...custom_params,
//       user_name: params.custom_params?.user_name 
//     }
//   };

//   window.gtag(event, action, updatedParams); 
// };

export const gtagEvent = (event: string, action: string, params: GtagEventParams) => {
  if (typeof window === 'undefined' || !window.gtag) {
    console.error('Google Analytics gtag function is not available.');
    return;
  }

  const { custom_params, ...restParams } = params;
  const updatedParams: GtagEventParams = {
    ...restParams,
    custom_params: {
      ...custom_params,
      ...params.custom_params 
    }
  };

  window.gtag(event, action, updatedParams);
};

export const addDaysToDate = (days:any, expiryDate = new Date()) => {
  const result = new Date(expiryDate);
  result.setDate(result.getDate() + parseInt(days, 10));
  return result.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
};