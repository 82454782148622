import { useSelector } from "react-redux/es/hooks/useSelector";
import { SettingHeader } from "../../components/settingHeader/SettingHeader";
import HeroSection from "../../organisms/heroSection/HeroSection";
import { getSubscriptions } from "../../organisms/billing/selectors";
import AddPaymentModal from "../../organisms/billing/AddPaymentModal";
import { useEffect, useState } from "react";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { fbc, fbp, fetchIp, hashNumber } from "../../utils/utils";
import { getProfileInfo } from "../../components/profileMenu/selectors";

const Settings = () => {
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const[ openAddPaymentModal, setOpenAddPaymentModal]= useState(false)
  const profileInfo = useSelector((state) => getProfileInfo(state));
  
  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Setting_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(profileInfo?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  return (
    <>
      {!sunscriptions?.generalSubData && (
        <>
          <div className="wrapper bottom-zero">
            <div className="setting-alert">
              You don't have platform access. Please head to the "Billing" tab and hit "Upgrade Now".

            </div>
          </div>
        </>
      )}
      {sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined" && (
        <>
          <div className="wrapper bottom-zero">
            <div className="setting-alert">
              You don't have platform access because your account is overdue. Please <span role="link" onClick={()=> setOpenAddPaymentModal(true)}>update your credit card.</span>
              
            </div>
          </div>
        </>
      )}
      <HeroSection
        title="Settings"
        desc="Manage your account preferences here."
      />
      <div className="wrapper">
        <SettingHeader></SettingHeader>
      </div>
      {openAddPaymentModal && <AddPaymentModal
        handleClose={() => {
          setOpenAddPaymentModal(false);
        }}
        open={openAddPaymentModal}
        replacePaymentMethod={true}
      ></AddPaymentModal>}
    </>
  );
};
export default Settings;
