export enum AdvancedFilterAccordians {
  PropertyCharacteristics = "PropertyCharacteristics",
  AuctionsPreForeclosure ="AuctionsPreForeclosure",
  Ownership = "Ownership",
  TaxLiens="TaxLiens",
  ValuationEquity="ValuationEquity",
  MortgageInfo="MortgageInfo",
  CashBuyers="CashBuyers",
  Demographics="Demographics",
  None = "",
}