import { Tooltip } from "@mui/material";
import SearchDropDown from "../../atoms/SearchDropDown";
import styles from "./PropertySearch.module.scss";
import HelpCircle from "../../assets/icons/helpCircle";
import FilterDropdown from "../filterDropdown/FilterDropdown";
import { useFormContext } from "react-hook-form";
import AdvancedFilter from "./AdvancedFilter";
import Button from "../../atoms/Button";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE,
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
  MARKET_STATUS_OPTION,
  MARKET_STATUS_OPTION_MAP,
  PROPERTY_TYPE_OPTION,
  PROPERTY_TYPE_OPTION_MAP,
  SearchPropertyType,
} from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressPredictionLoading,
  getAddressPredictions,
  getClearPropertySearch,
  getSearchPropertiesResultCount,
} from "./selectors";
import { useEffect, useRef, useState } from "react";
import {
  setClearPropertySearch,
  setSearchTerm,
  setSearchTermSelected,
  setSearchedProperties,
} from "./reducer";
import DropDownIcon from "../../assets/icons/dropDownIcon";
import {
  createAdvancedFilters,
  removeZeroValues,
  updateFilterFlags,
} from "./utils";
import { searchAddress, searchProperty } from "./sagaActions";
import { useDbounce } from "../../hooks/debounce.hook";
import SearchReflation from "../../assets/icons/searchReflation";
import { useHelpModalContext } from "../../context/HelpModalContext";
import { useLocation } from "react-router-dom";
// import { PropertySearchFilterProps } from "./interface";
import { pageNameFn } from "../auth/enum";
import Input from "../../atoms/Input";
import { propertyCount } from "./PropertyService";

const PropertySearchFilter = ({
  setMapRightActive,
  pageNumber,
  setPageNumber,
  appliedAdvancedFiltersFormState,
  setAppliedAdvancedFiltersFormState,
  mapShapesPayload,
  disableSearch = false,
}: any) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
    getValues,
    reset,
    register,
  }: any = useFormContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchRef = useRef<any>(null);
  const seletcedRef = useRef<string | null>(null);
  const [predictions, setPredictions] = useState<any[]>([]);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [openBedAndBathFilter, setOpenBedAndBathFilter] =
    useState<boolean>(false);
  const [updatePropertyType, setUpdatePropertyType] = useState(false);
  const [advancedFilterCount, setAdvancedFilterCount] = useState<number>(0);
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState<boolean>(false);
  const [appliedAdvancedFiltersFlags, setAppliedAdvancedFiltersFlags] =
    useState<any>(DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE);
  const [init, setInit] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const clearPropertySearch = useSelector((state) =>
    getClearPropertySearch(state)
  );
const [updateCount, setUpdateCount]= useState(false)
const [loading, setLoading]= useState(false);
const [ currentValue, setCurrentValue] = useState<any>(appliedAdvancedFiltersFormState)
const searchPropertiesResultCount = useSelector((state) =>
  getSearchPropertiesResultCount(state)
);
const[propertiesCount, setPropertiesCount] = useState(-1)
  const addressPredictions = useSelector((state) =>
    getAddressPredictions(state)
  );
  const addressPredictionLoading = useSelector((state) =>
    getAddressPredictionLoading(state)
  );
  const { openWelcomeModal, modalContent, setModalContent } =
    useHelpModalContext();

  const page: any = pageNameFn(location?.pathname.split("/")[1]);

  const debounce = useDbounce(500, (data: any) => {
    if (data?.length > 0) {
      dispatch(searchAddress(data));
    } else {
      setIsSearched(false);
      setPredictions([]);
    }
  });

  const getAdvancedFilterObject = (
    data: any,
    setAppliedAdvancedFiltersFlags: Function
  ) => {
    let advancedFilter = {};
    let advancedFilterCount = 0;
    const filterArray = createAdvancedFilters(
      data,
      setAppliedAdvancedFiltersFlags
    );

    filterArray.forEach((filter) => {
      advancedFilter = { ...advancedFilter, ...filter };
      if (
        Object.keys(filter).length > 0 &&
        Object.values(filter).some((val) => val !== undefined)
      ) {
        advancedFilterCount++;
      }
    });
    setAdvancedFilterCount(advancedFilterCount);
    return advancedFilter;
  };

  const dispatchSearchProperty = async (pageNumber: number = 0) => {
    const advancedFilter = getAdvancedFilterObject(
      appliedAdvancedFiltersFormState,
      setAppliedAdvancedFiltersFlags
    );
    if (appliedAdvancedFiltersFormState.searchTerm || mapShapesPayload) {
      let address = addressPredictions.find((el: any) => {
        return (
          Object.values(el).join(",") ==
          appliedAdvancedFiltersFormState.searchTerm
        );
      });
      const payload = {
        ...appliedAdvancedFiltersFormState.bedsAndBathFilter,
        ...(appliedAdvancedFiltersFormState.marketStatus ?? {}),
        ...(appliedAdvancedFiltersFormState.propertyTypeFilter ?? {}),
        ...advancedFilter,
        ...address,
        ...mapShapesPayload,
        size: 3,
        resultIndex: pageNumber * 3,
      };
      for (const key in payload) {
        if (!key.startsWith("exclude_")) {
          continue;
        }

        if (!payload.exclude) {
          payload.exclude = [];
        }

        payload.exclude.push({
          [key.replace("exclude_", "")]: payload[key],
        });

        delete payload[key];
      }

     dispatch(setSearchTermSelected(address))
    if (updateCount) {
      try{
        setLoading(true)
        const response = await propertyCount(payload);
        setPropertiesCount(response?.data?.results_count);
        setLoading(false)
      }catch(error){
        setLoading(false)
      }
    } else {
      dispatch(searchProperty(payload));
    }
    }
    updateMapRightActive();
  };

  const updateMapRightActive = () => {
    if (appliedAdvancedFiltersFormState.searchTerm || mapShapesPayload) {
      setMapRightActive(true);
    } else {
      setMapRightActive(false);
    }
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e?.target?.value?.length > 0 && e?.target?.value?.trim()?.length === 0) {
      value = "";
    }
    dispatch(setSearchTerm(value));
    setValue("searchTerm", value);
  };

  const handleFocusOut = () => {
    setTimeout(() => {
      setPredictions([]);
      setIsSearched(false);
      const selctedAddress = addressPredictions.find((el: any) => {
        return (
          Object.values(el).join(",") ===
          appliedAdvancedFiltersFormState.searchTerm
        );
      })?.title;
      if (selctedAddress && getValues("searchTerm")) {
        setValue("searchTerm", selctedAddress);
        dispatch(setSearchTerm(selctedAddress));
        seletcedRef.current = selctedAddress;
      }
    }, 0);
  };

  const handleOpenBedAndBathFilter = (event: any, state: boolean) => {
    if (state) {
      setAnchorElement(event.currentTarget);
    } else {
      setValue(
        "minBedrooms",
        appliedAdvancedFiltersFormState.bedsAndBathFilter?.beds_min ?? undefined
      );
      setValue(
        "maxBedrooms",
        appliedAdvancedFiltersFormState.bedsAndBathFilter?.beds_max ?? undefined
      );
      setValue(
        "minBathrooms",
        appliedAdvancedFiltersFormState.bedsAndBathFilter?.baths_min ??
          undefined
      );
      setValue(
        "maxBathrooms",
        appliedAdvancedFiltersFormState.bedsAndBathFilter?.baths_max ??
          undefined
      );
      setAnchorElement(null);
    }
    setOpenBedAndBathFilter(state);
  };
  const { handleSubmit }: any = useFormContext();

  const handleBedsAndBathsFilterSubmit = (data: any) => {
    const beds_min = data?.bedsBath?.minBedrooms;
    const beds_max = data?.bedsBath?.maxBedrooms;
    const baths_min = data?.bedsBath?.minBathrooms;
    const baths_max = data?.bedsBath?.maxBathrooms;
    // if (!beds_min) {
    //   setValue("bedsBath.minBedrooms", 0);
    // }

    // if (!beds_max) {
    //   setValue("bedsBath.maxBedrooms", 0);
    // }

    // if (!baths_min) {
    //   setValue("bedsBath.minBathrooms", 0);
    // }

    // if (!baths_max) {
    //   setValue("bedsBath.maxBathrooms", 0);
    // }

    const filter: any = {
      beds_min,
      beds_max,
      baths_min,
      baths_max,
    };
    Object.keys(filter).forEach((key) => !filter[key] && delete filter[key]);
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      bedsAndBathFilter: filter,
    }));
    setOpenBedAndBathFilter(false);
  };

  const transformValue = (value: any[]) => {
    if (value.includes("ANY")) {
      if (!watch("propertyType")?.includes("ANY")) {
        return ["ANY"];
      } else {
        return value.filter((type: any) => type !== "ANY");
      }
    }
    return value;
  };

  const handleMarketStatusValueTransform = (value: any[]) => {
    const currentValue = getValues("marketStatus");
    if (value.includes("ANY")) {
      if (!currentValue?.includes("ANY")) {
        return ["ANY"];
      } else {
        return value.filter((type: any) => type !== "ANY");
      }
    }

    return value;
  };

  const handleKeyDownFn = (event: any) => {
    if (event?.key?.toLowerCase() === "tab") {
      event.stopPropagation();
    }
  };

  const handleOpenAdvancedFilter = (event: any, state: boolean) => {
    if (state && event) {
      setAnchorElement(event?.currentTarget);
      setOpenAdvancedFilter(true);
      return;
    } else {
      setAppliedAdvancedFiltersFormState(appliedAdvancedFiltersFormState)
      setAnchorElement(null);
      setOpenAdvancedFilter(state);
    }
    
  };

  const resetAdvancedFilter = (filterState: any, formValue: any) => {
    reset({ ...getValues(), ...formValue });
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      ...filterState,
    }));
    setAppliedAdvancedFiltersFlags(DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE);
    setOpenAdvancedFilter(false);
    setUpdateCount(false)
    resetSearchedPropertie();
  };

  const resetSearchedPropertie = () => {
    dispatch(
      setSearchedProperties({
        data: { result: [], results_count: -1, potential_count: 0 },
      })
    );
  };
  const onApplyFilter = (data: any ,flag?:any) => {
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      propertyCharacteristicsFilter: data.propertyCharacteristicsFilter,
      auctionsPreforeclousreFilter: data.auctionsPreforeclousreFilter,
      ownershipFilter: data.ownershipFilter,
      taxLienFilter: data.taxLienFilter,
      valuationEquityFilter: data.valuationEquityFilter,
      mortgageInfoFilter: data.mortgageInfoFilter,
      cashBuyersFilter: data.cashBuyersFilter,
      demographicsFilter: data.demographicsFilter,
    }));
   
    if(flag) {
      setUpdateCount(false)
       setOpenAdvancedFilter(false)
      }
      else{
        setUpdateCount(true)
        setCurrentValue(appliedAdvancedFiltersFormState)
      }
    ;
  };

  const handlePredictionClick = (value: string, label: string) => {
    setValue("searchTerm", label);
    dispatch(setSearchTerm(label));
    seletcedRef.current = label;
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      searchTerm: value,
    }));
    setPredictions([]);
    setIsSearched(false);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      if (predictions.length > 0) {
        handlePredictionClick(predictions?.[0]?.value, predictions?.[0]?.label);
      }
    }
  };
  const handleOnFocus = () => {
    if (watch("searchTerm")) {
      dispatch(searchAddress(watch("searchTerm")));
      seletcedRef.current = null;
    }
  };
  const handleSearchClick = () => {
    searchRef?.current?.focus();
  };

  const clearSearch = () => {
    resetSearchedPropertie();
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      searchTerm: "",
    }));
    dispatch(setSearchTerm(""));
    setIsSearched(false);
    setPredictions([]);
    setPropertiesCount(-1)
  };

  const handleSearchIconClick = (e: any) => {
    // if (searchText?.length > 0) {
    //   dispatch(searchAddress(searchText));
    // } else {
    //   setIsSearched(false);
    //   setPredictions([]);
    // }
    if (predictions.length > 0) {
      dispatchSearchProperty()
    }
  };

  useEffect(() => {
    if (seletcedRef.current !== watch("searchTerm")) {
      debounce(watch("searchTerm"));
      seletcedRef.current = null;
      setIsSearched(false);
    }
    if (!watch("searchTerm")) {
      clearSearch();
    }
  }, [watch("searchTerm")]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });
  }, [location.pathname]);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }: any) => {
      trigger([
        "propertyCharacteristicsFilter",
        "auctionsPreforeclousreFilter",
        "ownershipFilter",
        "taxLienFilter",
        "valuationEquityFilter",
        "mortgageInfoFilter",
        "cashBuyersFilter",
        "demographicsFilter",
      ]);
      updateFilterFlags(value, setAppliedAdvancedFiltersFlags);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!openAdvancedFilter) {
      reset({
        ...getValues(),
        propertyCharacteristicsFilter:
          appliedAdvancedFiltersFormState.propertyCharacteristicsFilter,
        auctionsPreforeclousreFilter:
          appliedAdvancedFiltersFormState.auctionsPreforeclousreFilter,
        ownershipFilter: removeZeroValues(
          appliedAdvancedFiltersFormState.ownershipFilter
        ),
        taxLienFilter: appliedAdvancedFiltersFormState.taxLienFilter,
        valuationEquityFilter: removeZeroValues(
          appliedAdvancedFiltersFormState.valuationEquityFilter
        ),
        mortgageInfoFilter: removeZeroValues(
          appliedAdvancedFiltersFormState.mortgageInfoFilter
        ),
        cashBuyersFilter: removeZeroValues(
          appliedAdvancedFiltersFormState.cashBuyersFilter
        ),
        demographicsFilter: removeZeroValues(
          appliedAdvancedFiltersFormState.demographicsFilter
        ),
      });
      setUpdateCount(false)
    }
  }, [openAdvancedFilter]);

  useEffect(() => {
    if (!pageNumber) {
      return;
    }
    if (appliedAdvancedFiltersFormState.searchTerm || mapShapesPayload) {
      dispatchSearchProperty(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    const options = addressPredictions?.map((el: any) => {
      return {
        value: Object.values(el).join(","),
        label: el.title,
        searchType: SearchPropertyType[String(el.searchType)],
      };
    });
    setPredictions(options);
    setIsSearched(true);
  }, [addressPredictions]);

  useEffect(() => {
    if (appliedAdvancedFiltersFormState.searchTerm || mapShapesPayload) {
      setPageNumber(0);
    }
    if (!init) {
      setInit(true);
    } else {
      dispatchSearchProperty();
    }
    if(mapShapesPayload === null && appliedAdvancedFiltersFormState.searchTerm.length < 1){
      setPropertiesCount(-1)
    }
  }, [appliedAdvancedFiltersFormState, mapShapesPayload]);

  useEffect(() => {
    let rawValue: string[] = getValues("marketStatus");
    const possibleMarketStatusKeys = Object.keys(MARKET_STATUS_OPTION_MAP);
    if (rawValue.length === possibleMarketStatusKeys.length - 1) {
      rawValue = ["ANY"];
    }

    // if (rawValue.includes("FOR_SALE") && rawValue.includes("OFF_MARKET")) {
    //   rawValue = rawValue.filter(
    //     (item: string) => item !== "FOR_SALE" && item !== "OFF_MARKET"
    //   );
    // }

    const value = rawValue.map(
      (item: string | number) => MARKET_STATUS_OPTION_MAP[item]
    );
    let formatValue = {};
    value.forEach((item: any) => {
      formatValue = { ...formatValue, ...item };
    });
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      marketStatus: formatValue,
    }));
  }, [watch("marketStatus")]);

  


  

  useEffect(() => {
    if (!updatePropertyType && watch("propertyType")?.length === 0) {
      return;
    }
    setUpdatePropertyType(true);
    let filter: any = { property_type: [] };
    watch("propertyType")?.forEach((type: any) => {
      if (PROPERTY_TYPE_OPTION_MAP[type]?.property_type) {
        filter.property_type.push(
          PROPERTY_TYPE_OPTION_MAP[type]?.property_type
        );
      } else {
        filter = { ...filter, ...PROPERTY_TYPE_OPTION_MAP[type] };
      }
    });
    if (filter.property_type.length === 0) {
      delete filter["property_type"];
    }
    setAppliedAdvancedFiltersFormState((prev: any) => ({
      ...prev,
      propertyTypeFilter: filter,
    }));
  }, [watch("propertyType")]);

  useEffect(() => {
    if (clearPropertySearch) {
      resetAdvancedFilter(
        DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
        DEFAULT_FORM_VALUE
      );
      dispatch(setClearPropertySearch(false));
      
    }
  }, [clearPropertySearch]);

  useEffect(()=>{
    if(searchPropertiesResultCount || searchPropertiesResultCount === 0){
     setPropertiesCount(searchPropertiesResultCount)
    }
    else{
      setPropertiesCount(-1)
    }
  },[searchPropertiesResultCount])

  return (
    <div className={`flex alignCenter ${styles.propertySearch}`}>
      <div className={` ${styles.searchBar} search-noresult-found `}>
        <SearchDropDown
          options={predictions}
          focused={watch("searchTerm")?.length > 0 ? true : false}
          closeIcon={
            watch("searchTerm") ===
            addressPredictions.find((el: any) => {
              return (
                Object.values(el).join(",") ===
                appliedAdvancedFiltersFormState.searchTerm
              );
            })?.title
              ? true
              : false
          }
          placeholder={"State, Zip, County, Street, Neighborhood, or Address"}
          searchIconRight
          searchText={watch("searchTerm")}
          handleInputChange={handleSearchTermChange}
          handlePredictionClick={handlePredictionClick}
          handleCloseClick={() => {
            setValue("searchTerm", "");
            clearSearch();
          }}
          handleFocusOut={handleFocusOut}
          handleKeyPress={handleKeyPress}
          isSearchLoading={addressPredictionLoading}
          onSearchClick={handleSearchClick}
          handleOnFocus={handleOnFocus}
          searchRef={searchRef}
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
          isSearched={isSearched}
          disabled={disableSearch}
          handleSearchIconClick={handleSearchIconClick}
        ></SearchDropDown>
      </div>
      <div className={` ${styles.dropdown}`}>
        <MultiSelectDropDown
          options={MARKET_STATUS_OPTION}
          label="Market Status"
          name="marketStatus"
          displayLabel={`Market Status`}
          control={control}
          transformValue={handleMarketStatusValueTransform}
          displayLength={
            watch("marketStatus")?.filter((type: any) => type !== "ANY").length
          }
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
        />
      </div>
      <div className={` ${styles.dropdownBed}`}>
        <Button
          postfix={<DropDownIcon />}
          label="Beds & Baths"
          className={`outline dropdown ${
            openBedAndBathFilter ? `rotateIcon` : ``
          } ${
            Object.keys(appliedAdvancedFiltersFormState.bedsAndBathFilter)
              .length > 0 || openBedAndBathFilter
              ? "focusedBlue"
              : ""
          }`}
          action={(e: any) => handleOpenBedAndBathFilter(e, true)}
        />
        {openBedAndBathFilter && (
          <FilterDropdown
            handleClose={(e: any) => handleOpenBedAndBathFilter(e, false)}
            anchorEl={anchorElement}
          >
            <div className={`filterMenu`} tabIndex={-1}>
              <div className={`filterMenu__info`}>
                <div onKeyDown={handleKeyDownFn}>
                  <p>Bedrooms</p>
                  <div className={`filterRow`}>
                    <div className={`filterRow__col`}>
                      {/* <DropDown
                      options={[
                        { value: 0, label: "Any" },
                        ...(watch("maxBedrooms")
                          ? BEDS_BATHS_OPTIONS.filter(
                              (option: any) =>
                                option.value <= watch("maxBedrooms")
                            )
                          : BEDS_BATHS_OPTIONS),
                      ]}
                      label=""
                      control={control}
                      name="minBedrooms"
                      errors={errors}
                      placeholder="Any"
                      clearOnBlur={true}
                    ></DropDown> */}

                      <Input
                        type="numeric"
                        register={register}
                        name="bedsBath.minBedrooms"
                        placeholder="No Min"
                        errors={errors?.["bedsBath"]?.["minBedrooms"]}
                        control={control}
                        errorToolTip={true}
                      ></Input>
                    </div>
                    <span className="separate">-</span>
                    <div className={` filterRow__col`}>
                      {/* <DropDown
                      options={[
                        { value: 0, label: "Any" },
                        ...(watch("minBedrooms")
                          ? BEDS_BATHS_OPTIONS.filter(
                              (option: any) =>
                                option.value >= watch("minBedrooms")
                            )
                          : BEDS_BATHS_OPTIONS),
                      ]}
                      label=""
                      control={control}
                      name="maxBedrooms"
                      errors={errors}
                      placeholder="Any"
                      clearOnBlur={true}
                    ></DropDown> */}

                      <Input
                        type="numeric"
                        register={register}
                        name="bedsBath.maxBedrooms"
                        placeholder="No Max"
                        errors={errors?.["bedsBath"]?.["maxBedrooms"]}
                        control={control}
                        errorToolTip={true}
                      ></Input>
                    </div>
                  </div>
             

                <p>Bathrooms</p>
                <div className={`filterRow`}>
                  <div className={`filterRow__col`}>
                    {/* <DropDown
                      options={[
                        { value: 0, label: "Any" },
                        ...(watch("maxBathrooms")
                          ? BEDS_BATHS_OPTIONS.filter(
                              (option: any) =>
                                option.value <= watch("maxBathrooms")
                            )
                          : BEDS_BATHS_OPTIONS),
                      ]}
                      label=""
                      control={control}
                      name="minBathrooms"
                      errors={errors}
                      placeholder="Any"
                      clearOnBlur={true}
                    ></DropDown> */}
                    <Input
                      type="numeric"
                      register={register}
                      name="bedsBath.minBathrooms"
                      placeholder="No Min"
                      errors={errors?.["bedsBath"]?.["minBathrooms"]}
                      control={control}
                      errorToolTip={true}
                    ></Input>
                  </div>
                  <span className="separate">-</span>
                  <div className={` filterRow__col`}>
                    {/* <DropDown
                      options={[
                        { value: 0, label: "Any" },
                        ...(watch("minBathrooms")
                          ? BEDS_BATHS_OPTIONS.filter(
                              (option: any) =>
                                option.value >= watch("minBathrooms")
                            )
                          : BEDS_BATHS_OPTIONS),
                      ]}
                      label=""
                      control={control}
                      name="maxBathrooms"
                      errors={errors}
                      placeholder="Any"
                      clearOnBlur={true}
                    ></DropDown> */}
                    <Input
                      type="numeric"
                      register={register}
                      name="bedsBath.maxBathrooms"
                      placeholder="No Max"
                      errors={errors?.["bedsBath"]?.["maxBathrooms"]}
                      control={control}
                      errorToolTip={true}
                    ></Input>
                  </div>
                </div>
                </div>
              </div>
              <div >
                <div className={` filterMenu__footer`}>
                  <Button
                    label="Apply"
                    className={`primary full`}
                    action={handleSubmit(handleBedsAndBathsFilterSubmit)}
                  />
                </div>
              </div>
            </div>
          </FilterDropdown>
        )}
      </div>
      <div
        className={` ${styles.dropdownProperty} ${
          watch("propertyType")?.length > 0 &&
          !watch("propertyType").includes("ANY")
            ? styles.dropdownPropertyFocused
            : ""
        }`}
      >
        <MultiSelectDropDown
          options={PROPERTY_TYPE_OPTION}
          label="Property Type"
          name="propertyType"
          displayLabel={`Property Type`}
          control={control}
          transformValue={transformValue}
          displayLength={
            watch("propertyType")?.filter((type: any) => type !== "ANY").length
          }
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
        ></MultiSelectDropDown>
      </div>
      <div className={` ${styles.dropdownAdvance}`}>
        <Button
          postfix={<DropDownIcon />}
          label={`Advanced${
            advancedFilterCount ? ` (${advancedFilterCount})` : ""
          }`}
          className={`outline dropdown ${
            openAdvancedFilter ? `rotateIcon` : ``
          } ${
            openAdvancedFilter ||
            Object.keys(appliedAdvancedFiltersFlags).filter(
              (key) => appliedAdvancedFiltersFlags[key]
            ).length > 0
              ? `focusedBlue`
              : ""
          }`}
          action={(e: any) => handleOpenAdvancedFilter(e, true)}
        />
        {openAdvancedFilter && (
          <FilterDropdown
            sizeLarge={true}
            handleClose={(e: any) => handleOpenAdvancedFilter(e, false)}
            anchorEl={anchorElement}
          >
            <AdvancedFilter
              onApplyFilter={onApplyFilter}
              appliedAdvancedFilters={appliedAdvancedFiltersFlags}
              resetAdvancedFilter={resetAdvancedFilter}
              handleKeyDownFn={handleKeyDownFn}
              propertiesCount={propertiesCount}
              loading={loading}
            ></AdvancedFilter>
          </FilterDropdown>
        )}
      </div>

      <Tooltip onClick={() => openWelcomeModal(page)} title="">
        <span className={` ${styles.info}`} role="link">
          <HelpCircle />
        </span>
      </Tooltip>
    </div>
  );
};
export default PropertySearchFilter;
