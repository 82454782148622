import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ArrowRight from "../../assets/icons/arrowRight";
import FileHeart from "../../assets/icons/fileHeart";
import OpenNew from "../../assets/icons/openNew";
import Button from "../../atoms/Button";
import styles from "./CreateCampaign.module.scss";
import { selectCampaignFormSchema } from "./validation";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCampaignLeadList, getLoadingCampaignLeadList } from "./selectors";
import Loader from "../../atoms/Loader";
import { useNavigate } from "react-router-dom";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import FileHeartFill from "../../assets/icons/FileHeartFill";
import SearchReflation from "../../assets/icons/searchReflation";
import { getProfileInfo, getProfileInfoLoading } from "../../components/profileMenu/selectors";
import { SelectCampaignListProps } from "./interface";
import CheckCircle from "../../assets/icons/checkCircle";
import { formartNumberAsLocalString } from "../../utils/utils";

const SelectCampaignList = ({ onSubmit, stepData, handleUploadList }: any) => {

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(selectCampaignFormSchema),
        defaultValues: { ...stepData },
    });
    const navigate = useNavigate();
    const [leadListOptions, setLeadListOptions] = useState([]);
    const listSelected = watch("leadList")
    const [listCost, setlistCost] = useState(0);
    const loadingCampaignLeadList = useSelector((state) =>
        getLoadingCampaignLeadList(state)
    );
    const profileInfo = useSelector((state) => getProfileInfo(state));
    const campaignLeadList = useSelector((state) => getCampaignLeadList(state));
    const profileInfoLoading = useSelector((state) =>
        getProfileInfoLoading(state)
    );

    useEffect(() => {
        const options = campaignLeadList.map((list: any) => ({
            value: list.id,
            label: list.name,
            count: list.propertyCount
        }));

        // // Reverse the array to show the latest lists first
        // options.reverse();

        setLeadListOptions(options);
    }, [campaignLeadList]);

    useEffect(() => {
        let totalCost = 0;
        const selected = campaignLeadList?.filter((lead: any) =>
            listSelected.includes(lead?.id)
        );
        selected.forEach((lead: any) => {
            totalCost = totalCost + lead?.propertyCount ?? 0;
        });
        setlistCost(totalCost);
    }, [listSelected, campaignLeadList]);
    return (
        <>
            {loadingCampaignLeadList || profileInfoLoading ? (
                <div className={`loader-main`}>
                    <Loader></Loader>
                </div>
            ) : (
                <div className={`${styles.createCampaign}`} >
                    <div className={`headerIcon ${styles.headerIcon}`} data-testid="headerIcon" >
                        <FileHeart />
                    </div>
                    <div className={`${styles.createCampaign__header}`}>
                        <h3>
                            Select your list <span> (Step 1 of 2)</span>
                        </h3>
                        <p>Choose a list from your uploaded or skip-traced lists.</p>
                    </div>
                    {listCost ? listCost > +profileInfo?.organisation?.availableCredit ?
                        <div className={`flex alignCenter ${styles.creditsInfo}`}>
                            <span className={`${styles.creditsInfo__circle} ${styles.orange}`}>
                                <CheckCircle />
                            </span>
                            <div className={`${styles.creditsInfo__info}`}>
                                <p>You're good to proceed but you'll be asked to top up <strong>{formartNumberAsLocalString(listCost - profileInfo?.organisation?.availableCredit)}</strong> credits in a <u>later step!</u>
                                </p>
                                {listCost ? <p><strong>{formartNumberAsLocalString(listCost)} credits</strong> is the total cost of this campaign. </p> : ''}
                            </div>
                        </div>
                        :
                        <div className={`flex alignCenter ${styles.creditsInfo}`}>
                            <span className={`${styles.creditsInfo__circle}`}>
                                <CheckCircle />
                            </span>
                            <div className={`${styles.creditsInfo__info}`}>
                                <p>You are good on credits! </p>
                                {listCost ? <p><strong>{formartNumberAsLocalString(listCost)} credits</strong> will be used for this campaign. </p> : ''}
                            </div>
                        </div> : ''}
                    <div className={`dflex   ${styles.createCampaign__upBuy}`}>
                        <div className={`${styles.list}`}>
                            {/* <DropDown
                options={leadListOptions}
                label="Select one or more list*"
                control={control}
                name="leadList"
                errors={errors}
                disabled={leadListOptions.length===0}
                placeholder={leadListOptions.length===0?'No list Found':"Select List"}
              ></DropDown> */}
                            <p>Select one or more list*</p>
                            <MultiSelectDropDown
                                options={leadListOptions}
                                label="Select List"
                                name="leadList"
                                errors={errors}
                                control={control}
                                emptySearchMetaData={{
                                    icon: <SearchReflation />,
                                    title: "No results found",
                                }}
                            ></MultiSelectDropDown>
                        </div>
                        <div className={`dflex alignCenter ${styles.uploadBuy}`}>
                            <div className={`dflex ${styles.uploadBuy__inner}`}>
                                <span role="link" onClick={handleUploadList}>
                                    <OpenNew /> Upload a list
                                </span>
                                <span role="link" onClick={() => navigate("/property-search")}>
                                    <FileHeartFill /> Pull a list
                                </span>
                            </div>
                        </div>
                    </div>
                  



                    <div className={`${styles.createCampaign__actionNext}`}>
                        <Button
                            label={"Next"}
                            prefix={<ArrowRight />}
                            className="primary sl full animated"
                            action={handleSubmit(onSubmit)}
                        ></Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SelectCampaignList;
