import Button from "../../atoms/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PlusCircle from "../../assets/icons/plusCircle";
import MinusCircle from "../../assets/icons/minusCircle";
import { useState, useEffect } from "react";
import { AdvancedFilterAccordians } from "./enum";
import { useFormContext } from "react-hook-form";
import PropertyCharacteristics from "./advancedFilterAccordians/PropertyCharacteristics";
import AuctionsPreForeclosure from "./advancedFilterAccordians/AuctionsPreForeclosure";
import Ownership from "./advancedFilterAccordians/Ownership";
import TaxLiens from "./advancedFilterAccordians/TaxLiens";
import ValuationEquity from "./advancedFilterAccordians/ValuationEquity";
import MortgageInfo from "./advancedFilterAccordians/MortgageInfo";
import CashBuyers from "./advancedFilterAccordians/CashBuyers";
import Demographics from "./advancedFilterAccordians/Demographics";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
} from "./constants";
import Reset from "../../assets/icons/reset";
import { AdvancedFilterProps } from "./interface";
import { getSearchPropertiesResultCount } from "./selectors";
import { useSelector } from "react-redux";
import { formartNumberAsLocalString } from "../../utils/utils";

const AdvancedFilter = ({
  onApplyFilter,
  appliedAdvancedFilters,
  resetAdvancedFilter,
  handleKeyDownFn,
  propertiesCount,
  loading
}: AdvancedFilterProps) => {
  const { handleSubmit ,formState: { isValid } ,watch}: any = useFormContext();
  const [expandedAccordion, setExpandedAccordion] =
    useState<AdvancedFilterAccordians>(AdvancedFilterAccordians.None);
  const handleApply = (data: any) => {
    onApplyFilter(data,true);
  };
  useEffect(()=>{
    const subscription = watch((value:any) =>
      onApplyFilter(value,false)
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const resetAllFilters = () => {
    const filterState: any = { ...DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE };
    delete filterState["searchTerm"];
    const formValue: any = { ...DEFAULT_FORM_VALUE };
    delete formValue["searchTerm"];
    resetAdvancedFilter(filterState, formValue);
  };
  useEffect(() => {
    const elementId =
      AdvancedFilterAccordians[
        expandedAccordion as keyof typeof AdvancedFilterAccordians
      ];

    if (elementId) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 300);
    }
  }, [expandedAccordion]);

  return (
    <>
      <div className={`filterMenu`}>
        <div className={`filterMenu__info`}>
          <div className={`accordion`} onKeyDown={handleKeyDownFn}>
            <Accordion
              expanded={
                expandedAccordion ===
                AdvancedFilterAccordians.PropertyCharacteristics
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(
                    AdvancedFilterAccordians.PropertyCharacteristics
                  );
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.PropertyCharacteristics ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Property Characteristics{" "}
                  {appliedAdvancedFilters.propertyCharacteristicsFilter > 0 &&
                    `(${appliedAdvancedFilters.propertyCharacteristicsFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PropertyCharacteristics></PropertyCharacteristics>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion ===
                AdvancedFilterAccordians.AuctionsPreForeclosure
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(
                    AdvancedFilterAccordians.AuctionsPreForeclosure
                  );
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.AuctionsPreForeclosure ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Auctions & Pre-Foreclosure{" "}
                  {appliedAdvancedFilters.auctionsPreforeclousreFilter > 0 &&
                    `(${appliedAdvancedFilters.auctionsPreforeclousreFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AuctionsPreForeclosure></AuctionsPreForeclosure>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.Ownership
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(AdvancedFilterAccordians.Ownership);
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.Ownership ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Ownership & Occupancy{" "}
                  {appliedAdvancedFilters.ownershipFilter > 0 &&
                    `(${appliedAdvancedFilters.ownershipFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Ownership></Ownership>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expandedAccordion === AdvancedFilterAccordians.TaxLiens}
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(AdvancedFilterAccordians.TaxLiens);
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.TaxLiens ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Tax Liens{" "}
                  {appliedAdvancedFilters.taxLienFilter > 0 &&
                    `(${appliedAdvancedFilters.taxLienFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TaxLiens></TaxLiens>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.ValuationEquity
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(
                    AdvancedFilterAccordians.ValuationEquity
                  );
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.ValuationEquity ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Valuation & Equity{" "}
                  {appliedAdvancedFilters.valuationEquityFilter > 0 &&
                    `(${appliedAdvancedFilters.valuationEquityFilter})`}
                </Typography>
              </AccordionSummary>

              <AccordionDetails id="ValuationEquity">
                <ValuationEquity></ValuationEquity>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.MortgageInfo
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(AdvancedFilterAccordians.MortgageInfo);
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.MortgageInfo ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Mortgage Info{" "}
                  {appliedAdvancedFilters.mortgageInfoFilter > 0 &&
                    `(${appliedAdvancedFilters.mortgageInfoFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="MortgageInfo">
                <MortgageInfo></MortgageInfo>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.CashBuyers
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(AdvancedFilterAccordians.CashBuyers);
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.CashBuyers ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Cash Buyers{" "}
                  {appliedAdvancedFilters.cashBuyersFilter > 0 &&
                    `(${appliedAdvancedFilters.cashBuyersFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="CashBuyers">
                <CashBuyers></CashBuyers>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.Demographics
              }
              onChange={(e, expanded) => {
                if (expanded) {
                  setExpandedAccordion(AdvancedFilterAccordians.Demographics);
                } else {
                  setExpandedAccordion(AdvancedFilterAccordians.None);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.Demographics ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Demographics{" "}
                  {appliedAdvancedFilters.demographicsFilter > 0 &&
                    `(${appliedAdvancedFilters.demographicsFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="Demographics">
                <Demographics></Demographics>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={` filterMenu__footer`}>
          <div className={`colRow`}>
            <div className={`colRow__col`} onKeyDown={handleKeyDownFn}>
              <Button
                label="Reset all filters"
                prefix={<Reset />}
                className={`outline full`}
                action={resetAllFilters}
              />
            </div>
            <div className={`colRow__col`}>
              <Button
                label={
                  propertiesCount !== -1
                    ? `Search ${
                        propertiesCount
                          ? formartNumberAsLocalString(propertiesCount)
                          : propertiesCount
                      } properties`
                    : "Apply"
                }
                className={`primary full`}
                action={handleSubmit(handleApply)}
                disabled={!isValid || loading}
                clicked={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvancedFilter;
