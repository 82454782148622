import styles from "./InfoCard.module.scss";
import { InfoCardProps } from "./interface";

const InfoCard = ({ title, description }: InfoCardProps) => {
    return (
        <div className={`${styles.infoCard}`}>
            <h4>{title}</h4>
            <p>{description}</p>
        </div>

    );
};

export default InfoCard;