import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../atoms/Input";

const TaxLiens = () => {
  const {
    formState: { errors },
    control,
    register,
  }: any = useFormContext();
  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="taxLienFilter.taxLien"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Tax Lien</h5>
                  <p>
                    Properties where there is a tax lien against the property.
                  </p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Tax Delinquent Year</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="taxLienFilter.taxDelinquentYearMin"
                placeholder="No Min"
                errors={errors?.["taxLienFilter"]?.["taxDelinquentYearMin"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="taxLienFilter.taxDelinquentYearMax"
                placeholder="No Max"
                errors={errors?.["taxLienFilter"]?.["taxDelinquentYearMax"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TaxLiens;
