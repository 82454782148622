import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listIsLoading: false,
  myLists: [] as any[],
  total: 0,
};

const myListsTableSlice = createSlice({
  name: "myListsTable",
  initialState,
  reducers: {
    setMyLists: (state, { payload: { data, total } }) => {
      return {
        ...state,
        myLists: data ?? [],
        total: total,
      };
    },
    appendMyLists: (state, { payload: { data, total } }) => {
      return {
        ...state,
        myLists: [...state.myLists, ...(data ? data : [])],
        total: total,
      };
    },
    setListIsLoading: (state, { payload }) => {
      return { ...state, listIsLoading: payload };
    },
    resetMyListsTableState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setMyLists,
  setListIsLoading,
  appendMyLists,
  resetMyListsTableState,
} = myListsTableSlice.actions;

export default myListsTableSlice.reducer;
