import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import { MapShapesPayloadRaw } from "../../pages/propertySearch/PropertySearch";
import {
  disableScroll,
  enableScroll,
  isAllLatsSame,
  isAllLngsSame,
  reducePoints,
  removeSameAdjacentePoints,
} from "./utils";
import {useSelector } from "react-redux";
import { getAddressSearchProperty } from "../../organisms/propertySearchFilter/selectors";

type Polyline = google.maps.Polyline;

const drawMapOptions: google.maps.MapOptions = {
  clickableIcons: false,
  draggable: false,
  zoomControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
};

const normalMapOptions: google.maps.MapOptions = {
  clickableIcons: true,
  draggable: true,
  zoomControl: true,
  fullscreenControl: true,
  mapTypeControl: false,
  restriction: null,
};

interface DrawControlsProps {
  onApplyButtonClick?: (payload: MapShapesPayloadRaw) => void;
  onDrawButtonClick?: () => void;
  onRemoveBoundariesButtonClick?: () => void;
  onCancelButtonClick?: () => void;
}

const DrawControls = ({
  onApplyButtonClick,
  onDrawButtonClick,
  onRemoveBoundariesButtonClick,
  onCancelButtonClick,
}: DrawControlsProps) => {
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false);

  // shapes
  const [polylines, setPolylines] = useState<Polyline[]>([]);
  const polylineRef = useRef<google.maps.Polyline | null>(null);
  const isMouseDownRef = useRef<boolean | null>(null);
  const mapDivRef = useRef<ElementRef<"div">>();
const searchTerm= useSelector((data)=>getAddressSearchProperty(data));
  // map libs
  const coreLibrary = useMapsLibrary("core");
  const mapLibrary = useMapsLibrary("maps");
  const map = useMap();

  // functions
  const onPolylineComplete = useCallback(
    (shape: Polyline): Polyline | null => {
      let path = shape.getPath().getArray();
      path.push(shape.getPath().getArray()[0]);

      path = removeSameAdjacentePoints(path);

      shape.setMap(null);

      if (!path.length || isAllLatsSame(path) || isAllLngsSame(path)) {
        return null;
      }

      const reducedPath = reducePoints(path, coreLibrary!);

      if (!reducedPath || reducedPath.length <= 2) return null;

      const newShape = new mapLibrary!.Polyline({
        strokeColor: "#005DA4",
        strokeOpacity: 1,
        strokeWeight: 3,
        path: reducedPath,
      });
      // const newPolygon = new mapLibrary!.Polygon({
      //   paths: reducedPath,
      //   fillColor: "#000000",
      //   fillOpacity: 0.5,
      // });
      // newPolygon.setMap(map);
      newShape.setMap(map);
      return newShape;
    },
    [coreLibrary, map, mapLibrary]
  );

  const triggerMouseMoveEventOnMap = useCallback(() => {
    if (!coreLibrary || !map) return;
    coreLibrary.event.trigger(map, "mousemove");
  }, [coreLibrary, map]);

  const handleMouseDownListener = useCallback((e: MouseEvent) => {
    if (mapDivRef.current!.contains(e.target as HTMLElement)) {
      isMouseDownRef.current = true;
    }
  }, []);

  const handleMouseUpListener = useCallback((e: MouseEvent) => {
    if (mapDivRef.current!.contains(e.target as HTMLElement)) {
      isMouseDownRef.current = null;
    }
  }, []);

  const handleTouchMoveListener = useCallback(() => {
    triggerMouseMoveEventOnMap();
  }, [triggerMouseMoveEventOnMap]);

  const handleTouchStartListener = useCallback(() => {
    isMouseDownRef.current = true;
    triggerMouseMoveEventOnMap();
  }, [triggerMouseMoveEventOnMap]);

  const handleTouchEndListener = useCallback(() => {
    isMouseDownRef.current = null;
    triggerMouseMoveEventOnMap();
  }, [triggerMouseMoveEventOnMap]);

  const handleMapMouseMoveListener = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (!mapLibrary) return;

      const polyline = polylineRef.current;

      if (isMouseDownRef.current === null && polyline) {
        const reducedPolyline = onPolylineComplete(polyline);
        if (reducedPolyline) {
          setPolylines((prev) => [...prev, reducedPolyline]);
        }
        polylineRef.current = null;
        return;
      }

      if (isMouseDownRef.current === null) {
        return;
      }

      if (polylineRef.current === null) {
        polylineRef.current = new mapLibrary.Polyline({
          strokeColor: "#005DA4",
          strokeOpacity: 1,
          strokeWeight: 3,
        });

        polylineRef.current.setMap(map);
      }

      if (isMouseDownRef.current && e?.latLng) {
        polylineRef.current.setPath([
          ...polylineRef.current.getPath().getArray(),
          e.latLng,
        ]);
      }
    },
    [map, mapLibrary, onPolylineComplete]
  );

  const addEventsListeners = useCallback(() => {
    window.addEventListener("mousedown", handleMouseDownListener);
    window.addEventListener("mouseup", handleMouseUpListener);
    mapDivRef.current!.addEventListener("touchmove", handleTouchMoveListener);
    mapDivRef.current!.addEventListener("touchstart", handleTouchStartListener);
    mapDivRef.current!.addEventListener("touchend", handleTouchEndListener);
  }, [
    handleMouseDownListener,
    handleMouseUpListener,
    handleTouchEndListener,
    handleTouchMoveListener,
    handleTouchStartListener,
  ]);

  const removeEventsListeners = useCallback(() => {
    window.removeEventListener("mousedown", handleMouseDownListener);
    window.removeEventListener("mouseup", handleMouseUpListener);
    mapDivRef.current!.removeEventListener(
      "touchstart",
      handleTouchStartListener
    );
    mapDivRef.current!.removeEventListener(
      "touchmove",
      handleTouchMoveListener
    );
    mapDivRef.current!.removeEventListener("touchend", handleTouchEndListener);
  }, [
    handleMouseDownListener,
    handleMouseUpListener,
    handleTouchEndListener,
    handleTouchMoveListener,
    handleTouchStartListener,
  ]);

  const handleDrawButtonClick = useCallback(() => {
    disableScroll();
    addEventsListeners();
    map?.setOptions({
      restriction: {
        latLngBounds: map.getBounds()!,
      },
      ...drawMapOptions,
    });
    setShowApplyButton(true);
    onDrawButtonClick?.();
  }, [addEventsListeners, map, onDrawButtonClick]);

  const handleRemoveBoundariesButtonClick = useCallback(() => {
    removeEventsListeners();
    map?.setOptions(normalMapOptions);

    for (const polyline of polylines) {
      polyline.setMap(null);
    }

    isMouseDownRef.current = null;
    polylineRef.current = null;
    setShowApplyButton(false);
    setPolylines([]);

    onRemoveBoundariesButtonClick?.();
  }, [map, onRemoveBoundariesButtonClick, polylines, removeEventsListeners]);

  const handleApplyButtonClick = useCallback(() => {
    enableScroll();

    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }

    const newPolylines: typeof polylines = [];

    for (const polyline of polylines) {
      const paths = polyline.getPath().getArray();

      // check all lat are same or not
      const isAllLatsSame = paths.every(
        (_, i, a) => i === a.length - 1 || a[i].lat() === a[i + 1].lat()
      );
      if (isAllLatsSame) {
        polyline.setMap(null);
        continue;
      }

      // check all lng are some or not
      const isAllLngsSame = paths.every(
        (_, i, a) => i === a.length - 1 || a[i].lng() === a[i + 1].lng()
      );
      if (isAllLngsSame) {
        polyline.setMap(null);
        continue;
      }

      newPolylines.push(polyline);
    }

    setPolylines(newPolylines);
    if (!newPolylines.length) return;
    onApplyButtonClick?.({
      polylines: newPolylines,
    });

    removeEventsListeners();
    setShowApplyButton(false);
    map?.setOptions(normalMapOptions);
    isMouseDownRef.current = null;
    polylineRef.current = null;
  }, [map, onApplyButtonClick, polylines, removeEventsListeners]);

  const handleCancelButtonClick = useCallback(() => {
    enableScroll();
    removeEventsListeners();
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
    onApplyButtonClick?.({
      polylines: [],
    });
    map?.setOptions(normalMapOptions);

    for (const polyline of polylines) {
      polyline.setMap(null);
    }

    isMouseDownRef.current = null;
    polylineRef.current = null;
    setShowApplyButton(false);
    setPolylines([]);
    onCancelButtonClick?.();
  }, [
    map,
    onApplyButtonClick,
    onCancelButtonClick,
    polylines,
    removeEventsListeners,
  ]);

  // effects
  useEffect(() => {
    if (!map || !coreLibrary) return;
    coreLibrary.event.addListener(map, "mousemove", handleMapMouseMoveListener);
    return () => coreLibrary.event.clearListeners(map, "mousemove");
  }, [coreLibrary, handleMapMouseMoveListener, map]);

  useEffect(() => {
    mapDivRef.current = document.getElementById(
      "google-map-ottoleads"
    ) as HTMLDivElement;
    return () => enableScroll();
  }, []);

  useEffect(() => {
    if (searchTerm ) {
      polylines.forEach((polyline) => polyline.setMap(null));
      setPolylines([]);
      handleCancelButtonClick();
    }
  }, [searchTerm]);

  return (
    <div className={`mapDrawBtn ${showApplyButton ? `mapDrawBtnBg` : ``}`}>
      {showApplyButton && (
        <>
          <p>
            <strong>Draw a shape</strong> around the properties you'd like to
            search for
          </p>
          <div className="mapDrawBtnWrap">
            <Button
              className="outline"
              label={"Cancel"}
              action={handleCancelButtonClick}
            ></Button>
            <Button
              className="primary"
              label={"Apply"}
              disabled={!polylines.length}
              action={handleApplyButtonClick}
            ></Button>
          </div>
        </>
      )}
      {!showApplyButton && polylines.length !== 0 && (
        <Button
          className="outline-red outline"
          postfix={<Close />}
          label={"Remove Boundaries"}
          action={handleRemoveBoundariesButtonClick}
        ></Button>
      )}

      {!showApplyButton && polylines.length === 0 && (
        <Button
          className="outline"
          label={"Draw"}
          action={handleDrawButtonClick}
        ></Button>
      )}
    </div>
  );
};

export default DrawControls;
